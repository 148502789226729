import React, {useContext, useEffect, useState} from 'react';
import context from "../../context/context";
import Http from "../../libs/Http";
import {
    COMPOUNDING_DEVICE,
    HD_PREPARATIONS,
    HOSPITAL_TYPE,
    ONCOLOGY_BEDS,
    ONCOLOGY_CHAIRS
} from "../../constants";
import {Link} from "react-router-dom";
import {Col, Container, FormGroup, Row} from "reactstrap";
import CustomInput from "../Reusable/CustomInput";
import {translate} from "../../libs/trans";
import logo from "../../resources/images/side-logo.png";
import Checkbox from "../Reusable/Checkbox";
import {useHistory} from "react-router";
import CustomSelect from "../Reusable/CustomSelect";
import CustomSelectCreate from "../Reusable/CustomSelectCreate";
import {generateOfficeName, scrollToError} from "../../libs/helpers";
import {FormButton} from "../Reusable/FormComponents";

let officeSchema = {name: null, country_id: null};

const Register = () => {

    const {state: globalState, dispatch} = useContext(context);

    const initialState = {
        owner: {},
        company: {},
        version: 1,
        agree: false,
    };

    const [state, setState] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [maxId, setMaxId] = useState(null);
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [countries, setCountries] = useState([]);
    const [currentCountry, setCurrentCountry] = useState(null);
    const [offices, setOffices] = useState([{...officeSchema}]);
    const history = useHistory();


    useEffect(() => {
        if (globalState.user && !isLoading) {
            history.push('/');
        }
    }, [globalState.user, isLoading]);

    useEffect(() => {
        _getDropdownOptions();
        _getMaxId();
        _getCountries();
    }, [])

    useEffect(() => {
        if (maxId) {
            const tempOffices = JSON.parse(JSON.stringify(offices));
            tempOffices[0]['name'] = generateOfficeName(maxId, 0);
            tempOffices[0]['country_id'] = currentCountry;
            setOffices(tempOffices);
        }
    }, [currentCountry, maxId])

    const _getDropdownOptions = async () => {
        const res = await Http.get('/dropdown-options');
        if (!res.hasError) {
            setDropdownOptions(res.data);
        }
    };

    const _getMaxId = async () => {
        const res = await Http.get('/max-number');
        if (!res.hasError) {
            setMaxId(res.data);
        }
    };

    const _getCountries = async () => {
        const res = await Http.get('/countries');
        if (!res.hasError) {
            setCountries(res.data.options);
            setCurrentCountry(res.data.value)
        }
    };

    const sendRequest = () => {
        const {owner, company} = state;
        return company.hospital_type_id && company.oncology_chairs_id && company.oncology_beds_id && company.hd_preparations_id
            && company.compounding_device_id  && owner.email && state.agree
    }

    const _register = async () => {

        if (isLoading) return false;

        const params = {
            owner: state.owner,
            company: state.company,
            offices: offices
        };

        setIsLoading(true);

        const res = await Http.post('/register', params);

        if (res.hasError) {
            setErrors(res.error)
            scrollToError();
        } else {
            dispatch({type: "SET_NOTIFICATION_MESSAGE", payload: translate('auth.register.confirm')});
            history.push({
                pathname: '/login',
                hideModal: true
            });
        }

        setIsLoading(false);
    };


    const _handleChange = (name, value) => {
        setState(prevState => ({...prevState, company: {...prevState.company, [name]: value}}));
    };

    const _handleChangeOwner = (name, value) => {
        setState(prevState => ({...prevState, owner: {...prevState.owner, [name]: value}}));
    };

    const _handleChangeOffice = (key, name, value) => {
        let tempOffices = JSON.parse(JSON.stringify(offices));
        tempOffices[key][name] = value;
        setOffices(tempOffices);
    };

    const _addOfficeRange = () => {
        let tempOffices = JSON.parse(JSON.stringify(offices));
        tempOffices.push({
            name: generateOfficeName(maxId, tempOffices.length),
            country_id: currentCountry
        });
        setOffices(tempOffices);
    };

    const _deleteOfficeRange = key => () => {
        let tempOffices = JSON.parse(JSON.stringify(offices));
        if (offices.length <= 1) {
            return false;
        }
        tempOffices = tempOffices.filter((i, ind) => ind !== key);
        setOffices(tempOffices);
    };

    const _handleAgree = () => {
        const {agree} = state;
        setState(prevState => ({...prevState, agree: !agree}))
    };

    return (
        <div>
            <div className={'auth-background-image'}/>
            <div className={'auth-blank-section'}/>

            <div className={'auth-container register mt-4'}>
                <div className={'up'}>
                        <Row className="mx-0">
                            <Col xs={12} className="header-login px-0">
                                <div className={'logo-container'}>
                                    <img src={logo} alt=""/>
                                    <h2 className="">{translate('auth.login.hd_check')}
                                        <span>{translate('auth.login.analyser')}</span></h2>
                                </div>
                            </Col>
                            <Container>
                                <p className="mb-3 mt-4 ml-4 register-title">{translate('auth.register.create_account')}</p>
                                <Col xs={12} className={'px-4'}>
                                    <FormGroup>
                                        <CustomInput
                                            label={translate('misc.account_no')}
                                            type="text"
                                            disabled={true}
                                            className="mb-4"
                                            value={maxId}
                                        />
                                    </FormGroup>
                                    {offices.length > 0 && offices.map((item, key) =>
                                        <div key={key} className="multiple-office">
                                            <span/>
                                            <div className="w-100">
                                                <FormGroup>
                                                    <CustomSelect
                                                        className="mb-3"
                                                        name="country_id"
                                                        label={translate('location.country')}
                                                        required={true}
                                                        onChange={(n, v) => _handleChangeOffice(key, n, v)}
                                                        value={item.country_id}
                                                        options={countries}
                                                        error={errors.hasOwnProperty(`offices.${key}.country_id`)}
                                                        loadingNoOptions={isLoading}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <CustomInput
                                                        name="name"
                                                        label={translate('location.office_name')}
                                                        required={true}
                                                        readonly={true}
                                                        onChange={() =>{}}
                                                        value={item.name}
                                                        error={errors.hasOwnProperty(`offices.${key}.name`)}
                                                    />
                                                    {key > 0 && <div className="delete-range">
                                                        <span onClick={_deleteOfficeRange(key)}>Delete</span>
                                                    </div>}
                                                </FormGroup>
                                            </div>
                                        </div>)}
                                    <Col xs={12} className="ml-5 pl-4">
                                        {offices.length < 5 && <FormButton onClick={_addOfficeRange}
                                                                       size={'small'}
                                                                       variant={'secondary'}
                                                                       className={"mb-4 mt-0"}
                                                                       text={"Add"}
                                        />}
                                    </Col>
                                    <FormGroup>
                                        <CustomSelect
                                            className="mb-4"
                                            name="hospital_type_id"
                                            required={true}
                                            label={translate('auth.register.hospital_type')}
                                            value={state.company.hospital_type_id}
                                            options={dropdownOptions[HOSPITAL_TYPE]}
                                            onChange={_handleChange}
                                            error={errors['company.hospital_type_id']}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <CustomSelect
                                            type="text"
                                            className="mb-4"
                                            required={true}
                                            label={translate('auth.register.oncology_chairs')}
                                            value={state.company.oncology_chairs_id}
                                            options={dropdownOptions[ONCOLOGY_CHAIRS]}
                                            name="oncology_chairs_id"
                                            onChange={_handleChange}
                                            error={errors['company.oncology_chairs_id']}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <CustomSelect
                                            type="text"
                                            className="mb-4"
                                            required={true}
                                            label={translate('auth.register.oncology_beds')}
                                            value={state.company.oncology_beds_id}
                                            options={dropdownOptions[ONCOLOGY_BEDS]}
                                            name="oncology_beds_id"
                                            onChange={_handleChange}
                                            error={errors['company.oncology_beds_id']}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <CustomSelect
                                            type="text"
                                            className="mb-4"
                                            required={true}
                                            label={translate('auth.register.hd_preparations')}
                                            value={state.company.hd_preparations_id}
                                            options={dropdownOptions[HD_PREPARATIONS]}
                                            name="hd_preparations_id"
                                            onChange={_handleChange}
                                            error={errors['company.hd_preparations_id']}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <CustomSelectCreate
                                            className="mb-4"
                                            name="compounding_device_id"
                                            required={true}
                                            label={translate('auth.register.compounding_device')}
                                            value={state.company.compounding_device_id}
                                            options={dropdownOptions[COMPOUNDING_DEVICE]}
                                            onChange={_handleChange}
                                            error={errors['company.compounding_device_id']}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <CustomInput
                                            className="mb-4"
                                            label={translate('auth.register.email')}
                                            type="text"
                                            value={state.owner.email}
                                            required={true}
                                            name="email"
                                            onChange={_handleChangeOwner}
                                            error={errors['owner.email']}
                                        />
                                    </FormGroup>

                                    <FormGroup className={'remember-container pt-3'}>
                                        <Checkbox {...(state.agree === true ? {
                                            checked: true
                                        } : {})} onChange={_handleAgree}/>
                                        <span className="cursor-pointer"
                                              onClick={_handleAgree}>{translate('auth.login.agree')}</span>
                                    </FormGroup>

                                    <FormButton
                                        size={'xl'}
                                        className={'my-4'}
                                        fluid={true}
                                        variant={'primary'}
                                        isLoading={isLoading}
                                        disabled={!sendRequest()}
                                        text={translate('misc.submit')}
                                        onClick={_register}/>
                                </Col>
                                <h5 className={'text-center cursor-pointer mt-4 mb-5'}><Link to={{pathname: '/login', hideModal: true}}>{translate('auth.register.existing_users')}</Link></h5>
                            </Container>
                        </Row>
                </div>

            </div>
        </div>
    );
};

export default Register;