import React from 'react';

export const FormButton = (props) => {

    const cssClasses = [
        'fc-button',
        props.className,
        `size-${props.size}`,
        `variant-${props.variant || 'primary'}`,
        props.fluid && 'fluid',
    ].join(' ');

    return (
        <button className={cssClasses}
                disabled={props.disabled}
                onClick={props.onClick}>
            {props.isLoading ? <div className="spinner"/> : props.text && props.text}
        </button>
    );
};

export const FormInput = () => {
    return (
        <input className="fc-input" type="text" />
    );
}

