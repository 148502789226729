import React, {useContext, useEffect, useState} from 'react';
import Layout from "../Misc/Layout";
import {translate} from "../../libs/trans";
import {Col, Row} from "reactstrap";
import Timeline from "../Charts/Timeline";
import Http from "../../libs/Http";
import context from "../../context/context";
import {ROLE_ADMIN} from "../../constants";
import CustomSelect from "../Reusable/CustomSelect";
import CompareRadar from "../Charts/CompareRadar";

const Overall = () => {

    const {state: globalState, dispatch} = useContext(context);
    const [dropdowns, setDropdowns] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [params, setParams] = useState({
        result: null,
        type: 'institution_benchmarks',
        area_id: null,
        surface_id: null,
        surface_status_id: null,
        others_timespan: 11,
        company_id: null,
        hospital_type_id: null,
    });

    useEffect(() => {
        (async () => {
            if(globalState.user.hasRole(ROLE_ADMIN)){
                await _getClients();
            }
        })();
    }, []);

    useEffect(() => {
        if(globalState.user.hasRole(ROLE_ADMIN)){
            setParams(params => ({...params, company_id: globalState.clients?.[1]?.value}));
        }
    }, [globalState.clients]);

    useEffect(() => {
        if (params.company_id || !globalState.user.hasRole(ROLE_ADMIN)) {
            _getDropdownOptions(params.company_id)
        }
    }, [params.company_id]);

    const _getDropdownOptions = async (companyId) => {

        const params = {
            dropdowns: ['dept_area', 'drug_tested', 'surface', 'surface_status', 'hospital_type', 'countries'],
            ...(globalState.user.hasRole(ROLE_ADMIN) && {client_id: companyId}),
            ...(globalState.user.hasRole(ROLE_ADMIN) && {mode: companyId === 'all' ? 'all' : 'single'}),
        };

        const res = await Http.get('/dropdown-options', params);
        if (!res.hasError) {
            setDropdowns(res.data)
        }
        setIsLoading(false);
    };

    const _getClients = async () => {
        const res = await Http.get('/clients/min');

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            let clients = res.data.filter(item => item.value !== 102);
            clients.unshift({
                value: 'all',
                label: translate('clients.all_clients')
            });

            dispatch({type: "SET_CLIENTS", payload: clients});
        }
    };

    const setTimeFilter = (value) => _ => {
        if (!isLoading)
            setParams(params => ({...params, others_timespan: value}));
    }

    return (
        <Layout loading={isLoading}>


            {!isLoading && <div className="pb-2 background-white">
                <div className="bordered-container mb-4">
                    <div className="dash-box bot-container mt-0 px-4 ">
                        <div
                            className={`w-100 justify-content-end align-items-center ${globalState.responsive.isMobile ? 'd-block' : 'd-flex'}`}>
                            { !globalState.user.readonly_access && <div className="left-side w-100">
                                <h4 className="title">Comparison</h4>
                            </div> }
                            <div className="filters-container">
                                {globalState.user.hasRole(ROLE_ADMIN) && <div>
                                    <CustomSelect
                                        onChange={(name, value) => setParams(params => ({...params, company_id: value, area_id: null, surface_id: null}))}
                                        value={params.company_id}
                                        options={globalState.clients}
                                    />
                                </div>}
                                <div>
                                    <div className="time-filter-container d-flex">
                                        <div onClick={setTimeFilter("all")}
                                             className={`time-option ${params.others_timespan === "all" ? 'active' : ""}`}>{translate('misc.all')}</div>
                                        <div onClick={setTimeFilter(11)}
                                             className={`time-option ${params.others_timespan === 11 ? 'active' : ""}`}>1 Yr
                                        </div>
                                        <div onClick={setTimeFilter(5)}
                                             className={`time-option ${params.others_timespan === 5 ? 'active' : ""}`}>6 {translate('misc.month')}</div>
                                        <div onClick={setTimeFilter(2)}
                                             className={`time-option ${params.others_timespan === 2 ? 'active' : ""}`}>3 {translate('misc.month')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { !globalState.user.readonly_access && <>
                        <Row className="mx-0">
                            <Col sm={12} className="px-0">
                                <div className="dash-box px-4 pt-0">
                                    <CompareRadar dropdowns={dropdowns} params={params} setParams={setParams} />
                                </div>
                            </Col>
                        </Row>
                    </> }
                </div>

                <div className="bordered-container px-3">
                    <Row className="mx-0">
                        <Col className="px-0">
                            <div className="dash-box mt-0 bot-container px-4">
                                <Timeline params={params} title={translate('analysis.timeline')}/>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>}

        </Layout>
    );
};

export default Overall;