import React, {useContext, useEffect, useState} from 'react';
import context from "../../context/context";
import Http from "../../libs/Http";
import {User} from "../../libs/models";
import {DEFAULT_LANG, DEFAULT_PHONE_PREFIX} from "../../constants";
import {Link} from "react-router-dom";
import {Button as ButtonBootstrap, Col, Container, FormFeedback, FormGroup, Row} from "reactstrap";
import CustomInput from "../Reusable/CustomInput";
import Button from '../Reusable/Button';
import SuccessAlert from "../Reusable/SuccessAlert";
import {translate} from "../../libs/trans";
import logo from "../../resources/images/side-logo.png";
import hdCheck from "../../resources/images/hd_check.png";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import Checkbox from "../Reusable/Checkbox";
import {useHistory} from "react-router";
import {FormButton} from "../Reusable/FormComponents";
import Modal from "../Reusable/Modal";

const Login = (props) => {

    const {state: globalState, dispatch} = useContext(context);

    const initialState = {
        email: '',
        password: '',
        version: 1,
        agree: false,
    };

    const [state, setState] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [remember, setRemember] = useState(true);
    const [modalBeta, setModalBeta] = useState(true);
    const [notificationMessage, setNotificationMessage] = useState(false);
    const [errors, setErrors] = useState({});
    const history = useHistory();

    useEffect(() => {
        if (globalState.user && !isLoading) {
            history.push('/');
        }
        if (globalState.notificationMessage) {
            setNotificationMessage(globalState.notificationMessage)
            dispatch({type: "SET_NOTIFICATION_MESSAGE", payload: null});
        }
    }, [globalState.user, isLoading]);

    useEffect(() => {
        if (globalState.user && !isLoading) {
            history.push('/');
        }
        if (globalState.notificationMessage) {
            setNotificationMessage(globalState.notificationMessage)
            dispatch({type: "SET_NOTIFICATION_MESSAGE", payload: null});
        }
    }, [globalState.user, isLoading]);

    useEffect(() => {
        getVersion();
    }, [])

    useEffect(() => {
        if(props.location.hasOwnProperty('hideModal')){
            setModalBeta(false);
        }
    }, [props?.location])


    const getVersion = async () => {
        const res = await Http.getRaw('/version');
        setState(prev => ({...prev, version: res.data}));
    };


    const login = async () => {

        if (isLoading) return false;

        const params = {
            email: state.email,
            password: state.password,
            remember
        };

        setIsLoading(true);

        const res = await Http.post('/login', params);

        if (res.hasError) {

            setErrors(res.error)

        } else {
            sessionStorage.setItem('jwt', res.data.jwt);
            if (remember && res.data.hasOwnProperty('rememberToken')) {
                localStorage.setItem('rememberToken', res.data.rememberToken);
            } else {
                localStorage.removeItem('rememberToken');
            }

            const lang = res.data.user.lang || DEFAULT_LANG;
            localStorage.setItem('lang', lang);
            dispatch({type: "SET_LANGUAGE", payload: lang});

            res.data.user.suspended_screen = res.data.suspended_screen;
            dispatch({type: "SET_USER", payload: User(res.data.user)});
            console.log('%c Version: ' + res.data.app_version, 'color: #152b5c; font-weight: bold');
        }

        setIsLoading(false);
    };


    const _handleChange = (name, value) => {
        if (name === 'email') {
            value = value.toLowerCase();
        }
        setState(prevState => ({...prevState, [name]: value}))

    };

    const _handleAgree = () => {
        const {agree} = state;
        setState(prevState => ({...prevState, agree: !agree}))
    };

    const _enterPressed = (event) => {
        let code = event.keyCode || event.which;
        if (code === 13 && state.agree) {
            login(event);
        }
    };

    return (
        <div>
            <div className={'auth-background-image'}/>
            <div className={'auth-blank-section'}/>

            <div className={'auth-container mt-4'}>
                <div className={'up'}>
                    <Row className="mx-0">
                        <Col xs={12} className="header-login px-0">
                            <div className={'logo-container'}>
                                <img src={logo} alt=""/>
                                <h2 className="">{translate('auth.login.hd_check')}
                                    <span>{translate('auth.login.analyser')}</span></h2>
                            </div>
                        </Col>
                        <Container>
                            <Col xs={12} className={'px-4'}>
                                <span className={'beta'}>BETA</span>
                                <img className={'hd-check mt-5'} src={hdCheck} alt=""/>
                                <h3 className="text-center mb-2">{translate('auth.login.comparison_analytics')}</h3>
                                <h5 className="text-center mb-3">{translate('auth.login.existing_users')}</h5>
                                <SuccessAlert visible={!!globalState.notificationMessage}
                                              customText={notificationMessage}
                                              className={'seatbelt-login-success'}/>
                                <div>
                                    <FormGroup>
                                        {errors.general &&
                                        <FormFeedback>{errors.general}</FormFeedback>}
                                        {errors.email &&
                                        <FormFeedback>{errors.email}</FormFeedback>}
                                        <CustomInput
                                            label={translate('auth.login.email')}
                                            type="text"
                                            value={state.email}
                                            name="email"
                                            onChange={_handleChange}
                                            onKeyPress={_enterPressed}
                                            {...(errors && (errors.email || errors.general) ? {
                                                invalid: true,
                                                icon: faExclamation
                                            } : {})}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
                                        <CustomInput
                                            label={translate('auth.login.password')}
                                            type="password"
                                            value={state.password}
                                            name="password"
                                            onChange={_handleChange}
                                            onKeyPress={_enterPressed}
                                            passwordViewer
                                            {...(errors && (errors.password || errors.general) ? {
                                                invalid: true
                                            } : {})}
                                        />
                                    </FormGroup>

                                    <FormGroup className={'remember-container pt-3'}>
                                        <Checkbox {...(state.agree === true ? {
                                            checked: true
                                        } : {})} onChange={_handleAgree}/>
                                        <span className="cursor-pointer"
                                              onClick={_handleAgree}>{translate('auth.login.agree')}</span>
                                    </FormGroup>

                                    <FormGroup className={'remember-container mb-0'}>
                                        <Checkbox {...(remember === true ? {
                                            checked: true
                                        } : {})} onChange={() => setRemember(!remember)}/>
                                        <span className="cursor-pointer"
                                              onClick={() => setRemember(!remember)}>{translate('auth.login.remember')}</span>
                                    </FormGroup>
                                    <FormButton
                                        size={'xl'}
                                        fluid={true}
                                        className={'mt-4'}
                                        variant={'primary'}
                                        disabled={state.email === '' || state.password === '' || !state.agree}
                                        text={translate('misc.submit')}
                                        onClick={login}/>
                                </div>
                                <p className={'auth-redirect login mt-3 pt-1'}>
                                    <Link to='/forgot-password'>{translate('auth.login.forgot')}</Link>
                                </p>
                                <FormButton
                                    size={'xl'}
                                    fluid={true}
                                    className={'mt-4 mb-5'}
                                    variant={'secondary'}
                                    text={translate('auth.register.title')}
                                    onClick={() => history.push('/register')}/>
                            </Col>
                        </Container>
                    </Row>
            </div>
        </div>

    {
        state.version && <p className='text-right mb-0 version-no'>v{state.version}</p>
    }

            {modalBeta &&  <Modal modal={modalBeta} _toggleModal={() => {}} className={'bd-my-modal modal-center modal-dialog-centered beta-modal'}
                   hideTitle={true}
                   confirmText={'Close'}
                   onAction={() => setModalBeta(!modalBeta)}
                   hideCancelButton={true}
                   buttonFluid={true}
                   buttonSize={'xl'}>
                <Row noGutters={true} className={'iteam-modal-row'}>
                    <Col xs={12}>
                        <span>By using this web application you accept that it is for beta use only: a pre-release for real use testing.</span>
                        <span>We appreciate your feedback on any improvement we can make to the tool.</span>
                    </Col>
                </Row>
            </Modal>}
</div>);
};

export default Login;