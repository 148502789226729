import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const Checkbox = ({disabled, checked, onChange, className}) => {

    const _toggleCheck = () => {
        onChange && onChange(!checked);
    };

    return (
        <div className={`custom-checkbox-box ${className} ${checked ? 'isChecked' : ''} ${disabled ? 'disabled' : ''}`}
             {...(!disabled ? {
                 onClick: _toggleCheck
             } : {})}
        >
            {!disabled && checked && <FontAwesomeIcon icon={faCheck} className="custom-checkbox-checked"/>}
        </div>
    );
};

export default Checkbox;
