import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HeaderNavItem from "./HeaderNavItem";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import logo2 from "../../resources/images/side-logo.png";
import context from "../../context/context";
import NavElementWithDropdown from "./NavElementWithDropdown";
import Http from "../../libs/Http";
import {translate} from "../../libs/trans";

const Header = (props) => {

    const { state: globalState, dispatch } = useContext(context);
    const [lang, setLang] = useState(globalState.lang);

    useEffect(() => {
        const {width} = globalState.responsive;
        if (width <= 768) {
            if(document.querySelector('body').classList.contains('backdrop-menu')){
                document.querySelector('body').classList.remove('backdrop-menu')
            }
        }
    }, []);


    const _toggleSidebar = () => {
        const {width, sidebarOpen} = globalState.responsive;
        if (width <= 1024) {
            document.querySelector('.aside-left').classList.toggle('mobile-expand');
            document.querySelector('.iteam-header').classList.toggle('mobile-expand');
            document.querySelector('.header-actions').classList.toggle('d-none');
            document.querySelector('.header-hamburger').classList.toggle('mobile-expand');
            if (document.querySelector('.table-container')) {
                document.querySelector('.table-container').classList.toggle('disabled');
            }
            dispatch({type: "TOGGLE_SIDEBAR", payload: true});
        } else {
            dispatch({type: "TOGGLE_SIDEBAR", payload: !sidebarOpen});
        }
        if (width <= 768) {
            document.querySelector('.layout-content').classList.toggle('backdrop-menu');
            sidebarOpen && document.querySelector('body').classList.toggle('backdrop-menu');
        }
    };

    const _changeLang = async(_,value) => {
        setLang(value)
        localStorage.setItem('lang',value);
        dispatch({type: "SET_LANGUAGE", payload: value});
        await Http.patch('/user/language', {lang: value});
    };


    return (
        <div>
            <header className={`iteam-header d-block`} id="header-color">

                <div className={'d-flex w-100 justify-content-between'}>
                    <div className="sidebar-logo d-flex">
                        <Link to='/' className={'logo-container'}>
                            <img src={logo2} alt="BD logo" className={'bd-logo ml-3 bd-default-logo'}/>
                            <h2>{translate('auth.login.hd_check')} {translate('auth.login.analyser')}</h2>
                        </Link>
                        {globalState.responsive.isMobile && <div className={'header-hamburger mr-3'}  onClick={_toggleSidebar}>
                            <HeaderNavItem className="blue" customIcon={<FontAwesomeIcon icon={faBars} id={'hamburger-color'}/>} />
                        </div>}
                        {globalState.responsive.width > 575 && props.headerFilters}
                    </div>

                   <div className={'header-actions d-flex justify-content-center'}>
                       {!globalState.responsive.isMobile && <div className={'d-flex align-items-center'}>
                           <div className={'header-hamburger'}  onClick={_toggleSidebar}>
                               <HeaderNavItem className="blue" customIcon={<FontAwesomeIcon icon={faBars} id={'hamburger-color'}/>} />
                           </div>
                           <NavElementWithDropdown width={globalState.responsive.width} user={globalState.user}>
                               <div className={"navbar-dropdown-container profile"}>
                                   <div className={"profile-item border-0 p-0"}>
                                       <Link className="logout" to={"/my-account"}>
                                           <div>
                                               <span className={'profile-dropdown'}>{translate('general.my_account')}</span>
                                           </div>
                                       </Link>
                                   </div>
                                   <div className={"profile-item border-0 p-0 pb-2"}>
                                       <Link className="logout" to={"/logout"}>
                                           <div>
                                               <span className={'profile-dropdown'}>{translate('general.sign_out')}</span>
                                           </div>
                                       </Link>
                                   </div>
                               </div>
                           </NavElementWithDropdown>
                       </div>}
                    </div>
                </div>
                {globalState.responsive.width <= 575 && props.headerFilters}

            </header>
            <div className={`header-border ${globalState.headerLoading ? 'loading' : ''}`}/>
        </div>
    );
};

export default Header;
