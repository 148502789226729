import React from 'react';
import {translate} from "../../../libs/trans";
import MenuItem from "./MenuItem";


const Client = ({sidebarOpen}) => {

    const urlSegments = window.location.pathname.split('/').filter(item => item !== '');

    return (
        <div>

            <div className='sidebar-menu-item'>
                <MenuItem
                    sidebarOpen={sidebarOpen}
                    exact={true}
                    text={translate('sidebar.dashboard')}
                    href={'/'}/>
            </div>

            <div className='sidebar-menu-item'>
                <MenuItem
                    sidebarOpen={sidebarOpen}
                    text={translate('sidebar.results')}
                    href={'/overall-analysis'}
                />
            </div>

            <div className='sidebar-menu-item'>
                <MenuItem
                        sidebarOpen={sidebarOpen}
                          exact={true}
                          text={translate('sidebar.company_details')}
                          href={'/account'}
                />
            </div>

            <div className='sidebar-menu-item'>
                <MenuItem
                    sidebarOpen={sidebarOpen}
                    exact={true}
                    text={translate('sidebar.faq')}
                    href={'/faq'}
                />
            </div>

            <div className='sidebar-menu-item'>
                <MenuItem
                    sidebarOpen={sidebarOpen}
                    exact={true}
                    text={translate('sidebar.account')}
                    href={'/my-account'}
                />
            </div>


            <div className={`sidebar-menu-item ${(['terms', 'gdpr', 'privacy'].some(v => urlSegments.includes(v)) ? 'bg-white' : 'bg-grey')}`}>
                <MenuItem
                    sidebarOpen={sidebarOpen}
                    exact={true}
                    text={translate('sidebar.terms')}
                    href={'/terms'}
                />

                <MenuItem
                    sidebarOpen={sidebarOpen}
                    exact={true}
                    text={translate('sidebar.privacy')}
                    href={'/privacy'}
                />

                <MenuItem
                    sidebarOpen={sidebarOpen}
                    exact={true}
                    text={translate('sidebar.gdpr')}
                    href={'/gdpr'}
                />
            </div>
            <div className="sidebar-menu-item">
                <div className="menu-item">
                    <a href="https://forms.office.com/r/rxujLjzmdS" target="_blank"><p>Give Us Feedback</p></a>
                </div>
            </div>

            <div className='sidebar-menu-item'>
                <MenuItem
                    sidebarOpen={sidebarOpen}
                    exact={true}
                    text={translate('general.sign_out')}
                    href={'/logout'}
                />
            </div>

        </div>
    );
};

export default Client;
