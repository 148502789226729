import React, {useContext, useEffect} from 'react';
import Loading from "./Reusable/Loading";
import Http from "../libs/Http";
import {DEFAULT_LANG} from "../constants";
import {User} from "../libs/models";
import context from "../context/context";
import {useHistory} from "react-router";

const guestRoutes = [
    'login',
    'register',
    's',
    'forgot-password'
];

const Guard = (props) => {

    const { state: globalState, dispatch } = useContext(context);
    const history = useHistory();
    const rememberToken = localStorage.getItem('rememberToken');
    const jwt = sessionStorage.getItem('jwt');

    history.listen(_ => {
        window.scrollTo(0, 0)
    })

    useEffect(() => {

        if (rememberToken && !globalState.user) {
            getUserRememberToken();
        } else if (jwt && !globalState.user) {
            getUserJwtToken();
        } else if (!jwt && guestRoutes.indexOf(history.location.pathname.split('/')[1]) < 0 ) {
            history.push("/login");
        }

        window.addEventListener('resize', () => {
            dispatch({type: "UPDATE_SCREEN_SIZE"});
        });

    }, []);

    if (!window.setHeaderLoading) {
        window.setHeaderLoading = (payload) => {
            dispatch({type: "SET_HEADER_LOADING", payload});
        };
    }

    async function getUserRememberToken() {
        dispatch({ type: "SET_GLOBAL_LOADING", payload: true});
        const res = await Http.post('/login', {rememberToken});
        if (!res.hasError) {
            res.data.user.suspended_screen = res.data.suspended_screen;
            dispatch({type: "SET_USER", payload: User(res.data.user)});
            sessionStorage.setItem('jwt', res.data.jwt);
        } else {
            localStorage.removeItem('rememberToken');
            history.push("/login");
        }
        dispatch({ type: "SET_GLOBAL_LOADING", payload: false});
    }

    async function getUserJwtToken() {
        dispatch({ type: "SET_GLOBAL_LOADING", payload: true});
        const res = await Http.get('/user');

        if (!res.hasError) {
            localStorage.setItem('lang', res.data.lang || DEFAULT_LANG);
            dispatch({ type: "SET_USER", payload: User(res.data)});
        } else {
            sessionStorage.removeItem('jwt');
            history.push("/login");
        }
        dispatch({ type: "SET_GLOBAL_LOADING", payload: false});
    }

    if (globalState.isLoading)
        return <Loading loading={true} />;

    return props.children;
};

export default Guard;
