import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Bar} from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {useAlert} from "react-alert";
import Http from "../../libs/Http";
import moment from "moment";
import context from "../../context/context";

const colors = [
    "#EF6549",
    "#FAD0C8",
    "#0090B5",
    "#ADDFEB",
    "#009988",
    "#A9DCD6",
];

const Timeline = ({title, params, ...props}) => {

    const {state: globalState} = useContext(context);
    const [results, setResults] = useState({
        line: [],
        line_blue: [],
        bars: [],
        labels: {}
    });
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();


    useEffect(() => {
        getCompareResults();
    }, [params]);

    const getCompareResults = useCallback(async () => {
        setIsLoading(true);

        const res = await Http.get('/dashboard/timeline', params);

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            const {bars, line, line_blue} = formatData(res.data);
            setResults({
                bars,
                line,
                line_blue
            });
        }

        setIsLoading(false);
    }, [params]);

    const formatData = (data) => {

        const drugs = Object.keys(Object.values(data.bars)[0]);
        let bars = [];
        let index = 0;

        drugs.forEach(drug_id => {
            bars.push({
                label: data.labels[drug_id] + " - Negative",
                datalabels: {
                    color: 'transparent',
                },
                type: "bar",
                barPercentage: 0.7,
                stack: drug_id,
                backgroundColor: colors[index++],
                data: Object.values(data.bars).map(monthData => {
                    return monthData[drug_id].total_negative;
                }),
            });
            bars.push({
                label: data.labels[drug_id] + " - Positive",
                datalabels: {
                    color: 'transparent',
                },
                barPercentage: 0.7,
                type: "bar",
                stack: drug_id,
                backgroundColor: colors[index++],
                data: Object.values(data.bars).map(monthData => {
                    return monthData[drug_id].total_positive;
                }),
            });
        });

        // get max value of bars to normalize line values
        let maxValue = 0;
        Object.values(data.bars).forEach(drugData => {
            Object.values(drugData).forEach(d => {
                if (d.total_per_month > maxValue) {
                    maxValue = d.total_per_month;
                }
            });
        });
        if (maxValue === 0) {
            maxValue = Math.max(...data.line_blue.map(i => i.percent), ...data.line.map(i => i.percent));
        }

        const line = {
            labels: data.line.map(i => moment().month(i.month - 1).format('MMMM')),
            label: "line",
            type: "line",
            stack: "4",
            fill: false,
            backgroundColor: props.dashboard ? "#000" : "#FF9D00",
            borderColor: props.dashboard ? "#000" : "#FF9D00",
            borderWidth: 4,
            pointRadius:0,
            pointHoverRadius:0,
            // borderDash: [5,5],
            tension: 0,
            data: data.line.map((item) => {
                const yValue = parseInt(item.percent) / 100 * maxValue;

                return ({
                    x: item.month,
                    y: yValue,
                    label: ''
                })
            }),
            datalabels: {
                color: '#000',
                font: {
                    size: 15,
                    weight: 'bold'
                },
                align: 'right',
                offset: 10
            },
            options: {
                elements: {
                    point:{
                        radius: 0
                    }
                }
            }
        };

        const line_blue = {
            labels: data.line_blue.map(i => moment().month(i.month - 1).format('MMMM')),
            label: "line_blue",
            type: "line",
            stack: "blue",
            fill: false,
            backgroundColor: "#0E458B",
            borderColor: "#0E458B",
            borderWidth: 4,
            pointRadius:0,
            pointHoverRadius:0,
            // borderDash: [5,5],
            tension: 0,
            data: data.line_blue.map((item) => {

                const yValue = parseInt(item.percent) / 100 * maxValue;

                return ({
                    x: item.month,
                    y: yValue,
                    label: ''
                })
            }),
            datalabels: {
                color: '#000',
                font: {
                    size: 15,
                    weight: 'bold'
                },
                align: 'right',
                offset: 10
            },
            options: {
                elements: {
                    point:{
                        radius: 0
                    }
                }
            }
        };

        return {
            bars,
            line,
            line_blue,
        };

    }


    return (
        <div>
            { title && <div className={`w-100 justify-content-between align-items-center mb-3 ${globalState.responsive.isMobile ? 'd-block': 'd-flex'}`}>
                <div className="left-side w-100">
                    <h4 className="title">{title}</h4>
                    <div className={`timeline-legend ${props.dashboard ? 'dashboard' : ''}`}>
                        <div className="ave-pos-container"><span className="pos-rate"/> <div>{props.dashboard ? "Your Positive Average" : "Your Average"}</div></div>
                        {results.line_blue?.data?.length > 0 && <div className="ave-pos-container"><span className="pos-rate blue"/> <div>Comparison Average</div></div>}
                    </div>
                </div>
            </div>}
            <div className="big-chart">
                { isLoading ? <div className="chart-spinner-bar-container">
                        <div className="spinner"/>
                    </div> :
                    <Bar plugins={[ChartDataLabels]} data={{
                        labels: results.line.labels,
                        datasets: [results.line, results.line_blue, ...results.bars]
                    }}
                         height={globalState.responsive.isMobile ? 150 : 70}
                         options={{
                             legend: {
                                 display: false
                             },
                             tooltips: {
                                 filter: function (i) {
                                     return i.datasetIndex > 0;
                                 }
                             },
                             scales: {
                                 xAxes: [{
                                     stacked: true,
                                     ticks: {
                                         beginAtZero: true,
                                         maxRotation: 0,
                                         minRotation: 0
                                     }
                                 }],
                                 yAxes: [{
                                     gridLines : {
                                         display : false
                                     },
                                 }]
                             },
                         }}/>}

                <div className={`bar-legend mt-3`}>
                    <div className={'drug-row cyc'}>
                        <div>CYC</div>
                        <div>NEG</div>
                        <div>POS</div>
                    </div>
                    <div className={'drug-row mtx'}>
                        <div>MTX</div>
                        <div>NEG</div>
                        <div>POS</div>
                    </div>
                    <div className={'drug-row dox'}>
                        <div>DOX</div>
                        <div>NEG</div>
                        <div>POS</div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Timeline;