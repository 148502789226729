import React from "react";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Home from "./components/Dashboard/home";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import ProtectedRoute from "./components/Misc/ProtectedRoute";
import Logout from "./components/Auth/Logout";
import Guard from "./components/Guard";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Templates from "./components/Templates"
import ClientsList from "./components/Clients";
import MediaLibrary from "./components/Settings/MediaLibrary";
import CreatePassword from "./components/Auth/CreatePassword";
import Page from "./components/Settings/Page";
import ReportTemplate from "./components/ReportTemplates/ReportTemplate";
import MyAccount from "./components/Settings/MyAccount";
import ClientDetails from "./components/Clients/Partials/Details";
import ResultsList from "./components/Results";
import OverallAnalysis from "./components/Analysis/Overall";
import SubOptions from "./components/Dropdowns/SubOptions";
import Faq from "./components/Settings/faq";
import Settings from "./components/Settings";
import {PERMISSION_ADD_ADMIN, PERMISSION_SAVE_MEDIA} from "./constants";


const Router = () => {

    return (
        <BrowserRouter>
            <Guard>
                <Switch>
                    {/*AUTH*/}
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/logout" component={Logout}/>
                    <Route exact path="/register" component={Register}/>
                    <Route exact path="/s/:code?" component={CreatePassword}/>
                    <Route exact path="/forgot-password/:code?" component={ForgotPassword}/>

                    {/*DASHBOARDS*/}
                    <ProtectedRoute exact path="/" component={Home}/>

                    {/*SMS/EMAIL TEMPLATES*/}
                    <ProtectedRoute exact path="/templates" permission={PERMISSION_ADD_ADMIN} component={Templates}/>

                    {/*ADMIN*/}
                    <ProtectedRoute exact path="/settings" permission={PERMISSION_ADD_ADMIN} component={Settings}/>
                    <ProtectedRoute exact path="/clients"  permission={PERMISSION_ADD_ADMIN}  component={ClientsList}/>
                    <ProtectedRoute exact path="/clients/create" permission={PERMISSION_ADD_ADMIN} component={ClientsList} create={true}/>
                    <ProtectedRoute exact path="/clients/:id" permission={PERMISSION_ADD_ADMIN} component={ClientDetails}/>

                    <ProtectedRoute exact path="/overall-analysis" component={OverallAnalysis}/>

                    {/*CLIENT*/}
                    <ProtectedRoute exact path="/account" component={ClientDetails} client={true}/>

                    {/*Results*/}
                    <ProtectedRoute exact path="/overall-analysis/results" component={ResultsList}/>
                    <ProtectedRoute exact path="/all-results" component={ResultsList}/>

                    {/*SETTINGS*/}
                    <ProtectedRoute exact path="/settings/dropdowns/:id" permission={PERMISSION_ADD_ADMIN} component={SubOptions}/>
                    <ProtectedRoute exact path="/reports" component={ReportTemplate}/>
                    <ProtectedRoute exact path="/media" permission={PERMISSION_SAVE_MEDIA}  component={MediaLibrary}/>
                    <ProtectedRoute exact path="/terms" component={Page}/>
                    <ProtectedRoute exact path="/privacy" component={Page}/>
                    <ProtectedRoute exact path="/faq" component={Faq}/>
                    <ProtectedRoute exact path="/gdpr" component={Page}/>
                    <ProtectedRoute exact path="/my-account" component={MyAccount}/>



                </Switch>
            </Guard>
        </BrowserRouter>
    );
}

export default Router;
