import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const MediaImagePlaceholder = ({path, alt}) => {

    const [loaded, setLoaded] = useState(false);

    return (
        <>
            {!loaded && <span className="loader">
                 <FontAwesomeIcon icon={faSpinner} title={alt} className="fa-spin"/>
                </span>}
            <img className={`img-fluid ${loaded ? 'loaded' : ''}`} style={(!loaded ? {display: 'none'} : {})} src={path}
                 alt={alt} onLoad={() => setLoaded(true)}/>
        </>
    );
};

export default MediaImagePlaceholder;