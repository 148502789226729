import React, {useContext} from 'react';
import {translate} from "../../../libs/trans";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import context from "../../../context/context";
import {faListAlt} from "@fortawesome/free-regular-svg-icons";
import {faTv,faMapMarkerAlt, faUsers,faUserAlt} from "@fortawesome/free-solid-svg-icons";
import MenuItem from "./MenuItem";
import {
    PERMISSION_ADD_CLIENT,
} from "../../../constants";

const Sale = ({sidebarOpen}) => {

    const {state: globalState} = useContext(context);

    return (
        <div>

            <div className='menu-border pb-2'>
                <div className="mt-3">
                    <MenuItem icon={<FontAwesomeIcon icon={faTv}/>}
                              sidebarOpen={sidebarOpen}
                              exact={true}
                              text={translate('sidebar.dashboard')}
                              href={'/'}
                    />
                </div>

                {globalState.user.can(PERMISSION_ADD_CLIENT) && <MenuItem icon={<FontAwesomeIcon icon={faUserAlt}/>}
                                                                          sidebarOpen={sidebarOpen}
                                                                          exact={true}
                                                                          text={translate('sidebar.add_client')}
                                                                          href={'/clients/create'}
                />}

                <MenuItem icon={<FontAwesomeIcon icon={faListAlt}/>}
                          sidebarOpen={sidebarOpen}
                          exact={true}
                          text={translate('sidebar.add_result')}
                          href={'/results'}
                />
            </div>

            <div className="menu-border">
                <p className="menu-title mr-4">
                    <span>{translate('sidebar.customers')}</span>
                </p>
                <MenuItem icon={<FontAwesomeIcon icon={faUsers}/>}
                          sidebarOpen={sidebarOpen}
                          exact={true}
                          text={translate('sidebar.clients')}
                          href={'/clients'}
                />

                <MenuItem icon={<FontAwesomeIcon icon={faUsers}/>}
                          sidebarOpen={sidebarOpen}
                          exact={true}
                          text={translate('sidebar.client_users')}
                          href={'/client-users'}
                />

                <MenuItem icon={<FontAwesomeIcon icon={faMapMarkerAlt}/>}
                          sidebarOpen={sidebarOpen}
                          exact={true}
                          text={translate('sidebar.client_locations')}
                          href={'/client-locations'}
                />
            </div>

            <div className="menu-border">
                <p className="menu-title mr-4">
                    <span>{translate('sidebar.results')}</span>
                </p>
                <MenuItem icon={<FontAwesomeIcon icon={faListAlt}/>}
                          sidebarOpen={sidebarOpen}
                          exact={true}
                          text={translate('sidebar.overall_analysis')}
                          href={'/overall-analysis'}
                />
            </div>


            <div className="menu-border">
                <p className="menu-title mr-4">
                    <span>{translate('sidebar.settings')}</span>
                </p>


                <MenuItem icon={<FontAwesomeIcon icon={faListAlt}/>}
                          sidebarOpen={sidebarOpen}
                          exact={true}
                          text={translate('sidebar.terms')}
                          href={'/terms'}
                />

                <MenuItem icon={<FontAwesomeIcon icon={faListAlt}/>}
                          sidebarOpen={sidebarOpen}
                          exact={true}
                          text={translate('sidebar.gdpr')}
                          href={'/gdpr'}
                />

                <MenuItem icon={<FontAwesomeIcon icon={faListAlt}/>}
                          sidebarOpen={sidebarOpen}
                          exact={true}
                          text={translate('sidebar.faq')}
                          href={'/faq'}
                />

            </div>

        </div>
    );
};

export default Sale;
