import React, {useContext} from 'react';
import context from "../../context/context";
import {Container} from "reactstrap";
import Loading from "../Reusable/Loading";
import AsideLeft from "./AsideLeft";
import Header from "./Header";
import ConfirmModalDelete from "../Reusable/ConfirmModalDelete";

const Layout = (props) => {

    const { state: globalState } = useContext(context);

    const layoutExtraClasses = () => {
        // if (!globalState.user.hasRole(ROLE_ADMIN)) {
        //     return 'layout-secondary';
        // }
        return '';
    };

    return (
        <div className={`layout ${props.className ? props.className : ''}`}>
            <Header headerFilters={props.headerFilters} />
            <Container fluid={true} className={`layout-body ${layoutExtraClasses()} ${!globalState.responsive.sidebarOpen ? 'shrinked-sidebar' : ''}`}>
                <AsideLeft width={globalState.width} sidebarOpen={globalState.responsive.sidebarOpen} user={globalState.user}/>
                <div className={'layout-content'}>
                    {props.children}
                    <Loading loading={props.loading || globalState.isLoading}/>
                </div>
                { globalState.modalDelete.open && <ConfirmModalDelete />}
            </Container>
        </div>
    );
};

export default Layout;