import React, {useEffect, useState} from 'react';
import {Col, FormFeedback, Input as InputBootstrap, Label, Row} from "reactstrap";
import Select from "react-select";
import PhoneJSON from "../../resources/phone.json";

const CustomPhone = (props) => {

    const initialState = {
        isFocused: props.valueInput !== null && props.valueInput !== undefined
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (props.valueInput && !state.isFocused) {
            setState(prevState => ({...prevState, isFocused: true}))
        }
    }, [props.valueInput, state.isFocused])

    /**
     *
     * @param option
     * @param select
     * @private
     */
    const _handleChangeSelect = (option, select) => {
        const value = option.value,
            name = 'phone_country';
        const {onChange} = props;
        onChange && onChange(name, value);
    };

    /**
     * Handle input change
     *
     * @param e
     * @private
     */
    const _handleChangeInput = (e) => {
        const {name, value} = e.target;

        const {onChange} = props;

        onChange && onChange(name, value);
    };

    /**
     * Handle input focus
     *
     * @private
     */
    const _handleFocus = () => {
        setState(prevState => ({...prevState, isFocused: true}))
    };

    /**
     * Handle input blur
     *
     * @private
     */
    const _handleBlur = () => {
        const {valueInput} = props;

        if (valueInput === '' || valueInput === undefined || valueInput === null) {
            setState(prevState => ({...prevState, isFocused: false}))
        }
    };

    const {label, valueInput, valuePrefix, icon, iconDirection, error, borderRightNone, onKeyPress, required} = props;

    const invalid = error !== undefined;

    const options = Object.keys(PhoneJSON).map((code) => ({
        label: `${code} (${PhoneJSON[code]})`,
        value: code
    }));

    const containerClasses = [
        "custom-input-container",
        ...(invalid ? ["custom-input-adjust"] : []),
        ...(borderRightNone ? ["custom-input-container-border-none"] : []),
    ].join(" ");

    const labelClasses = [
        "custom-label",
        ...(state.isFocused ? ["input-focus"] : []),
        ...(icon && iconDirection === 'left' ? ["with-icon"] : []),
    ].join(" ");

    const selectClasses = [
        "custom-select-react",
        ...(invalid ? ["is-invalid"] : [])
    ].join(" ");

    return (
        <>
            {error && <FormFeedback>{error}</FormFeedback>}

            <div className={`${containerClasses} custom-phone mb-3`}>
                <Row>
                    <Col xs={5} className="pr-1 prefix-phone">
                        <Select
                            className={selectClasses}
                            classNamePrefix="react-select"
                            name="phone_country"
                            value={options.filter(option => option.value === valuePrefix)}
                            onChange={_handleChangeSelect}
                            options={options}
                        />
                    </Col>
                    <Col xs={7} className="pl-0 value-phone">

                        {label && <Label className={`${labelClasses} ${error ? 'error' : ''}`}>
                            {label}{required && <span className={'field-required'}>*</span>}
                        </Label>}

                        <InputBootstrap className="custom-input"
                                        name="phone"
                                        type="text"
                                        value={valueInput || ''}
                                        onFocus={_handleFocus}
                                        onBlur={_handleBlur}
                                        onChange={_handleChangeInput}
                                        onKeyPress={onKeyPress}
                                        autoComplete={'off'}
                                        {...(invalid ? {
                                            invalid: true
                                        } : {})}>
                        </InputBootstrap>
                    </Col>
                </Row>

            </div>
        </>
    );
};

export default CustomPhone;