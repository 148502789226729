import React, {useCallback, useContext, useEffect, useState} from 'react';
import CustomSelect from "../Reusable/CustomSelect";
import {Radar} from "react-chartjs-2";
import context from "../../context/context";
import Http from "../../libs/Http";
import {useAlert} from "react-alert";
import {
    Col,
    Modal as BootstrapModal,
    ModalBody as BootstrapModalBody,
    Row
} from "reactstrap";
import {translate} from "../../libs/trans";
import HorizontalBarChart from "./HorizontalBarChart";
import {ROLE_ADMIN} from "../../constants";
import {FormButton} from "../Reusable/FormComponents";
import {useHistory} from "react-router-dom";

const filterName = ['Dept/Area', 'Surface', "Status Surface"];
const filter = ['dept_area', 'surface', "surface_status"];

const plugin = {
    id: 'custom_canvas_background_color',
    afterDraw: (chart) => {
        const scale = chart.scale;
        const ctx = chart.canvas.getContext('2d');
        ctx.beginPath();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'rgba(255,255,255,1)';

        let pointLabelPosition = scale.getPointPosition(0, scale.drawingArea)
        ctx.moveTo(pointLabelPosition.x, pointLabelPosition.y);
        for (let i = 0; i < scale.pointLabels.length; i++) {
            pointLabelPosition = scale.getPointPosition(i, scale.drawingArea)
            ctx.lineTo(pointLabelPosition.x, pointLabelPosition.y);
        }
        ctx.closePath();
        ctx.fill();
        ctx.save();
        ctx.restore();
    }
};

const CompareRadar = ({params, setParams, dropdowns}) => {


    const {state: globalState} = useContext(context);
    const [modal, setModal] = useState(false);
    const [item, setItem] = useState({});
    const [offices, setOffices] = useState([]);
    const [results, setResults] = useState({
        company: {},
        others: {},
        labels: [],
        options: []
    });
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const history = useHistory();

    useEffect(() => {
        getCompareResults();
    }, [params]);

    const toggleModal = () => {
        setModal(!modal);
    };

    useEffect(() => {
        _getOffices()
    }, [params?.company_id]);

    const openMoreModal = (item, key) => _ => {
        item.key = key;
        setItem(item)
        setModal(true);
    };

    const getCompareResults = useCallback(async () => {

        setIsLoading(true);

        const res = await Http.get('/dashboard/compare', params);

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            setResults(res.data);
        }

        setIsLoading(false);
    }, [params]);

    const _getOffices = async () => {

        setIsLoading(true);

        const paramsData = {
            ...(globalState.user.hasRole(ROLE_ADMIN) && {company_id: params.company_id})
        };

        const res = await Http.get('/options/office', paramsData);

        if (!res.hasError) {

            let tempOffices = res.data

            tempOffices.unshift({
                value: 'all',
                label: translate('misc.all')
            });

            setOffices(tempOffices);
        }

        setIsLoading(false);
    };

    return (

        <div className="row">
            <Col lg={3} md={4} sm={12}>

                <div className="stats-line orange mt-0">
                    <div>My Results</div>
                    <div><span/></div>
                </div>
                {params.company_id !== 'all' &&
                <div className="select-container">
                    <CustomSelect
                        className="mb-3"
                        name="office_id"
                        label={"Sub Account"}
                        value={params.office_id}
                        options={offices}
                        onChange={(name, value) => setParams(params => ({...params, office_id: value}))}
                    />
                </div>}

                <div className="select-container">
                    <CustomSelect
                        className="mb-3"
                        label="Result"
                        onChange={(name, value) => setParams(params => ({...params, result: value}))}
                        value={params.result}
                        options={[
                            {label: "All", value: 'all'},
                            {label: "Positive Results", value: 1},
                            {label: "Negative Results", value: 0}
                        ]}
                    />
                </div>

                <div className="select-container">
                    <div className="nested-select-return mb-3">
                        <span/>
                        <CustomSelect
                            name="surface_id_sub"
                            label={`Retest`}
                            value={params.retest}
                            options={[
                                {label: "All", value: 'all'},
                                {label: "Yes", value: 1},
                                {label: "No", value: 0}
                            ]}
                            onChange={(name, value) => setParams(params => ({...params, retest: value}))}
                        />
                    </div>
                </div>

                <div className="select-container">
                    <CustomSelect
                        className="mb-3"
                        label="Dept/Area"
                        onChange={(name, value) => setParams(params => ({...params, area_id: value}))}
                        value={params.area_id}
                        options={[{label: 'All', value: 'all'}].concat(dropdowns.dept_area)}
                    />
                </div>

                <div className="select-container">
                    <CustomSelect
                        className="mb-3"
                        label="Surface"
                        onChange={(name, value) => setParams(params => ({...params, surface_id: value}))}
                        value={params.surface_id}
                        options={[{label: 'All', value: 'all'}].concat(dropdowns.surface)}
                    />
                </div>

                <div className="select-container">
                    <CustomSelect
                        className="mb-3"
                        label="Surface Status"
                        onChange={(name, value) => setParams(params => ({...params, surface_status_id: value}))}
                        value={params.surface_status_id}
                        options={[{label: 'All', value: 'all'}].concat(dropdowns.surface_status)}
                    />
                </div>


                <div className="stats-line blue">
                    <div>Compare to</div>
                    <div><span/></div>
                </div>

                <div className="select-container">
                    <CustomSelect
                        className="mb-3"
                        onChange={(name, value) => setParams(params => ({
                            ...params,
                            type: value,
                            previous_office_id: null,
                            hospital_type_id: null,
                            country_id: null
                        }))}
                        value={params.type}
                        options={[
                            {label: 'My Previous Results', value: 'previous_results'},
                            {label: 'By Institution Benchmarks', value: 'institution_benchmarks'},
                            {label: 'By Type of Hospital', value: 'hospital_type'},
                            {label: 'By Country', value: 'country'},
                        ]}
                    />
                </div>

                {(params.type === 'previous_results' && params.company_id !== 'all') &&
                <div className="select-container">
                    <CustomSelect
                        className="mb-3"
                        name="office_id"
                        label={"Sub Account"}
                        value={params.previous_office_id}
                        options={offices}
                        onChange={(name, value) => setParams(params => ({...params, previous_office_id: value}))}
                    />
                </div>}

                {params.type === 'hospital_type' && <div className="select-container">
                    <div className="nested-select-return mb-3">
                        <span/>
                        <CustomSelect
                            name="hospital_type_id"
                            label={`Hospital Type`}
                            value={params.hospital_type_id}
                            options={[{label: 'All', value: 'all'}].concat(dropdowns.hospital_type)}
                            onChange={(name, value) => setParams(params => ({...params, hospital_type_id: value}))}
                        />
                    </div>
                </div>}

                {params.type === 'country' && <div className="select-container">
                    <div className="nested-select-return mb-3">
                        <span/>
                        <CustomSelect
                            name="country_id"
                            label={`Country`}
                            value={params.country_id}
                            options={[{label: 'All', value: 'all'}].concat(dropdowns.countries)}
                            onChange={(name, value) => setParams(params => ({...params, country_id: value}))}
                        />
                    </div>
                </div>}


            </Col>
            <Col lg={9} md={8} sm={12}>
                <Row className="mx-0">
                    <Col lg={globalState.responsive.width > 1400 ? 7 : 12}>
                        <div className="chart-container pt-4 mx-auto" style={{maxWidth: '450px'}}>
                            {isLoading ? <div className="chart-spinner-container">
                                    <div className="spinner"/>
                                </div> :
                                <Radar width={1} height={1} plugins={[plugin]} data={{
                                    labels: results.labels,
                                    datasets: [
                                        {
                                            label:'1',
                                            data: Object.values(results.company).map(i => i.percent),
                                            backgroundColor: 'rgba(255, 157, 0, 0.3)',
                                            borderColor: 'rgb(255,157,0)',
                                            borderWidth: 3,
                                            pointRadius: 0
                                        },
                                        {
                                            label:'2',
                                            data: Object.values(results.others).map(i => i.percent),
                                            backgroundColor: 'rgba(14,69,139, .3)',
                                            borderColor: 'rgb(14,69,139)',
                                            borderWidth: 3,
                                            pointRadius: 0
                                        },

                                    ],
                                }} options={{
                                    scale: {
                                        pointLabels: {
                                            fontColor: '#000',
                                            fontSize: 12,
                                            fontFamily: 'Roboto',
                                            fontStyle: 'bold'
                                        },
                                        ticks: {
                                            display: false,
                                            suggestedMin: 0,
                                            suggestedMax: 100,
                                            maxTicksLimit: 10,
                                            beginAtZero: true
                                        },
                                        gridLines: {
                                            color: 'rgba(0,0,0,.3)'
                                        },
                                        angleLines: {
                                            color: 'rgba(0,0,0,.3)'
                                        }
                                    },
                                    tooltips: {
                                        enabled: false
                                    },
                                    legend: {
                                        display: false,
                                    },
                                }}/>
                            }
                        </div>
                    </Col>
                    <Col lg={globalState.responsive.width > 1400 ? 5 : 12} className={`px-0 mt-4 ${globalState.responsive.width > 1400 ? 'mr-0' : ''} compare-table-content`}>
                        {Object.values(results.company).length > 0 &&  <><div className="compare-table">
                            <Row className="header mx-0">
                                <Col xs={8}>
                                    <div className={'header-container'}>
                                        <div className="orange">My Results</div>
                                        <div className="line orange"/>
                                    </div>
                                </Col>
                                <Col xs={4} className="px-1">
                                    <div className={'header-container'}>
                                        <div className="blue">Compare to</div>
                                        <div className="line blue"/>
                                    </div>

                                </Col>
                            </Row>

                            {Object.values(results.company).map((item, key) => {
                                let arrowDirection;
                                const companyPercent = parseFloat(item.percent);
                                const othersPercent = parseFloat(results.others[item.drug_tested_id].percent)

                                if (companyPercent > othersPercent) {
                                    arrowDirection = 'up'
                                } else if (companyPercent < othersPercent) {
                                    arrowDirection = 'down';
                                }

                                return <div className="drug-row" key={key}>
                                    <Col xs={8} className="d-flex align-items-center justify-content-between">
                                        <div>{item.name}</div>
                                        <div className="d-flex align-items-center">
                                            <div className={`arrow-${arrowDirection}`}/>
                                            <div className="ml-3">{companyPercent.toFixed(0)}%</div>
                                        </div>
                                        <FormButton
                                            size={'small'}
                                            variant={'secondary'}
                                            text={translate('misc.more')}
                                            onClick={openMoreModal(item, key)}/>
                                    </Col>
                                    <Col xs={4}>{othersPercent.toFixed(0)} %</Col>
                                </div>
                            })}
                        </div>
                            { !globalState.user.readonly_access && <FormButton
                                fluid={true}
                                size={"xl"}
                                className={"my-3"}
                                variant={'primary'}
                                text={'Add New Result/s'}
                                onClick={() => history.push({
                                    pathname: '/overall-analysis/results',
                                    create: true
                                })}/>}
                            <FormButton
                                fluid={true}
                                size={"xl"}
                                variant={'secondary'}
                                text={'Existing Results'}
                                onClick={() => history.push('/overall-analysis/results')}/>
                        </>}
                    </Col>
                </Row>
            </Col>


            <BootstrapModal isOpen={modal} toggle={toggleModal}
                            className="general-modal modal-upload horizontal-bar-modal">
                <BootstrapModalBody>
                    <div className="container-fluid px-0">
                        <Row className={'invalid-negative-margin mx-0'}>
                            {filter.map((i, key) => <Col sm={12} key={key} className={'mb-3 px-0'}>
                                <div className={'horizontal-container'}>
                                    <h5>{filterName[key]}</h5>
                                <HorizontalBarChart item={item}
                                                    params={params}
                                                    drug_tested_id={item.drug_tested_id}
                                                    filter={i}
                                />
                                </div>
                            </Col>)}

                            <Col sm={12} className={`${globalState.responsive.isDesktop ? 'text-center' : ""} pb-5 m-auto`}>
                                <FormButton
                                    size={!globalState.responsive.isDesktop ?  'xl' : 'xxl'}
                                    fluid={!globalState.responsive.isDesktop}
                                    className={'w-450 ml-1'}
                                    variant={!globalState.responsive.isDesktop ? 'secondary' : "primary"}
                                    text={!globalState.responsive.isDesktop ? translate('misc.back') : translate('misc.close') }
                                    onClick={toggleModal}/>
                            </Col>
                        </Row>
                    </div>
                </BootstrapModalBody>
            </BootstrapModal>
        </div>
    );
};

export default CompareRadar;