import Http from "../libs/Http";

export const pick = (...props) => o => props.reduce((a, e) => ({ ...a, [e]: o[e] }), {});

export const impersonate = async params =>  {
    let user_id = params.hasOwnProperty('owner_id') ? params.owner_id : params.id;
    const res = await Http.get('/admin/impersonate/' + user_id);

    if (!res.hasError) {
        sessionStorage.setItem('jwt', res.data.jwt);
        localStorage.removeItem('rememberToken');
        window.location.href = '/';
    } else {
        sessionStorage.removeItem('jwt');
        window.location.href = '/login';
    }
};

export const timeoutLogout = () => {
    // clearTimeout(logoutTimeout);
    // window.logoutTimeout = setTimeout(() => {
    //     window.location.href = '/logout/1';
    // }, SESSION_TIMEOUT);
};


export const scrollToError = function() {
    setTimeout(() => {
        let elem = document.getElementsByClassName('invalid-feedback')[0];
        if (elem) {
            const yOffset = -60;
            const y = elem.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
    })
};

export const scrollToErrorInModal = function() {
    setTimeout(() => {
        let elem = document.getElementsByClassName('invalid-feedback')[0];
        let modal = document.getElementsByClassName('modal-body')[0];

        if (elem) {
            const yOffset = -60;
            const y = elem.getBoundingClientRect().top + window.pageYOffset + yOffset;
            modal && modal.scrollIntoView({top: y, behavior: "smooth"});
        }
    })
};

export const scrollToTop = function() {
    let elem = document.getElementsByClassName('layout-body')[0];
    elem && elem.scrollIntoView({top: 0, behavior: "smooth"});
};

export const scrollToTopInModal = function() {
    let elem = document.getElementsByClassName('modal-header')[0];
    if (!elem) {
        elem = document.getElementsByClassName('modal-body')[0];
    }
    elem && elem.scrollIntoView({behavior: "smooth"});
};

export const listenEvents = (event_name, callback) => {
    // const userStore = store.getState().user;
    // if (userStore.socketConnected)
    //     window.echo.private(userStore.user.channel).listen('.' + event_name, ({data}) => callback(data));
};

export const stopListenEvents = (event_name) => {
    // const userStore = store.getState().user;
    // if (userStore.socketConnected)
    //     window.echo.private(userStore.user.channel).stopListening('.' + event_name);
};

export const empty = (variable) =>  {

    if (variable === undefined || variable === null) {
        return true;
    }

    return (Array.isArray(variable) && variable.length === 0) || (typeof variable === "object" && Object.keys(variable).length === 0);
};

export const plainText = (e) => {
    let bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
    let new_contents = bufferText.replace(/(<([^>]+)>)/g, "");
    e.preventDefault();
    setTimeout( function(){
        document.execCommand( 'insertText', false, new_contents );
    }, 10 );
};

export const highlightQuery = (str, query) => {

    if (!query) return str;

    query = query.replace(/[,]*[+]*[\s]+/g, ' ');

    if (query.includes(' ')) {
        const parts = query.split(' ').filter(i => i);
        let returned = highlightQuery(str, parts[0].trim());
        for (let i = 1; i < parts.length ; i++) {
            returned = highlightQuery(returned, parts[i].trim());
        }
        return returned;
    }

    const n = str.toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
        return str; // bail early
    }
    const l = q.length;
    return str.substr(0, x) + '<b class="highlight">' + str.substr(x, l) + '</b>' + str.substr(x + l);
}


export const generateOfficeName = (clientNumber = '', officesNumber = '') => {
    return clientNumber.toString().padStart(3, '0') + '-' + (officesNumber + 1).toString().padStart(3, '0')
};
