import React, {useCallback, useContext, useEffect, useState} from 'react';
import {HorizontalBar} from "react-chartjs-2";
import {useAlert} from "react-alert";
import Http from "../../libs/Http";
import context from "../../context/context";
import {ROLE_ADMIN} from "../../constants";
import CustomSelect from "../Reusable/CustomSelect";
import {translate} from "../../libs/trans";

const colors = [
    ['#EF6549', '#FAD0C8'],
    ['#0090B5', '#ADDFEB'],
    ['#009988', '#A9DCD6'],
];

const HorizontalBarChart = (props) => {

    const {state: globalState} = useContext(context);
    const [params, setParams] = useState({
        ...(globalState.user.hasRole(ROLE_ADMIN) && {company_id: props.params.company_id}),
        filter: props.filter || 'dept_area',
        drug_tested_id: props.drug_tested_id,
        others_timespan: props.params.others_timespan,
        area_id: props.params.area_id || null,
        surface_id: props.params.surface_id || null,
        surface_status_id: props.params.surface_status_id || null,
        retest: props.params.retest || null
    });
    const [results, setResults] = useState({
        bars: [],
        labels: {}
    });
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        getResults();
    }, [params]);

    useEffect(() => {
        document.getElementById(`download-${props.filter}`).addEventListener('click', function () {
            let url_base64jp = document.getElementById(`barChart-${props.filter}`).toDataURL("image/jpg");
            let a = document.getElementById(`download-${props.filter}`);
            a.href = url_base64jp;
        });
    }, []);

    const getResults = useCallback(async () => {
        setIsLoading(true);

        const res = await Http.get('/dashboard/horizontal-bar', params);

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            const {bars, labels} = formatData(res.data);
            setResults({
                bars,
                labels
            });
        }

        setIsLoading(false);
    }, [params]);

    const formatData = (data) => {

        let bars = [];

        bars.push({
            label: 'Negative',
            datalabels: {
                color: 'transparent',
            },
            stack: 'stackbar',
            backgroundColor: Array(Object.values(data.bars).length).fill(colors[props.item.key][0]),
            data: Object.values(data.bars).map(i => i.total_negative),
        });
        bars.push({
            label: 'Positive',
            datalabels: {
                color: 'transparent',
            },
            stack: 'stackbar',
            backgroundColor: Array(Object.values(data.bars).length).fill(colors[props.item.key][1]),
            data: Object.values(data.bars).map(i => i.total_positive),
        });

        return {
            bars,
            labels: data.labels
        };

    }
    const chartHeight = () => {
        let height = 100;

        if (!globalState.responsive.isMobile) {
            if (params.filter === 'surface') {
                height = 300;
            } else if (params.filter === 'dept_area') {
                height = 200;
            }
        } else {
            height = 150;
            if (params.filter === 'surface') {
                height = 500;
            }
        }

        if (Object.keys(results.labels).length === 1) {
            height = 40;
        }

        return height
    }

    return (
        <div className={props.showHeader ? 'bg-white' : ""}>
            <div className="horizontal-bar px-2 position-relative">
                {props.showHeader &&
                <div className={`header-row`}>
                    <div className="left-side d-flex">
                        <div className="title ml-4 pl-2">{translate('misc.most_recent')}
                            <span> {props.item.name}</span> {translate('misc.total')}</div>
                    </div>
                </div>}
                <div className="big-chart">
                    {props.showHeader && <div className="mt-4">
                        <CustomSelect
                            onChange={(name, value) => setParams(params => ({...params, filter: value}))}
                            value={params.filter}
                            className="border-bottom-select"
                            options={[
                                {label: translate('analysis.by_department'), value: 'dept_area'},
                                {label: translate('analysis.by_surface'), value: 'surface'},
                                {label: translate('analysis.by_surface_status'), value: 'surface_status'},
                            ]}
                        />

                    </div>}
                    {isLoading ? <div className={`chart-spinner-bar-container`}>
                            <div className="spinner"/>
                        </div> :
                        <HorizontalBar id={`barChart-${props.filter}`} data={{
                            labels: Object.values(results.labels),
                            datasets: [...results.bars]
                        }}
                                       height={chartHeight()}
                                       options={{
                                           tooltips: {
                                               position: 'nearest'
                                           },
                                           legend: {
                                               display: false,
                                           },
                                           scales: {
                                               xAxes: [{
                                                   position: "top",
                                                   ticks: {
                                                       beginAtZero: true,
                                                       fontSize: 18,
                                                       fontColor: "#000",
                                                       maxTicksLimit: 5,
                                                   }
                                               }],
                                               yAxes: [{
                                                   stacked: true,
                                                   gridLines: {
                                                       display: false
                                                   },
                                               }]
                                           },
                                       }}/>}

                </div>
            </div>
            <div className={`mt-5 px-0 pb-3 ${isLoading ? "d-none" : ""}`}>
                <a id={`download-${props.filter}`}
                   download="ChartImage.jpg"
                   href=""
                   className="download-chart">Download Image</a>
            </div>
        </div>
    );
};

export default HorizontalBarChart;