import React, {useContext, useEffect, useRef, useState} from 'react';
import context from '../../context/context';
import Layout from "../Misc/Layout";
import {translate} from "../../libs/trans";
import moment from "moment";
import {
    Col,
    Button as ButtonBootstrap,
    Row,
    Modal as BootstrapModal,
    ModalBody as BootstrapModalBody,
    ModalHeader as BootstrapModalHeader, FormGroup
} from "reactstrap";
import Table from "../Reusable/Table";
import Http from "../../libs/Http";
import TableHover from "../Reusable/TableHover";
import Modal from "../Reusable/Modal";
import CustomInput from "../Reusable/CustomInput";
import {
    COMPOUNDING_DEVICE,
    DEFAULT_PHONE_PREFIX, HD_PREPARATIONS, HOSPITAL_TYPE, ONCOLOGY_BEDS, ONCOLOGY_CHAIRS,
    PERMISSION_ADD_CLIENT,
    PERMISSION_IMPERSONATE,
} from "../../constants";
import {useAlert} from "react-alert";
import CustomSelect from "../Reusable/CustomSelect";
import {faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {generateOfficeName, impersonate, pick, scrollToError, scrollToTopInModal} from "../../libs/helpers";
import {useHistory} from "react-router";
import ReactSummernote from "react-summernote";
import CustomSelectCreate from "../Reusable/CustomSelectCreate";
import Button from "../Reusable/Button";
import {FormButton} from "../Reusable/FormComponents";

const officeSchema = {name: null, country_id: null};

const ClientsList = (props) => {

    const initialState = {
        modalExtendAccess: false,
        modalEdit: false,
        modalResendInvite: false,
        modal: !!props.create,
        item: {
            phone_country: DEFAULT_PHONE_PREFIX
        },
        office: {
            country_id: 39
        },
        office_id: null,
        owner_id: null,
        owner: {
            phone_country: DEFAULT_PHONE_PREFIX
        }

    };

    const {state: globalState} = useContext(context);
    const [state, setState] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [offices, setOffices] = useState([{...officeSchema}]);
    const [errors, setErrors] = useState({});
    const [maxId, setMaxId] = useState(null);
    const [countries, setCountries] = useState([]);
    const [template, setTemplate] = useState({});
    const [forceHover, setForceHover] = useState(false);
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [currentCountry, setCurrentCountry] = useState(39);

    const alert = useAlert();
    const history = useHistory();
    const wrapperRef = useRef();

    const [refreshTable, doRefresh] = useState(0);
    const refreshTableResults = () => doRefresh(i => ++i);

    useEffect(() => {
        if (state.modal === false && state.modalEdit === false && state.modalExtendAccess === false) {
            setState(prevState => ({
                ...prevState,
                item: {phone_country: DEFAULT_PHONE_PREFIX},
                office: {country_id: 39},
                owner: {phone_country: DEFAULT_PHONE_PREFIX}
            }));
            setErrors({});
        }
    }, [state.modal, state.modalEdit, state.modalExtendAccess]);

    useEffect(() => {
        if (state.modal === true) {
            _getMaxId()
            setOffices([{name: null, country_id: 39}])
        }
    }, [state.modal]);


    useEffect(() => {
        if (props.create === true) {
            setState(prevState => ({...prevState, modal: true}));
        }
    }, [props.create]);

    useEffect(() => {
        _getDropdownOptions();
    }, [])

    const _getDropdownOptions = async (params) => {
        const res = await Http.get('/dropdown-options',{...params, client: true});
        if (!res.hasError) {
            setDropdownOptions(res.data);
        }
    };

    const _getMaxId = async () => {
        const res = await Http.get('/max-number');
        if (!res.hasError) {
            setMaxId(res.data);
        }
    };

    useEffect(() => {
        if (!countries.length && state.modal === true) {
            _getCountries();
        }
    }, [countries.length, state.modal]);

    useEffect(() => {

        if (state.modalResendInvite && !template.id)
            _getTemplateDetails();

    }, [state.modalResendInvite]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => document.removeEventListener('click', handleClickOutside, true);
    }, []);

    useEffect(() => {
        if (maxId) {
            const tempOffices = JSON.parse(JSON.stringify(offices));
            tempOffices[0]['name'] = generateOfficeName(maxId, 0);
            tempOffices[0]['country_id'] = 39;
            setOffices(tempOffices);
        }
    }, [maxId,state.modal])

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target))
            setForceHover(false);
    };

    const _handleChange = (name, value) => {
        setState(prevState => ({...prevState, item: {...prevState.item, [name]: value}}));
    };

    const _handleChangeOffice = (key, name, value) => {
        let tempOffices = JSON.parse(JSON.stringify(offices));
        tempOffices[key][name] = value;
        setOffices(tempOffices);
    };

    const _addOfficeRange = () => {
        let tempOffices = JSON.parse(JSON.stringify(offices));
        tempOffices.push({
            name: generateOfficeName(maxId, tempOffices.length),
            country_id: currentCountry
        });
        setOffices(tempOffices);
    };

    const _deleteOfficeRange = key => () => {
        let tempOffices = JSON.parse(JSON.stringify(offices));
        if (offices.length <= 1) {
            return false;
        }
        tempOffices = tempOffices.filter((i, ind) => ind !== key);
        setOffices(tempOffices);
    };

    const _handleChangeOwner = (name, value) => {
        setState(prevState => ({...prevState, owner: {...prevState.owner, [name]: value}}));
    };


    const _toggleModal = () => {
        setState(prevState => ({...prevState, modal: !prevState.modal}));
    };

    const _toggleModalEdit = () => {
        setState(prevState => ({...prevState, modalEdit: !prevState.modalEdit}));
    };

    const _toggleModalExtendAccess = () => {
        setState(prevState => ({...prevState, modalExtendAccess: !prevState.modalExtendAccess}));
    };

    const _openModalExtendAccess = (item) => {
        setState(prevState => ({...prevState, item}));
        _toggleModalExtendAccess()
    };



    const _openEditModal = async (item) => {

        let data = JSON.parse(JSON.stringify(item));

        if (data.phone && data.phone.includes('-')) {
            const arrPhone = data.phone.split('-');
            data.phone = arrPhone[1];
        }

        setState(prevState => ({
            ...prevState,
            office_id: data?.main?.id,
            owner_id: data?.owner_id,
            item:data
        }));

        _toggleModalEdit();
    };


    const _getTemplateDetails = async () => {

        setIsLoading(true);

        const res = await Http.get('/templates/new_company_verification');

        if (!res.hasError) {
            setTemplate(res.data);
        }

        setIsLoading(false);
    };

    const getItems = params => Http.get('/clients', {...params});

    const deleteItem = async params => {
        setIsLoading(true);
        const res = await Http.delete('/clients/', {client_id: params.id});
        setIsLoading(false);
        if (res.hasError) {
            alert.show(res.error.general, {type: 'error'});
        } else {
            alert.show(translate('notifications.delete_company'), {type: 'success'});

        }
        refreshTableResults();
    };

    const suspendItem = async params => {
        setIsLoading(true);
        const res = await Http.patch('/clients/' + params.id + '/toggle-suspend');
        setIsLoading(false);
        if (res.hasError) {
            alert.show(res.error.general, {type: 'error'});
        } else {
            if (res.data.suspended) {
                alert.show(translate('notifications.suspend_company'), {type: 'success'});
            } else {
                alert.show(translate('notifications.unsuspend_company'), {type: 'success'});
            }

        }
        refreshTableResults();
    };

    const _save = async () => {

        if (isLoading) return false;

        setIsLoading(true);

        let res;
        let params;

        const attr = [
            'phone_country', 'hospital_type_id',
            'oncology_chairs_id', 'oncology_beds_id',
            'hd_preparations_id', 'compounding_device_id'
        ];
        let company = pick(...attr)(state.item);


        if (state.item?.id) {
            params = {
                company,
                office_id: state.office_id,
                owner_id: state.owner_id
            };

            res = await Http.patch('/clients/' + state.item.id, params);

        } else {

            let owner = JSON.parse(JSON.stringify(state.owner));
            params = {
                company,
                offices,
                owner
            };

            res = await Http.post('/register', params);
        }

        setIsLoading(false);

        if (res.hasError) {
            setErrors(res.error);
            scrollToTopInModal();
        } else {
            if(!Number.isInteger(state.item.compounding_device_id)){
                _getDropdownOptions();
            }
            if (state.item?.id) {
                alert.show(translate('notifications.update_company'), {type: 'success'});
                _toggleModalEdit();
            } else {
                alert.show(translate('notifications.add_company'), {type: 'success'});
                _toggleModal();
            }
            refreshTableResults();
        }

    };

    const _extendAccess = async () => {

        if (isLoading) return false;

        setIsLoading(true);

        let res = await Http.patch(`/clients/${state.item.id}/extend-access`);

        setIsLoading(false);

        if (res.hasError) {
            setErrors(res.error);
            scrollToError();
        } else {
            alert.show(translate('notifications.extend_access'), {type: 'success'});
            _toggleModalExtendAccess();
            refreshTableResults();
        }
    };


    const _getCountries = async () => {
        const res = await Http.get('/options/country');
        if (!res.hasError) {
            setCountries(res.data);
        }
    };

    const _openResentInviteModal = (item) => {
        setState(prevState => ({...prevState, modalResendInvite: !prevState.modalResendInvite, item: item.owner}));
    };

    const _toggleResentInviteModal = () => {
        setState(prevState => ({...prevState, modalResendInvite: !prevState.modalResendInvite, item: {phone_country: DEFAULT_PHONE_PREFIX}}));
    };

    const _resendInvite = async () => {

        if (isLoading) return false;

        setIsLoading(true);

        const res = await Http.post(`/user/${state.item.id}/resend-invite-email`, {type: 'company_verification'});

        if (res.hasError) {
            setErrors(res.error);
        } else {
            refreshTableResults();
            alert.show(translate('templates.resend_customer_code'), {type: 'success'});
        }

        setIsLoading(false);

        _toggleResentInviteModal();
    };

    const onInit = (name, value) => (note) => {
        note.reset()

        const regex = /(<\w*)((\s\/>)|(.*<\/\w*>))/i

        if (value.match(regex) !== null) {
            note.replace(value)
        } else {
            note.insertText(value)
        }
    }

    const isExpired = (expiry_date) =>{
        return  moment().format('YYYY-MM-DD') >= expiry_date
    };


    const columns = [
        {
            colSize: 8,
            text: translate('clients.name'),
            name: 'id',
            sortable: true,
            colSizeTablet: 10,
            colSizeMobile: 20,
        },
        {
            colSize: 10,
            text: translate('clients.verification_code'),
            colSizeTablet: 16
        },
        {
            colSize: 20,
            text: translate('auth.register.hospital_type')
        },
        {
            colSize: 12,
            text: translate('clients.oncology_chairs')
        },
        {
            colSize: 10,
            text: translate('clients.oncology_beds')
        },
        {
            colSize: 10,
            text: translate('clients.hd_preparations')
        },
        {
            colSize: 12,
            text: translate('clients.compounding_device')
        },
        {
            colSize: 6,
            customClass: "d-flex justify-content-center",
            text: translate('misc.invite'),
            colSizeTablet: 15,
            colSizeMobile: 40,
        },
        {
            colSize: 8,
            customClass: "d-flex justify-content-center",
            text: translate('misc.status'),
            colSizeTablet: 20,
            colSizeMobile: 20,
        },
        {
            colSize: 1,
            text: '',
            colSizeTablet: 5,
            colSizeMobile: 5,
        }
    ];

    const {item, owner} = state;

    return (
        <Layout>
            <div className="bordered-container">
                <h3 className={'mt-3 pl-4'}>{translate('sidebar.clients')}</h3>
            <div className="table-container">
                <div className={'table-content add-users'}>
                    <Table columns={columns}
                           getItems={getItems}
                           setItems={setItems}
                           refreshTable={refreshTable}
                           buttonsContainer={globalState.user.can(PERMISSION_ADD_CLIENT) &&  <FormButton
                               size={'large'}
                               variant={'secondary'}
                               text={"+ " + translate('clients.add')}
                               onClick={_toggleModal}/>}>
                        {!!items.length && items.map((company, key) => {
                            return (
                                <div className={`table-row-item row align-items-center m-0 ${forceHover === key ? 'force-hover' : 'stop-hover'}`} key={key}>
                                    <div className="pr-3 cursor-pointer" onClick={() => company.id !==102 && history.push(`/clients/${company.id}`)}>{company.id}{company.id === 102 ? ' [B]' : ''}</div>
                                    <div className="ellipsis pr-3">{company.owner.register_code || '-'}</div>
                                    <div className="ellipsis pr-3">{dropdownOptions[HOSPITAL_TYPE]?.find(option => option.value === company?.hospital_type_id)?.label}</div>
                                    <div className="ellipsis pr-3">{dropdownOptions[ONCOLOGY_CHAIRS]?.find(option => option.value === company?.oncology_chairs_id)?.label}</div>
                                    <div className="ellipsis">{dropdownOptions[ONCOLOGY_BEDS]?.find(option => option.value === company?.oncology_beds_id)?.label}</div>
                                    <div className="ellipsis">{dropdownOptions[HD_PREPARATIONS]?.find(option => option.value === company?.hd_preparations_id)?.label}</div>
                                    <div className="ellipsis">{dropdownOptions[COMPOUNDING_DEVICE]?.find(option => option.value === company?.compounding_device_id)?.label}</div>
                                    <div className={'d-flex justify-content-center box-content'}>
                                        {!!company.owner.confirmed && <span
                                            onClick={isExpired(company.expiry_date) ? () => _openModalExtendAccess(company) : () => {}}
                                            className={`${isExpired(company.expiry_date) ? 'status-grey' : 'status-green'}`}>
                                            {isExpired(company.expiry_date) ? translate('misc.expired') : translate('misc.active')}
                                        </span>}
                                        {!company.owner.confirmed && <span onClick={() => _openResentInviteModal(company)}
                                                                           className={`${company.owner.email_sent ? 'status-orange' : 'status-red'}`}>
                                            {company.owner.email_sent ? translate('misc.sent') : translate('misc.not_sent')}
                                        </span>}
                                    </div>
                                    <div className={'d-flex justify-content-center'}>
                                        <span className={`${company.suspended ? 'status-red' : (company.owner.confirmed ? 'status-green' : 'status-orange')}`}/>
                                    </div>
                                    <div className="justify-content-center">
                                        <div className={'table-row-item dots force-hover'}>
                                            <div className={'force-row-icons dots-icon'}>
                                                <div className="force-row-icon" onClick={() => setForceHover(key)}>
                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div ref={wrapperRef}>
                                        {globalState.user.can(PERMISSION_ADD_CLIENT) ? <TableHover
                                            idSuffix={key + 'clients'}
                                            onEdit={_openEditModal}
                                            onImpersonate={impersonate}
                                            onDelete={deleteItem}
                                            onLock={suspendItem}
                                            onView={() => history.push(`/clients/${company.id}`)}
                                            onLogs={() => {
                                            }}
                                            item={company}
                                            hover={{
                                                view: company.id !== 102,
                                                edit: globalState.user.can(PERMISSION_ADD_CLIENT) || globalState.user.id === globalState.user?.company?.owner_id,
                                                delete:company.id !== 102,
                                                impersonate: company.id !== 102 && globalState.user.can(PERMISSION_IMPERSONATE) && !!company.owner.confirmed,
                                                lock:  company.id !== 102
                                            }}
                                        /> : <TableHover hover={{}} />}
                                    </div>
                                </div>
                            );
                        })}
                    </Table>
                </div>
            </div>
            </div>

            {globalState.user.can(PERMISSION_ADD_CLIENT) && globalState.responsive.width <=1024 && <div className="mt-4 px-4 w-450">
                <FormButton
                size={'xl'}
                fluid={true}
                variant={'secondary'}
                text={"+ " + translate('clients.add')}
                onClick={_toggleModal}/>
            </div>}

            <Modal modal={state.modal} _toggleModal={_toggleModal} className={'bd-my-modal modal-right'}
                   title={translate('clients.add')}
                   confirmText={translate('misc.save')}
                   onAction={_save}
                   buttonSize={'xl'}
                   buttonFluid={true}
                   isLoading={isLoading}
                   hideCancelButton={true}>
                <Row noGutters={true} className={'iteam-modal-row'}>
                    <Col xs={12}>
                        <h6 className={'mb-3'}>{translate('clients.company_details')}</h6>

                        <CustomInput
                            label={translate('misc.account_no')}
                            type="text"
                            className="mb-4"
                            disabled={true}
                            value={maxId}
                        />

                        {offices.length > 0 && offices.map((item, key) =>
                            <div key={key} className="multiple-office">
                                <span className={'admin'}/>
                                <div className="w-100">
                                    <FormGroup>
                                        <CustomSelect
                                            className="mb-3"
                                            name="country_id"
                                            label={translate('location.country')}
                                            required={true}
                                            onChange={(n, v) => _handleChangeOffice(key, n, v)}
                                            value={item.country_id}
                                            options={countries}
                                            error={errors.hasOwnProperty(`offices.${key}.country_id`)}
                                            loadingNoOptions={isLoading}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <CustomInput
                                            name="name"
                                            label={translate('location.office_name')}
                                            required={true}
                                            onChange={(n, v) => _handleChangeOffice(key, n, v)}
                                            value={item.name}
                                            error={errors.hasOwnProperty(`offices.${key}.name`)}
                                        />

                                        {key > 0 && <div className="delete-range">
                                            <span onClick={_deleteOfficeRange(key)}>Delete</span>
                                        </div>}
                                    </FormGroup>
                                </div>
                            </div>)}
                        <Col xs={12} className="p-0">
                            {offices.length < 5 && <FormButton
                                size={'small'}
                                className={'mb-4'}
                                variant={'secondary'}
                                text={"Add"}
                                onClick={_addOfficeRange}/>}
                        </Col>

                        <CustomSelect
                            className="mb-3"
                            name="hospital_type_id"
                            required={true}
                            label={translate('auth.register.hospital_type')}
                            value={item.hospital_type_id}
                            options={dropdownOptions[HOSPITAL_TYPE]}
                            onChange={_handleChange}
                            error={errors['company.hospital_type_id']}
                        />
                        <CustomSelect
                            type="text"
                            className="mb-3"
                            required={true}
                            label={translate('auth.register.oncology_chairs')}
                            value={item.oncology_chairs_id}
                            options={dropdownOptions[ONCOLOGY_CHAIRS]}
                            name="oncology_chairs_id"
                            onChange={_handleChange}
                            error={errors['company.oncology_chairs_id']}
                        />

                        <CustomSelect
                            type="text"
                            className="mb-3"
                            required={true}
                            label={translate('auth.register.oncology_beds')}
                            value={item.oncology_beds_id}
                            options={dropdownOptions[ONCOLOGY_BEDS]}
                            name="oncology_beds_id"
                            onChange={_handleChange}
                            error={errors['company.oncology_beds_id']}
                        />

                        <CustomSelect
                            type="text"
                            className="mb-3"
                            required={true}
                            label={translate('auth.register.hd_preparations')}
                            value={item.hd_preparations_id}
                            options={dropdownOptions[HD_PREPARATIONS]}
                            name="hd_preparations_id"
                            onChange={_handleChange}
                            error={errors['company.hd_preparations_id']}
                        />

                        <CustomSelectCreate
                            className="mb-3"
                            name="compounding_device_id"
                            required={true}
                            label={translate('auth.register.compounding_device')}
                            value={item.compounding_device_id}
                            options={dropdownOptions[COMPOUNDING_DEVICE]}
                            onChange={_handleChange}
                            error={errors['company.compounding_device_id']}
                        />
                        <CustomInput
                            type="text"
                            className="mb-3"
                            label={translate('general.email')}
                            name="email"
                            required={true}
                            value={owner.email}
                            onChange={_handleChangeOwner}
                            error={errors['owner.email']}
                        />
                    </Col>
                </Row>
            </Modal>

            <Modal modal={state.modalEdit} _toggleModal={_toggleModalEdit} className={'bd-my-modal modal-right'}
                   title={translate('clients.update_client')}
                   confirmText={translate('misc.update')}
                   onAction={_save}
                   buttonSize={'xl'}
                   buttonFluid={true}
                   isLoading={isLoading}
                   hideCancelButton={true}>
                <Row noGutters={true} className={'iteam-modal-row'}>
                    <Col xs={12}>
                        <h6 className={'mb-3'}>{translate('clients.company_details')}</h6>

                        <CustomInput
                            label={translate('misc.account_no')}
                            type="text"
                            className="mb-4"
                            value={item.id}
                            disabled={true}
                        />

                        <CustomSelect
                            className="mb-3"
                            name="hospital_type_id"
                            required={true}
                            label={translate('auth.register.hospital_type')}
                            value={item.hospital_type_id}
                            options={dropdownOptions[HOSPITAL_TYPE]}
                            onChange={_handleChange}
                            error={errors['company.hospital_type_id']}
                        />
                        <CustomSelect
                            type="text"
                            className="mb-3"
                            required={true}
                            label={translate('auth.register.oncology_chairs')}
                            value={item.oncology_chairs_id}
                            options={dropdownOptions[ONCOLOGY_CHAIRS]}
                            name="oncology_chairs_id"
                            onChange={_handleChange}
                            error={errors['company.oncology_chairs_id']}
                        />

                        <CustomSelect
                            type="text"
                            className="mb-3"
                            required={true}
                            label={translate('auth.register.oncology_beds')}
                            value={item.oncology_beds_id}
                            options={dropdownOptions[ONCOLOGY_BEDS]}
                            name="oncology_beds_id"
                            onChange={_handleChange}
                            error={errors['company.oncology_beds_id']}
                        />

                        <CustomSelect
                            type="text"
                            className="mb-3"
                            required={true}
                            label={translate('auth.register.hd_preparations')}
                            value={item.hd_preparations_id}
                            options={dropdownOptions[HD_PREPARATIONS]}
                            name="hd_preparations_id"
                            onChange={_handleChange}
                            error={errors['company.hd_preparations_id']}
                        />

                        <CustomSelectCreate
                            className="mb-30"
                            name="compounding_device_id"
                            required={true}
                            label={translate('auth.register.compounding_device')}
                            value={item.compounding_device_id}
                            options={dropdownOptions[COMPOUNDING_DEVICE]}
                            onChange={_handleChange}
                            error={errors['company.compounding_device_id']}
                        />

                    </Col>
                </Row>
            </Modal>

            {state.modalExtendAccess &&   <Modal modal={true} _toggleModal={_toggleModalExtendAccess}
                   confirmText={translate('misc.yes')}
                   reverseButtons={true}
                   onAction={_extendAccess}
                   className="pk-confirm-modal modal-dialog-centered"
                   hideActionButton={false}
                   hideCancelButton={false}
                   isLoading={isLoading}>
                <div className={'pk-confirm-icon'}><span>!</span></div>
                <p className="pk-confirm-text px-4" ><span className="red">Are you sure you want to extend the access for this client ({state.item.name}) for another 12 months?</span></p>
            </Modal>}




            {(state.modalResendInvite && !isLoading) && <BootstrapModal isOpen={true} toggle={_toggleResentInviteModal} className="general-modal modal-mw-750">
                <BootstrapModalHeader className="pt-3 pb-0 border-0" toggle={_toggleResentInviteModal}/>

                <BootstrapModalBody className="">
                    <div className="container-fluid">
                        <div className={'current-page-details text-center pb-3'}>
                            <h4>{translate('templates.message_template')}</h4>
                        </div>

                        <Row noGutters={true}>
                            <Col xs={12}>
                                <CustomInput
                                    type="text"
                                    disabled={true}
                                    className="mb-3 bg-white"
                                    label={translate('templates.subject')}
                                    value={template.subject}
                                />

                                <div className={'summernote'}>
                                    <label htmlFor="sms_content">{translate('templates.email')}</label>
                                    <ReactSummernote
                                        onInit={onInit('email_content', template.email_content)}
                                        className="mb-3 pt-2"
                                        disabled={true}
                                        options={{
                                            height: 300,
                                            dialogsInBody: true,
                                            toolbar: [
                                                ['tags', ['tags']]
                                            ],
                                            popover: false
                                        }}
                                    />
                                </div>

                                <div className="d-flex justify-content-center" onClick={_resendInvite}>
                                    <ButtonBootstrap className={'custom-button background-light-green my-2'}>
                                        {state.item.email_sent ? translate('misc.resend_invite') : translate('misc.send_invite')}
                                    </ButtonBootstrap>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </BootstrapModalBody>
            </BootstrapModal>}
        </Layout>
    );
};

export default ClientsList;