import en from './translations/en';
import de from './translations/de';

export const DEFAULT_LANG = 'en';
export const ALERT_TIME = 30000;
export const SEARCH_TIME = 500;
export const SESSION_TIMEOUT = 60 * 60000;
export const WEBSITE_LANGUAGES = {
    en,
    de
};

export const LANGUAGES = [
    { value: 'en', label: 'English'},
    { value: 'de', label: 'German'},
    // { value: 'no', label: 'Norwegian'},
    // { value: 'nl', label: 'Dutch'}
];

export const DEFAULT_PHONE_PREFIX = 'GB';
export const DEFAULT_LANGUAGE = 'en';

/** User roles **/
export const ROLE_SUPER_ADMIN = 'super-admin';
export const ROLE_ADMIN = 'admin';
export const ROLE_SALES = 'sales';
export const ROLE_CLIENT = 'client';
export const ROLE_CLIENT_USER = 'client-user';
export const ROLE_CLIENT_ID = 4;

export const PERMISSION_ADD_ADMIN = 'save.admin';
export const PERMISSION_ADD_SALES = 'save.sales';
export const PERMISSION_ADD_CLIENT = 'save.clients';
export const PERMISSION_SAVE_RESULTS = 'save.results';
export const PERMISSION_IMPERSONATE = 'impersonate';
export const PERMISSION_VIEW_LOGS = 'view.logs';
export const PERMISSION_SAVE_MEDIA = 'save.media';

export const COMPOUNDING_DEVICE = 'compounding_device';
export const DEPT_AREA = 'dept_area';
export const SURFACE = 'surface';
export const SURFACE_STATUS = 'surface_status';
export const DRUG_TESTED = 'drug_tested';
export const HOSPITAL_TYPE = 'hospital_type';
export const ONCOLOGY_BEDS = 'oncology_beds';
export const ONCOLOGY_CHAIRS = 'oncology_chairs';
export const HD_PREPARATIONS = 'hd_preparations';


export const ALERT_OPTIONS = {
    position: "top center",
    timeout: 5000,
}

export const HOURS = [
    { value: '00:00:00', label: '0:00 AM'},
    { value: '01:00:00', label: '1:00 AM'},
    { value: '02:00:00', label: '2:00 AM'},
    { value: '03:00:00', label: '3:00 AM'},
    { value: '04:00:00', label: '4:00 AM'},
    { value: '05:00:00', label: '5:00 AM'},
    { value: '06:00:00', label: '6:00 AM'},
    { value: '07:00:00', label: '7:00 AM'},
    { value: '08:00:00', label: '8:00 AM'},
    { value: '09:00:00', label: '9:00 AM'},
    { value: '10:00:00', label: '10:00 AM'},
    { value: '11:00:00', label: '11:00 AM'},
    { value: '12:00:00', label: '12:00 PM'},
    { value: '13:00:00', label: '13:00 PM'},
    { value: '14:00:00', label: '14:00 PM'},
    { value: '15:00:00', label: '15:00 PM'},
    { value: '16:00:00', label: '16:00 PM'},
    { value: '17:00:00', label: '17:00 PM'},
    { value: '18:00:00', label: '18:00 PM'},
    { value: '19:00:00', label: '19:00 PM'},
    { value: '20:00:00', label: '20:00 PM'},
    { value: '21:00:00', label: '21:00 PM'},
    { value: '22:00:00', label: '22:00 PM'},
    { value: '23:00:00', label: '23:00 PM'}
];
