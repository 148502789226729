import React, {useEffect, useState} from 'react';
import {
    Col,
    Modal as BootstrapModal,
    ModalBody as BootstrapModalBody,
    ModalHeader as BootstrapModalHeader,
    Row
} from "reactstrap";
import CustomInput from "../../Reusable/CustomInput";
import {translate} from "../../../libs/trans";
import Http from "../../../libs/Http";
import {useAlert} from "react-alert";
import ReactSummernote from "react-summernote";
import {plainText} from "../../../libs/helpers";
import MediaLibrary, {IMAGE_PATH} from "../MediaLibrary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {FormButton} from "../../Reusable/FormComponents";
import CustomSelect from "../../Reusable/CustomSelect";


const AddOrEditFaqModal = (props) => {

    const initialState = {
        faq: {},
    };

    const [state, setState] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const alert = useAlert();

    useEffect(() => {
        if (props.item.hasOwnProperty('id')) {
            setState(prevState => ({...prevState, faq: props.item}));
        }
    }, [props]);

    const _handleChange = (name, value) => {
        setState(prevState => ({...prevState, faq: {...prevState.faq, [name]: value}}));
    };


    const onInit = (name, value) => (note) => {
        note.reset()

        const regex = /(<\w*)((\s\/>)|(.*<\/\w*>))/i

        if (value.match(regex) !== null) {
            note.replace(value)
        } else {
            note.insertText(value)
        }

        setState(prevState => ({...prevState, faq: {...prevState.faq, [name]: value}}));
    };


    /**
     * Handle Save
     * @returns {Promise<boolean>}
     * @private
     */
    const _handleSave = async () => {

        if (isLoading) return false;

        setIsLoading(true);

        const res = await Http.post("/faq-section", {...faq});

        if (res.hasError) {
            setErrors(res.error);
        } else {
            setErrors({});
            if (state.faq.id) {
                alert.show(translate('notifications.update_faq'), {type: 'success'});
            } else {
                alert.show(translate('notifications.created_faq'), {type: 'success'});
            }
            props.toggleModal();
            props.refreshTableResults()
        }

        setIsLoading(false);
    };
    const _toggleModal = () => {
        setState(prevState => ({...prevState, modal: !state.modal}));
    };

    const onPickImages = (image) => {
        let faq = state.faq;
        faq['media_id'] = image[0].id;
        faq['media'] = image[0];
        setState(prevState => ({...prevState, faq: {...prevState.faq, faq}}));
        _toggleModal();
    };

    const _deleteImage = () => {
        let faq = state.faq;
        faq['media_id'] = null;
        faq['media'] = null;
        setState(prevState => ({...prevState, faq: {...prevState.faq, faq}}));
    };

    const {toggleModal} = props;
    const {faq} = state;

    return (
        <div>

            <BootstrapModal isOpen={true} toggle={toggleModal}
                            className="general-modal modal-upload">
                <BootstrapModalHeader toggle={toggleModal} className={'mr-3'}>
                    <Col xs={12}>
                        {translate('faq.add')}
                    </Col>
                </BootstrapModalHeader>
                <BootstrapModalBody>
                    <div className="container-fluid">
                        <Row>
                            <Col sm={12}>
                                <CustomInput
                                    type="text"
                                    label={translate('faq.title_1')}
                                    name="title"
                                    required={true}
                                    className="mb-3"
                                    value={faq?.title}
                                    onChange={_handleChange}
                                    error={errors.title}
                                />
                            </Col>
                            <Col sm={12} className={'d-flex align-items-center mb-3'}>
                                <div
                                    className={`summernote w-100  ${errors.body ? 'invalid-feedback mt-1' : ''}`}>
                                    <label htmlFor="short_description">{translate("misc.body")} <span
                                        className={'field-required'}>*</span></label>

                                    <ReactSummernote
                                        onInit={onInit('body', state.faq?.body || '')}
                                        required={true}
                                        onPaste={plainText}
                                        options={{
                                            height: 200,
                                            dialogsInBody: true,
                                            toolbar: [
                                                ['font', ['bold', 'italic', 'underline']],
                                            ],
                                            popover: false
                                        }}
                                        onChange={value => _handleChange('body', value)}
                                    />
                                </div>
                            </Col>

                            <Col sm={12} className={'d-flex align-items-center position-relative mb-3'}>
                                {faq?.media ? <div className={"w-100"}>
                                    {faq?.media?.type === 'faq_video' ? <div className={'mt-3'}>
                                        <video width="100%" controls controlsList="nodownload">
                                            <source src={faq?.media?.full_path} type="video/mp4"/>
                                        </video>
                                    </div> : <>
                                        <div onClick={_toggleModal}
                                             className={'choose-image-media-library'}
                                             style={faq?.media && {backgroundImage: "url('" + IMAGE_PATH + faq?.media.file_name + "')"}}>
                                            {!faq?.media && translate('misc.choose_image_video')}
                                        </div>
                                    </>}
                                    {faq?.media?.title &&
                                    <span className={'device-delete-image mr-3'} onClick={_deleteImage}><FontAwesomeIcon
                                        icon={faTrashAlt}/></span>}
                                </div> : <div className={'w-100'}>
                                    <div onClick={_toggleModal}
                                         className={'choose-image-media-library'}
                                         style={faq?.media && {backgroundImage: "url('" + IMAGE_PATH + faq?.media.file_name + "')"}}>
                                        {translate('misc.choose_image_video')}
                                    </div>
                                </div>}
                            </Col>

                            <Col sm={6}>
                                <CustomSelect
                                    type="text"
                                    label={translate('faq.direction')}
                                    name="direction"
                                    required={true}
                                    className="mb-4 mt-3"
                                    value={faq?.direction}
                                    onChange={_handleChange}
                                    options={[
                                        {label: "Left", value: 'left'},
                                        {label: "Above", value: 'above'},
                                    ]}
                                    error={errors.direction}
                                />
                            </Col>

                            <Col sm={12} className="text-center mt-3">
                                <FormButton
                                    size={'large'}
                                    className={'mb-4'}
                                    variant={'primary'}
                                    text={translate(faq.hasOwnProperty('id') ? 'misc.update' : "misc.save")}
                                    onClick={_handleSave}/>

                            </Col>
                        </Row>
                    </div>
                </BootstrapModalBody>
            </BootstrapModal>
            {state.modal && <BootstrapModal isOpen={true}
                                            toggle={_toggleModal}
                                            className="general-modal modal-mw-750 modal-choose-image-media-library">
                <BootstrapModalHeader className="pt-3 pb-0 border-0" toggle={_toggleModal}/>
                <BootstrapModalBody className={'pb-5'}>
                    <div className="container-fluid">
                        <MediaLibrary noLayout={true}
                                      defaultType={'faq_image'}
                                      allowPickImage={true}
                                      onPickImages={onPickImages}
                                      picked={faq?.media ? [faq?.media] : []}
                                      pickSingleImage={true}
                                      hideControls={true}
                                      hideUploadBtn={true}/>
                    </div>
                </BootstrapModalBody>
            </BootstrapModal>}
        </div>
    );
};

export default AddOrEditFaqModal;
