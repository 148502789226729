import React, {Fragment, useContext} from 'react';
import {ROLE_ADMIN, ROLE_CLIENT, ROLE_CLIENT_USER, ROLE_SALES} from "../../constants";

import context from "../../context/context";
import SaleDashboard from "./Sale";
import AdminDashboard from "./Admin";
import ClientDashboard from "./Client";

const Home = () => {

    const { state: globalState } = useContext(context);

    const _dashboardComponent = () => {

        if(globalState.user.hasRole(ROLE_ADMIN)) {
            return  <AdminDashboard/>;
        }
        if(globalState.user.hasRole(ROLE_SALES)) {
            return  <SaleDashboard/>;
        }
        if(globalState.user.hasRole(ROLE_CLIENT) || globalState.user.hasRole(ROLE_CLIENT_USER)) {
            return  <ClientDashboard/>;
        }
    };


    return (<Fragment>{_dashboardComponent()}</Fragment>)


};

export default Home;