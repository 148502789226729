import React, {Fragment, useState} from 'react';
import {translate} from "../../libs/trans";
import CustomSelect from "./CustomSelect";
import CustomInput from "./CustomInput";

const Pagination = (props) => {

    const initialState = {
        currentPage: props.currentPage || 1,
        goToPage: ''
    };

    const [state, setState] = useState(initialState);

    const _pagination = (current, total) => {
        let pagination = [];
        let next = true;
        let goToPage = true;

        if (total > 1) {
            let dots = false;
            for (let i = 1; i <= total; i++) {
                let item = {
                    item: i,
                    disabled: false,
                    dots: false
                };
                if (current === i) {
                    item.disabled = true;
                }
                if (dots && ((i > 1 && i < current - 1 && i < total) || (i > 1 && i > current + 1 && i < total))) {
                    continue;
                }

                if ((i > 1 && i < current - 1 && i < total) || (i > 1 && i > current + 1 && i < total)) {
                    item.dots = true;
                    dots = true;
                } else {
                    dots = false;
                }

                pagination.push(item);
            }
        } else {
            pagination.push({
                item: 1,
                disabled: true,
                dots: false
            });
            next = false;
            goToPage = false;
        }

        if (current === total) {
            next = false;
        }

        if (total < 4) {
            goToPage = false;
        }

        return {
            pages: pagination,
            next,
            goToPage
        };
    };

    const _onChange = (page, dots = false) => {
        if (dots) {
            return;
        }
        const {onChange} = props;
        setState(prevState => ({...prevState, currentPage: page}))

        onChange && onChange(page);
    };


    const _onNext = () => {
        const {pages} = props;
        const {currentPage} = state;

        let nextPage = currentPage + 1;

        if (nextPage > pages) {
            return;
        }

        _onChange(nextPage);
    };

    const _validateGoToPage = (name, value) => {
        value = value.replace(/[^0-9]/g, '');

        if ((parseInt(value) >= 1 && parseInt(value) <= props.pages) || value === '') {
            setState(prevState => ({...prevState, goToPage: value}))
        }
    };

    const _handlePageChange = (event) => {
        const {goToPage} = state;

        if (event.key === 'Enter' && goToPage !== '') {
            _onChange(+goToPage);
        }
    };

    const {currentPage, goToPage} = state;
    const {pages, limit, isLoading} = props;

    let pagination = _pagination(currentPage, pages);

    return (
        <div className="pagination-container">
        <div className={'pagination'}>
            <div className={'pagination-pages'}>
                {pagination.pages.map((page, key) => {

                    let classes = [
                        "pagination-item",
                        ...(page.disabled ? ["disabled"] : []),
                        ...(page.dots ? ["dots"] : []),
                    ].join(" ");

                    return (<div key={key} className={classes}
                                 onClick={!isLoading ? () => _onChange(page.item, page.dots) : () => {
                                 }}>
                        {page.dots ? '...' : page.item}
                    </div>);
                })}
                {pagination.next &&
                <div className={'pagination-item next'} onClick={!isLoading ? _onNext : () => {
                }}>{translate('pagination.next')}
                </div>}

                {(limit && props.hasOwnProperty('handleLimitChange')) && <div className="select-small-container">
                    <span>Items per page: </span>
                    <CustomSelect
                        onChange={(_, per_page) => props.handleLimitChange(per_page)}
                        className="select-small"
                        label={false}
                        value={limit}
                        options={[
                            {label: 10, value: 10},
                            {label: 20, value: 20},
                            {label: 50, value: 50},
                            {label: 'All', value: 9999},
                        ]}
                    />
                </div>}

            </div>
        </div>
            {!props.hideGoToPage && <div className={'pagination-jump'}>
                {pagination.goToPage && <Fragment>
                    <CustomInput onChange={_validateGoToPage} placeholder={translate('pagination.go')} value={goToPage}
                                 onKeyPress={_handlePageChange}/>
                </Fragment>}

            </div>}
        </div>
    );
};

export default Pagination;