import {useContext, useEffect} from 'react';
import context from "../../context/context";
import Http from "../../libs/Http";
import {useHistory} from "react-router";

const Logout = () => {

    const { dispatch } = useContext(context);
    const {state: globalState} = useContext(context);
    const history = useHistory();

    async function doLogout() {

        const rememberToken = localStorage.getItem('rememberToken');
        await Http.post('/logout', {rememberToken});
        window.echo && window.echo.disconnect();
        sessionStorage.clear();
        localStorage.removeItem('rememberToken');
        if(document.querySelector('body').classList.contains('backdrop-menu')){
            document.querySelector('body').classList.remove('backdrop-menu')
        }
        dispatch({type: "SET_USER", payload: null});
        history.push({
            pathname: '/login',
            hideModal: true
        });

    }

    useEffect(() => {
        doLogout()
    }, []);

    return false;
};

export default Logout;