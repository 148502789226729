import React from 'react';
import {
    Modal as BootstrapModal,
    ModalHeader as BootstrapModalHeader,
    ModalBody as BootstrapModalBody,
    ModalFooter as BootstrapModalFooter
} from 'reactstrap';


import {translate} from "../../libs/trans";

import {FormButton} from "./FormComponents";

const Modal = (props) => {

    const {modal, _toggleModal, title, children, onAction, confirmText, isLoading, hideCancelButton,
        hideActionButton, addAnotherButton, reverseButtons, className, hideTitle, buttonSize = 'large', buttonFluid = false, buttonVariant = 'primary', wrapClassName} = props;

    return (
        <BootstrapModal isOpen={modal}
                        backdrop={props.backdrop}
                        toggle={_toggleModal}
                        className={className}
                        wrapClassName={wrapClassName ? 'no-backdrop' : ''}>
            {!hideTitle && <BootstrapModalHeader toggle={_toggleModal}>{title}</BootstrapModalHeader>}
            <BootstrapModalBody>
                {children}
            </BootstrapModalBody>
            <BootstrapModalFooter className={`${reverseButtons ? 'reverse-buttons' : ''}`}>
                {hideCancelButton ? '' :
                    <FormButton
                        size={'large'}
                        variant={'secondary'}
                        text={translate('misc.cancel')}
                        onClick={_toggleModal}/>}
                {hideActionButton ? '' :
                    <FormButton
                        size={buttonSize}
                        fluid={buttonFluid}
                        className={'mx-0'}
                        variant={buttonVariant}
                        text={confirmText}
                        isLoading={isLoading}
                        onClick={onAction}/>}

                {addAnotherButton && addAnotherButton}

            </BootstrapModalFooter>
        </BootstrapModal>
    );

};

export default Modal;
