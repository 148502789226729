import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Radar} from "react-chartjs-2";
import context from "../../context/context";
import {useAlert} from "react-alert";
import Http from "../../libs/Http";
import CustomSelect from "../Reusable/CustomSelect";

const plugin = {
    id: 'custom_canvas_background_color',
    afterDraw: (chart) => {
        const scale = chart.scale;
        const ctx = chart.canvas.getContext('2d');
        ctx.beginPath();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'rgba(255,255,255,1)';

        let pointLabelPosition = scale.getPointPosition(0, scale.drawingArea)
        ctx.moveTo(pointLabelPosition.x, pointLabelPosition.y);
        for (let i = 0; i < scale.pointLabels.length; i++) {
            pointLabelPosition = scale.getPointPosition(i, scale.drawingArea)
            ctx.lineTo(pointLabelPosition.x, pointLabelPosition.y);
        }
        ctx.closePath();
        ctx.fill();
        ctx.save();
        ctx.restore();
    }
};

const SimpleCompareRadar = ({params}) => {

    const {state: globalState} = useContext(context);
    const [results, setResults] = useState({
        company: {},
        labels: [],
    });
    const [result, setResult] = useState('all');
    const [isLoading, setIsLoading] = useState(true);
    const alert = useAlert();

    useEffect(() => {
        getCompareResults();
    }, [params, result]);

    const getCompareResults = useCallback(async () => {

        setIsLoading(true);

        params.result = result

        const res = await Http.get('/dashboard/compare-results', params);

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            setResults(res.data);
        }

        setIsLoading(false);
    }, [params, result]);

    return (
        <div>
            <div className="dashboard-compare">
                <div className="left-side">
                    <div className="title">Overall</div>
                    <span>This chart shows the results you select below;</span>
                    <div className="select-container">
                        <CustomSelect
                            className="mb-3 mt-4"
                            label="Result"
                            name="result"
                            onChange={(name, value) => setResult(value)}
                            value={result}
                            options={[
                                {label: "All", value: 'all'},
                                {label: "Positive Results", value: 1},
                                {label: "Negative Results", value: 0}
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className="chart-container position-relative mx-auto" style={{maxWidth: '400px',padding:'20px'}}>
                {isLoading ? <div className="chart-spinner-container">
                        <div className="spinner"/>
                    </div> :
                    <Radar width={1} height={1} plugins={[plugin]} data={{
                        labels: Object.values(results.labels),
                        datasets: [
                            {
                                label:'1',
                                data: Object.values(results.data).map(i => i.percent),
                                backgroundColor: 'rgba(255, 157, 0, 0.3)',
                                borderColor: 'rgb(255,157,0)',
                                borderWidth: 3,
                                pointRadius: 0
                            },
                        ],
                    }} options={{
                        scale: {
                            pointLabels: {
                                fontColor: '#000',
                                fontSize: 12,
                                zIndex:1,
                                fontFamily: 'Roboto',
                                fontStyle: 'bold',
                            },
                            ticks: {
                                display: false,
                                suggestedMin: 0,
                                suggestedMax: 100,
                                maxTicksLimit: 10,
                                beginAtZero: true,
                            },
                            gridLines: {
                                color: 'rgba(0,0,0,.3)'
                            },
                            angleLines: {
                                color: 'rgba(0,0,0,.3)'
                            }
                        },
                        tooltips: {
                            enabled: false
                        },
                        legend: {
                            display: false,
                        },
                    }}/>
                }
            </div>
        </div>
    );
};

export default SimpleCompareRadar;