import React, {useContext, useEffect, useRef, useState} from 'react';
import Layout from "../Misc/Layout";
import {translate} from "../../libs/trans";
import {Button as ButtonBootstrap, Col, Row} from "reactstrap";
import context from "../../context/context";
import {useAlert} from "react-alert";
import Table from "../Reusable/Table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import TableHover from "../Reusable/TableHover";
import Http from "../../libs/Http";
import Modal from "../Reusable/Modal";
import CustomSelect from "../Reusable/CustomSelect";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import Button from "../Reusable/Button";
import CustomInput from "../Reusable/CustomInput";
import {scrollToTopInModal} from "../../libs/helpers";
import {PERMISSION_ADD_ADMIN} from "../../constants";
import {FormButton} from "../Reusable/FormComponents";

const CountryEmails = () => {


    const [item, setItem] = useState({
        emails: [null]
    });
    const {state: globalState} = useContext(context);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [items, setItems] = useState([]);
    const [errors, setErrors] = useState({});
    const [modal, setModal] = useState(false);
    const [countries, setCountries] = useState([]);
    const [forceHover, setForceHover] = useState(false);

    const alert = useAlert();
    const wrapperRef = useRef();

    const [refreshTable, doRefresh] = useState(0);
    const refreshTableResults = () => doRefresh(i => ++i);


    useEffect(() => {
        if (!countries.length) {
            _getCountries();
        }
    }, [])

    useEffect(() => {
        if (modal === false) {
            setItem({emails: [null]});
            setErrors({});
        }
    }, [modal]);

    const getItems = params => Http.get('/country-emails', {...params});

    const deleteItem = async params => {
        setIsLoading(true);
        const res = await Http.delete('/country-emails/' + params.id);
        setIsLoading(false);
        if (res.hasError) {
            alert.show(res.error.general, {type: 'error'});
        } else {
            alert.show(translate('notifications.delete_country'), {type: 'success'});

        }
        refreshTableResults();
    };

    const _getCountries = async () => {
        const res = await Http.get('/options/country');
        if (!res.hasError) {
            setCountries(res.data);
        }
    };

    const _addEmailModal = () => {
        _toggleModal();
    };

    const _openEditModal = (item) => {
        setItem(item);
        _toggleModal();
    };

    const _toggleModal = () => {
        setModal(!modal)
    };

    const _handleChange = (name, value) => {
        setItem({...item, [name]: value})
    };

    const _handleChangeEmails = (key) => (name, value) => {
        let data = JSON.parse(JSON.stringify(item.emails));
        data[key] = value;
        setItem({...item, 'emails': data});
    };

    const _deleteEmail = (key) => () => {
        let data = JSON.parse(JSON.stringify(item.emails));
        if (data.length === 1) return;
        data = data.filter((i, ind) => ind !== key);
        setItem({...item, 'emails': data});
    };

    const _addNewEmail = () => {
        let data = JSON.parse(JSON.stringify(item.emails));
        data.push(null);
        setItem({...item, 'emails': data});
    };

    const _saveCountryEmails = async () => {

        if (isLoadingModal) return;

        let tmpItem = JSON.parse(JSON.stringify(item));

        setIsLoadingModal(true);

        const res = await Http.post('/country-emails/save', {...tmpItem});

        setIsLoadingModal(false);

        if (res.hasError) {
            setErrors(res.error);
            scrollToTopInModal();
        } else {
            setErrors({});
            if (item.id) {
                alert.show(translate('notifications.edit_country'), {type: 'success'});
            } else {
                alert.show(translate('notifications.add_country'), {type: 'success'});
            }

            refreshTableResults();
            _toggleModal()
        }
    };


    const columns = [
        {
            colSize: 5,
            text: 'ID',
            name: 'id',
            sortable: true,
            colSizeTablet: 10,
            colSizeMobile: 15,
        },
        {
            colSize: 15,
            text: translate('country_emails.country'),
            name: 'country_id',
            sortable: true,
            colSizeTablet: 35,
            colSizeMobile: 40,
        },
        {
            colSize: 60,
            text: translate('country_emails.emails'),
            colSizeTablet: 16
        },
        {
            colSize: 1,
            text: '',
            colSizeTablet: 5,
            colSizeMobile: 5,
        }
    ];

    return (
        <>
            <div className="table-container">
                <div className={'table-content add-users'}>
                    <Table columns={columns}
                           getItems={getItems}
                           setItems={setItems}
                           refreshTable={refreshTable}
                           buttonsContainer={<FormButton
                                    size={'large'}
                                    variant={'secondary'}
                                    text={"+ " + translate('country_emails.add')}
                                    onClick={_addEmailModal}/>}>

                        {!!items.length && items.map((item, key) => {
                            return (
                                <div
                                    className={`table-row-item row align-items-center m-0 ${forceHover === key ? 'force-hover' : ''}`}
                                    key={key}>
                                    <div>{item.id}</div>
                                    <div className="ellipsis" onClick={() => _openEditModal(item)}>{item?.country?.name}</div>
                                    <div className="ellipsis">{item?.emails.join(', ')}</div>
                                    <div className="justify-content-center">
                                        {globalState.responsive.width <= 1024 &&
                                        <div className={'table-row-item dots force-hover'}>
                                            <div className={'table-row-icons dots-icon'}>
                                                <div className="table-row-icon" onClick={() => setForceHover(key)}>
                                                    <FontAwesomeIcon icon={faEllipsisH}/>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>

                                    <div ref={wrapperRef}>
                                        <TableHover
                                            idSuffix={key + 'results'}
                                            onEdit={(item) => _openEditModal(item)}
                                            onDelete={deleteItem}
                                            item={item}
                                            hover={{
                                                delete: true,
                                                edit: true,
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </Table>
                </div>
            </div>

            {globalState.responsive.width <=1024 && <div className={'outside-button-container pt-4'}>
                <FormButton
                    size={'xl'}
                    className={'w-450'}
                    fluid={true}
                    variant={'secondary'}
                    text={"+ " + translate('country_emails.add')}
                    onClick={_addEmailModal}/>
            </div>}

            <Modal modal={modal} _toggleModal={_toggleModal} className={'bd-my-modal modal-right'}
                   title={translate(`country_emails.${item.id ? 'edit' : 'add'}`)}
                   confirmText={translate('misc.save')}
                   onAction={_saveCountryEmails}
                   buttonSize={'xl'}
                   buttonFluid={true}
                   isLoading={isLoadingModal}
                   hideCancelButton={true}>
                <Row noGutters={true} className={'iteam-modal-row'}>
                    <Col xs={12}>
                        <CustomSelect
                            className="mb-4"
                            name="country_id"
                            disabled={!!item.id}
                            label={translate('location.country')}
                            required={true}
                            onChange={_handleChange}
                            value={item.country_id}
                            options={countries}
                            error={errors.country_id}
                            loadingNoOptions={isLoading}
                        />

                        {item.emails.map((email, key) => {
                            return (
                                <Row key={key} className={'mx-0 invalid-negative-margin'}>
                                    <Col xs={12}>
                                        <Row className={'align-items-center'}>
                                            <Col xs={10} className={'px-0'}>
                                                <CustomInput
                                                    label={`${translate('misc.email') + ' ' + (key + 1)}`}
                                                    className={'mb-4'}
                                                    type="text"
                                                    value={email}
                                                    required={true}
                                                    name="email"
                                                    onChange={_handleChangeEmails(key)}
                                                    error={errors['emails.' + key]}
                                                />
                                            </Col>
                                            <Col xs={2} className={'pr-0 d-flex justify-content-center'}>
                                                <div className={'circle-trash-container mb-4'}>
                                                    <FontAwesomeIcon title={translate('misc.delete')}
                                                                     className="cursor-pointer"
                                                                     icon={faTrashAlt}
                                                                     onClick={_deleteEmail(key)}/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            );
                        })}
                        <FormButton
                            size={'large'}
                            variant={'secondary'}
                            className={'mx-1'}
                            text={translate('country_emails.add_another')}
                            onClick={_addNewEmail}/>

                        {errors?.general === 'country_in_use' &&
                        <p className={'red'}>This country already has team emails. Please update the country from the
                            list.</p>}
                    </Col>
                </Row>
            </Modal>


        </>
    );
};

export default CountryEmails;