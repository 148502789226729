import React, {useState} from 'react';

import {Tooltip} from 'reactstrap';

const TooltipComponent = (props) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const _toggle = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const {placement, children, target} = props;

    return (
        <Tooltip placement={placement}
                 delay={{ show: 0, hide: 0 }}
                 isOpen={tooltipOpen}
                 target={target}
                 toggle={_toggle}>
            {children}
        </Tooltip>
    );

};
export  default  TooltipComponent
