import React from 'react';
import {Button as ButtonBootstrap} from 'reactstrap';

const Button = ({textColor, height, width, icon, text, iconDirection, backgroundColor, border,
                    className, fontSize, isLoading, ...others}) => {

    let styled = {};

    const classes = [
        "custom-button",
        ...(icon ? ["has-icon"] : []),
        ...(icon && iconDirection === 'right' ? ["icon-right"] : []),
        ...(isLoading ? ["loading"] : []),
        ...(fontSize ? [`font-size-${fontSize}`] : []),
        ...(backgroundColor ? [`background-${backgroundColor}`] : []),
        ...(border ? [`border-${border}`] : []),
        className,
    ].join(" ");

    if (textColor) {
        styled.color = textColor;
    }

    if (height) {
        styled.height = height;
    }

    if (width) {
        styled.width = width;
    }

    return (
        <ButtonBootstrap style={styled} className={classes} {...others}>
            {isLoading ? <div className="spinner"/> : text && text}
        </ButtonBootstrap>
    );
};

export default Button;
