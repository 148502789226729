import {DEFAULT_LANG, WEBSITE_LANGUAGES} from "../constants";

const _resolve = (path, obj, default_value) => {
    let lastPath = path.split('.').slice(-1)[0];

    return path.split('.').reduce(function (prev, curr) {
        if (lastPath === curr && typeof prev[curr] === 'object') {
            return path;
        }

        return prev ? typeof prev[curr] !== 'undefined' ? prev[curr] : (default_value || path) : (default_value || path) ;
    }, obj || {});
};

export const translate = (key, default_value = false) => {
    const lang = localStorage.getItem('lang') || DEFAULT_LANG;
    let translation = WEBSITE_LANGUAGES[lang];
    if (Object.keys(translation).length > 0) {
        return _resolve(key, translation, default_value);
    }
    return key;
};

export const translateValidation = (key, error) => {
    return translate(error).replace(':attribute ', '');
    // return translate(error).replace(':attribute', key);
};

export const getTransValues = (key, append = false) => {
    const lang = localStorage.getItem('lang') || DEFAULT_LANG;
    let translation = WEBSITE_LANGUAGES[lang][key];
    let array = [];
    for(let _key in translation){
        if (translation.hasOwnProperty(_key)) {
            array.push({
                value: _key,
                label: translation[_key]
            });
        }
    }

    append && array.push(append);

    return array;
};

