import React from 'react';
import {useDropzone} from 'react-dropzone';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {translate} from "../../libs/trans";

export default function Dropzone({handleDrop = null, item = null}) {

    const onDrop = acceptedFiles => {
        handleDrop && handleDrop(acceptedFiles);
    };

    const {getRootProps, getInputProps} = useDropzone({onDrop});

    return (
        <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} accept={"image/*"}/>
            <div className="media-dropzone-container">
                {item.image ?
                    <span className="image"><img className="img-fluid"
                                                 src={typeof item.image === 'string' ? item.image : URL.createObjectURL(item.image)}
                                                 alt=""/></span>
                    :
                    <span className="plus">
                        <FontAwesomeIcon icon={faPlus}/>
                        <span>{translate("media.choose")}</span>
                    </span>
                }
            </div>
        </div>
    );
}