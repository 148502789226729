import React, {useEffect, useState} from 'react';
import Layout from "../Misc/Layout";
import {translate} from "../../libs/trans";
import {Col, Row} from "reactstrap";
import CustomPhone from "../Reusable/CustomPhone";
import Button from "../Reusable/Button";
import CustomInput from "../Reusable/CustomInput";
import PhoneJSON from "../../resources/phone";
import Http from "../../libs/Http";
import {pick} from "../../libs/helpers";
import {useAlert} from "react-alert";
import {DEFAULT_PHONE_PREFIX} from "../../constants";
import {FormButton} from "../Reusable/FormComponents";

const MyAccount = () => {

    const [item, setItem] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const alert = useAlert();

    useEffect(() => {
        _getUser()
    }, []);

    const _handleChange = (name, value) => {
        setItem(prevState => ({...prevState,  [name]: value}));
    };

    const _getUser = async () => {
        setIsLoading(true);

        const res = await Http.get('/user');

        if (res.hasError) {
            setErrors(res.error);
        } else {
            let user = res.data;
            if (user.phone) {
                const arrPhone = user.phone.split('-');
                setItem({...user, phone: arrPhone[1]});
            } else {
                setItem({...user, phone_country: DEFAULT_PHONE_PREFIX});
            }
        }

        setIsLoading(false);
    };


    const _updateAccount = async() => {

        if (isLoadingButton) return false;

        setIsLoadingButton(true);

        const attr = ['first_name',
            'phone',
            'phone_country'
        ];

        let data = pick(...attr)(item);

        if (data.phone) {
            data.phone = `${PhoneJSON[data.phone_country]}-${data.phone}`;
        }
         const res = await Http.patch('/user', data);

        if (res.hasError) {
            setErrors(res.error);
        } else {
            alert.show(translate('notifications.update_account'), {type: 'success'});
            setErrors({});
        }

        setIsLoadingButton(false);

    };

    return (
        <Layout>
            <div className="bordered-container">
                <h3 className={'my-3 pl-4'}>{translate('general.my_account')}</h3>

           <div className="my-account">
                <div className="details-box ">
                    <Row className={'mx-2 no-col-right-padding invalid-negative-margin '}>
                        <Col xs={12} md={6} lg={4}>
                            <CustomInput
                                type="text"
                                className="mb-4"
                                label={translate('general.first_name')}
                                name="first_name"
                                value={item.first_name}
                                onChange={_handleChange}
                                error={errors.first_name}
                            />
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <CustomInput
                                type="text"
                                className="mb-4"
                                label={translate('general.email')}
                                name="email"
                                disabled={true}
                                value={item.email}
                                onChange={_handleChange}
                                error={errors.email}
                            />
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <CustomPhone
                                className="mb-3"
                                label={translate('general.mobile_no')}
                                valueInput={item.phone || null}
                                valuePrefix={item.phone_country}
                                onChange={_handleChange}
                                error={errors.phone}
                            />
                        </Col>

                    </Row>

                   <div className={'mx-4 mt-3 mb-4'}>
                       <FormButton
                           size={'large'}
                           variant={'primary'}
                           text={translate('misc.update')}
                           isLoading={isLoadingButton}
                           onClick={_updateAccount}/>
                    </div>

                </div>
            </div>
            </div>

        </Layout>
    );
};

export default MyAccount;