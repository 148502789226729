import React, {useContext, useEffect, useState} from 'react';
import Layout from "../../Misc/Layout";
import {translate} from "../../../libs/trans";
import Http from "../../../libs/Http";
import {useParams} from "react-router";
import {Col, Row} from "reactstrap";
import CustomInput from "../../Reusable/CustomInput";
import Table from "../../Reusable/Table";
import TableHover from "../../Reusable/TableHover";
import {impersonate, pick, scrollToError, scrollToTop} from "../../../libs/helpers";
import {
    COMPOUNDING_DEVICE,
    HD_PREPARATIONS, HOSPITAL_TYPE, ONCOLOGY_BEDS, ONCOLOGY_CHAIRS, PERMISSION_ADD_CLIENT,
    PERMISSION_IMPERSONATE,
    ROLE_ADMIN,
    ROLE_CLIENT,
    ROLE_CLIENT_ID, ROLE_CLIENT_USER
} from "../../../constants";
import context from "../../../context/context";
import {useAlert} from "react-alert";
import ClientUserModal from "../../Reusable/ClientUserModal";
import OfficeModal from "../../Reusable/OfficeModal";
import CustomSelect from "../../Reusable/CustomSelect";
import CustomSelectCreate from "../../Reusable/CustomSelectCreate";
import {FormButton} from "../../Reusable/FormComponents";

const Details = (props) => {

    const initialState = {
        modalEdit: false,
        modalEditLocation: false,
        company_id: null,
        item: {},
        owner_id: null,
        office_id: null,
        location: {},
        modalChangeRecommendationOffice: false,
        new_office_id: null,
        modalChangeRecommendationOwner: false,
        new_owner_id: null,
    };

    const {state: globalState} = useContext(context);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [client, setClient] = useState({});
    const [state, setState] = useState(initialState);
    const [offices, setOffices] = useState([]);
    const [users, setUsers] = useState([]);
    const [refreshTableUsers, doRefreshUsers] = useState(0);
    const [refreshTableLocations, doRefreshLocations] = useState(0);
    const [dropdownOptions, setDropdownOptions] = useState({});
    const refreshTableResultsUsers = () => doRefreshUsers(i => ++i);
    const refreshTableResultsLocations = () => doRefreshLocations(i => ++i);
    const [errors, setErrors] = useState({});
    const alert = useAlert();

    const {id} = useParams();

    useEffect(() => {
        if (id) {
            _getClient();

            setState(prevState => ({
                ...prevState, item: {
                    company_id: id,
                },
                location: {}
            }));
            setErrors({});
        } else {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (props.client) {
            _getCompanyDetails();

            setState(prevState => ({
                ...prevState, item: {
                    company_id: globalState.user.company_id,
                },
                location: {}
            }));
            setErrors({});
        }
    }, []);

    useEffect(() => {
        if (state.modal === false) {
            setState(prevState => ({
                ...prevState, item: {
                    company_id: id,
                },
                location: {}
            }));
            setErrors({});
        }
    }, [state.modal]);


    useEffect(() => {
        _getDropdownOptions();
    }, [])

    const _getDropdownOptions = async (params) => {
        const res = await Http.get('/dropdown-options', {...params, client: true});
        if (!res.hasError) {
            setDropdownOptions(res.data);
        }
    };

    const _getOffices = params => Http.get('/offices', {...params, company_id: id});
    const _getUsers = params => Http.get('/users', {type: 'client-with-users', ...params, company_id: id});

    const _getClient = async () => {
        setIsLoading(true);
        const res = await Http.get(`/client/${id}`);
        if (!res.hasError) {
            let data = res.data;

            if (data.phone && data.phone.includes('-')) {
                const arrPhone = data.phone.split('-');
                data.phone = arrPhone[1];
            }

            setState(prevState => ({
                ...prevState,
                office_id: data?.main?.id,
                owner_id: data?.owner_id
            }));
            setClient(data);
        }
        setIsLoading(false);
    };

    const _getCompanyDetails = async () => {
        setIsLoading(true);
        const res = await Http.get(`/client/account`);
        if (!res.hasError) {
            let data = res.data;
            setState(prevState => ({
                ...prevState,
                office_id: data?.main?.id,
                owner_id: data?.owner_id
            }));
            setClient(data);
        }
        setIsLoading(false);
    };


    const _handleChange = (name, value) => {
        setState(prevState => ({...prevState, item: {...prevState.item, [name]: value}}));
    };

    const pickOffice = (office_id) => {
        let newItem = JSON.parse(JSON.stringify(state.item));
        if (!newItem.hasOwnProperty('locations')) {
            newItem.locations = [];
        }
        if (newItem.locations.find(i => i.id === office_id)) {
            newItem.locations = newItem.locations.filter(i => i.id !== office_id);
        } else {
            newItem.locations.push({id: office_id})
        }
        setState(prevState => ({...prevState, item: newItem}));
    };

    const pickUser = (user_id) => {
        let newItem = JSON.parse(JSON.stringify(state.location));
        if (!newItem.hasOwnProperty('users')) {
            newItem.users = [];
        }
        if (newItem.users.find(i => i.id === user_id)) {
            newItem.users = newItem.users.filter(i => i.id !== user_id);
        } else {
            newItem.users.push({id: user_id})
        }
        setState(prevState => ({...prevState, location: newItem}));
    };

    const _toggleModal = () => {
        setState(prevState => ({...prevState, modal: !prevState.modal}));
    };

    const _openAddUserModal = () => {
        setState(prevState => ({...prevState, modal: !prevState.modal, item: {}}));
    };

    const _openAddLocationModal = () => {
        setState(prevState => ({...prevState, modalLocation: !prevState.modalLocation, location: {}}));
    };

    const _openEditModal = (item) => {
        setState(prevState => ({...prevState, item}));
        _toggleModal();
    };

    const _updateClient = async () => {

        if (isLoadingSave) return false;

        setIsLoadingSave(true);

        let res;
        let params = {};

        const attr = [
            'email',
            'hospital_type_id', 'oncology_chairs_id',
            'oncology_beds_id', 'hd_preparations_id',
            'compounding_device_id'
        ];
        let company = pick(...attr)(client);

        params = {
            company,
            office_id: state.office_id,
            owner_id: state.owner_id
        };

        res = await Http.patch('/clients/' + client.id, params);

        if (res.hasError) {
            setErrors(res.error);
            scrollToError();
        } else {
            if (!Number.isInteger(client.compounding_device_id)) {
                _getDropdownOptions();
            }
            setClient(prevState => ({...prevState, compounding_device_id: res.data.compounding_device_id}));
            setErrors({});
            alert.show(translate('notifications.update_company'), {type: 'success'});
        }

        setIsLoadingSave(false);
    };

    const _save = async () => {

        if (isLoadingModal) return false;

        setIsLoadingModal(true);

        let res;

        const attr = [
            'email',
            'locations'
        ];
        let data = pick(...attr)(state.item);

        if (state.item?.id) {
            res = await Http.patch('/user/client/' + state.item.id, data);
        } else {
            if (globalState.user.hasRole(ROLE_ADMIN)) {
                data.company_id = client.id
            }
            res = await Http.post('/user/client', data);
        }

        setIsLoadingModal(false);

        if (res.hasError) {
            setErrors(res.error);
        } else {
            if (data?.id) {
                alert.show(translate('notifications.update_user'), {type: 'success'});
            } else {
                alert.show(translate('notifications.add_user'), {type: 'success'});
            }
            _toggleModal();
            refreshTableResultsLocations();
            refreshTableResultsUsers();
        }

    };

    const suspendItem = async params => {
        const res = await Http.patch('/user/' + params.id + '/toggle-suspend');
        if (res.hasError) {
            alert.show(res.error.general, {type: 'error'});
        } else {
            if (res.data.suspended) {
                alert.show(translate('notifications.suspend_user'), {type: 'success'});
            } else {
                alert.show(translate('notifications.unsuspend_user'), {type: 'success'});
            }

        }
        refreshTableResultsUsers();
    };

    const _handleChangeLocation = (name, value) => {
        setState(prevState => ({...prevState, location: {...prevState.location, [name]: value}}));
    };

    const _toggleModalLocation = () => {
        setErrors({});
        setState(prevState => ({...prevState, modalLocation: !prevState.modalLocation}));
    };

    const _openEditModalLocation = (location) => {
        setState(prevState => ({...prevState, location}));
        _toggleModalLocation();
    };

    const saveLocation = async () => {

        if (isLoadingModal) return false;

        setIsLoadingModal(true);

        let res;

        let location = state.location;

        if (state.location?.id) {
            res = await Http.patch('/offices/' + location.id, location);
        } else {
            if (globalState.user.hasRole(ROLE_ADMIN)) {
                location.company_id = client.id
            }
            res = await Http.post('/offices', location);
        }

        setIsLoadingModal(false);

        if (res.hasError) {
            setErrors(res.error);
        } else {
            if (location?.id) {
                alert.show(translate('notifications.update_location'), {type: 'success'});
            } else {
                alert.show(translate('notifications.add_location'), {type: 'success'});
            }
            _toggleModalLocation();
            refreshTableResultsLocations();
            refreshTableResultsUsers();

            if(globalState.user.hasRole(ROLE_ADMIN)){
                _getClient();
            } else {
                _getCompanyDetails()
            }
        }

    };

    const _handleChangeClientDetails = (name, value) => {
        setClient(prevState => ({...prevState, [name]: value}));
    };

    const deleteOffice = async params => {
        if(isLoadingDelete) return;
        setIsLoadingDelete(true);
        const res = await Http.delete('/offices/' + params.id);
        setIsLoadingDelete(false);
        if (res.hasError) {
            alert.show(res.error.general, {type: 'error'});
        } else {
            scrollToTop();
            alert.show(translate('notifications.delete_location'), {type: 'success'});
            refreshTableResultsLocations();
        }
    };

    const anonymizeUser = async params => {
        if(isLoadingDelete) return;
        setIsLoadingDelete(true);
        const res = await Http.delete('/clients', {client_user: true, userId: params.id});
        setIsLoadingDelete(false);
        if (res.hasError) {
            alert.show(res.error.general, {type: 'error'});
        } else {
            scrollToTop();
            alert.show(translate('notifications.delete_user'), {type: 'success'});
            refreshTableResultsUsers();
        }
    };

    const columnsOffice = [

        {
            colSize: 30,
            colSizeTablet: 30,
            colSizeMobile: 30,
            text: translate('location.office_name'),
        },
        {
            colSize: 20,
            colSizeTablet: 20,
            colSizeMobile: 20,
            text: translate('location.country'),
        },
        {
            colSize: 50,
            colSizeTablet: 50,
            colSizeMobile: 50,
            text: translate('sidebar.client_users'),
        }
    ];


    const columnsUsers = [
        {
            colSize: 30,
            colSizeTablet: 30,
            colSizeMobile: 30,
            text: translate('general.email'),
        },
        {
            colSize: 60,
            colSizeTablet: 60,
            colSizeMobile: 60,
            text: translate('sidebar.sub_account'),
        },
        {
            colSize: 10,
            colSizeTablet: 10,
            colSizeMobile: 10,
            text: translate('misc.status'),
            customClass: "d-flex justify-content-center",
        }
    ];


    return (
        <Layout loading={isLoading}>
            {!isLoading && <div>
                <div className="bordered-container mb-4">
                    <div className={'current-page-details pl-4 mt-4 mb-3'}>
                        <h3>{translate('clients.details')}</h3>
                    </div>
                    <div className="my-account">
                    <div className="details-box">
                        <Row className={'mx-2 no-col-right-padding invalid-negative-margin '}>
                            <Col xs={12} md={4} lg={4}>
                                <CustomInput
                                    type="text"
                                    className="mb-4"
                                    label={translate('clients.ref_no')}
                                    value={client.id}
                                    disabled={true}
                                />
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <CustomInput
                                    type="text"
                                    className="mb-4"
                                    label={ translate('clients.expired_at')}
                                    value={client?.expiry_date || 'Not Activated'}
                                    disabled={true}
                                />
                            </Col>
                            <Col xs={9} md={3} lg={3}>
                                <CustomInput
                                    type="text"
                                    className="mb-4"
                                    label={translate('general.email')}
                                    value={client.email}
                                    disabled={true}
                                />
                            </Col>
                            <Col xs={3} md={1} lg={1}>
                                <div className="bullet-box">
                                    <span className="status">Status</span>
                                    <div className={`large-bullet  ${client.suspended ? 'red' : (client.owner?.confirmed ? 'green' : 'orange')}`}/>
                                </div>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <CustomInput
                                    type="text"
                                    className="mb-4"
                                    label={translate('general.users')}
                                    value={client.users_count}
                                    disabled={true}
                                />
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <CustomSelect
                                    className="mb-3"
                                    name="hospital_type_id"
                                    required={true}
                                    disabled={globalState.user.hasRole(ROLE_CLIENT_USER)}
                                    label={translate('auth.register.hospital_type')}
                                    value={client.hospital_type_id}
                                    options={dropdownOptions[HOSPITAL_TYPE]}
                                    onChange={_handleChangeClientDetails}
                                    error={errors['company.hospital_type_id']}

                                />
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <CustomSelect
                                    type="text"
                                    className="mb-3"
                                    required={true}
                                    label={translate('auth.register.oncology_chairs')}
                                    value={client.oncology_chairs_id}
                                    disabled={globalState.user.hasRole(ROLE_CLIENT_USER)}
                                    options={dropdownOptions[ONCOLOGY_CHAIRS]}
                                    name="oncology_chairs_id"
                                    onChange={_handleChangeClientDetails}
                                    error={errors['company.oncology_chairs_id']}
                                />
                            </Col>

                            <Col xs={12} md={4} lg={4}>
                                <CustomSelect
                                    type="text"
                                    className="mb-3"
                                    required={true}
                                    label={translate('auth.register.oncology_beds')}
                                    value={client.oncology_beds_id}
                                    disabled={globalState.user.hasRole(ROLE_CLIENT_USER)}
                                    options={dropdownOptions[ONCOLOGY_BEDS]}
                                    name="oncology_beds_id"
                                    onChange={_handleChangeClientDetails}
                                    error={errors['company.oncology_beds_id']}
                                />
                            </Col>

                            <Col xs={12} md={4} lg={4}>
                                <CustomSelect
                                    type="text"
                                    className="mb-3"
                                    required={true}
                                    label={translate('auth.register.hd_preparations')}
                                    value={client.hd_preparations_id}
                                    disabled={globalState.user.hasRole(ROLE_CLIENT_USER)}
                                    options={dropdownOptions[HD_PREPARATIONS]}
                                    name="hd_preparations_id"
                                    onChange={_handleChangeClientDetails}
                                    error={errors['company.hd_preparations_id']}
                                />
                            </Col>

                            <Col xs={12} md={4} lg={4}>
                                <CustomSelectCreate
                                    className="mb-30"
                                    name="compounding_device_id"
                                    required={true}
                                    label={translate('auth.register.compounding_device')}
                                    value={client.compounding_device_id}
                                    disabled={globalState.user.hasRole(ROLE_CLIENT_USER)}
                                    options={dropdownOptions[COMPOUNDING_DEVICE]}
                                    onChange={_handleChangeClientDetails}
                                    error={errors['company.compounding_device_id']}
                                />
                            </Col>

                            {(globalState.user.can(PERMISSION_ADD_CLIENT) || globalState.user.id === globalState.user?.company?.owner_id) &&
                            <Col xs={12}>
                                <FormButton
                                    size={'large'}
                                    variant={'secondary'}
                                    className={'mb-4'}
                                    text={translate('misc.update')}
                                    isLoading={isLoadingSave}
                                    onClick={_updateClient}/>
                            </Col>}
                        </Row>
                    </div>
                </div>
                </div>
                    <div className="bordered-container mb-4">
                        <div className={'current-page-details mt-4 d-flex justify-content-between'}>
                            <h3>{translate('sidebar.client_users')}</h3>
                        </div>
                        <div className="table-container simple-table-container">
                            <div className={'table-content add-users'}>
                                <Table columns={columnsUsers}
                                       hideSearchRow={true}
                                       sortOrder={'asc'}
                                       getItems={_getUsers}
                                       setItems={setUsers}
                                       refreshTable={refreshTableUsers}>
                                    {!!users.length && users.map((user, key) => {
                                        return (
                                            <div className="table-row-item row m-0" key={key}>
                                                <div className="ellipsis">{user.email} {(user.id === client.owner_id && <span className="badge badge-info badge-bd ml-1">master</span>)}</div>
                                                <div className="ellipsis">{user.locations?.map(i => i.name)?.join(', ')}</div>
                                                <div className="ellipsis justify-content-center d-flex">
                                                    <span
                                                        className={`${user.suspended ? 'status-red' : (user.confirmed ? 'status-green' : 'status-orange')}`}/>
                                                </div>
                                                <div>
                                                    {!globalState.user.hasRole(ROLE_CLIENT_USER) && <TableHover
                                                        idSuffix={key + 'clients'}
                                                        onEdit={_openEditModal}
                                                        onImpersonate={impersonate}
                                                        onDelete={anonymizeUser}
                                                        onLock={suspendItem}
                                                        onLogs={() => {
                                                        }}
                                                        item={user}
                                                        hover={{
                                                            edit: (globalState.user.id !== user.id && parseInt(user.role) !== ROLE_CLIENT_ID) || globalState.user.hasRole(ROLE_ADMIN),
                                                            impersonate: globalState.user.can(PERMISSION_IMPERSONATE) && !!user.confirmed && globalState.user.id !== user.id,
                                                            delete: (globalState.user.hasRole(ROLE_CLIENT) && globalState.user.id !== user.id && parseInt(user.role) !== ROLE_CLIENT_ID) || (globalState.user.hasRole(ROLE_ADMIN) && user.company.owner_id !== user.id)
                                                        }}
                                                    />}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Table>
                            </div>
                            {!globalState.user.readonly_access && !globalState.user.hasRole(ROLE_CLIENT_USER) &&
                            <FormButton
                                size={'large'}
                                className={'mt-4 mb-2'}
                                variant={'secondary'}
                                text={translate('misc.add_user')}
                                onClick={_openAddUserModal}/>}
                        </div>
                    </div>

                <div className="bordered-container mb-4">
                    <div className={'current-page-details mt-4 d-flex justify-content-between'}>
                        <h3>{translate('sidebar.sub_account')}</h3>
                    </div>
                    <div className="table-container simple-table-container">
                        <div className={'table-content add-users'}>
                            <Table columns={columnsOffice}
                                   hideSearchRow={true}
                                   sortOrder={'asc'}
                                   getItems={_getOffices}
                                   setItems={setOffices}
                                   refreshTable={refreshTableLocations}>
                                {!!offices.length && offices.map((office, key) => {
                                    return (
                                        <div className="table-row-item row m-0" key={key}>
                                            <div className="ellipsis">{office.name} {(!!office.main && <span className="badge badge-info badge-bd ml-1">master</span>)}</div>
                                            <div className="ellipsis">{office.country.name}</div>
                                            <div className="ellipsis">{office.users?.map(i => i.email)?.join(', ')}</div>
                                            <div>
                                                {!globalState.user.hasRole(ROLE_CLIENT_USER) && <TableHover
                                                    idSuffix={key + 'office'}
                                                    onEdit={_openEditModalLocation}
                                                    onDelete={deleteOffice}
                                                    item={office}
                                                    hover={{
                                                        edit: (globalState.user.company_id === office.company_id) || globalState.user.hasRole(ROLE_ADMIN),
                                                        delete: office.main !== 1 && ((globalState.user.company_id === office.company_id) || globalState.user.hasRole(ROLE_ADMIN)),
                                                    }}
                                                />}
                                            </div>
                                        </div>
                                    );
                                })}
                            </Table>
                        </div>
                        {!globalState.user.readonly_access && !globalState.user.hasRole(ROLE_CLIENT_USER) &&
                        <FormButton
                            size={'large'}
                            className={'mt-4 mb-2'}
                            variant={'secondary'}
                            text={translate('location.add')}
                            onClick={_openAddLocationModal}/>}
                    </div>
                </div>

            </div>}
            <ClientUserModal
                item={state.item}
                errors={errors}
                showClients={false}
                setMainState={setState}
                isOwner={globalState.user.hasRole(ROLE_CLIENT)}
                toggleModal={_toggleModal}
                offices={offices}
                pickOffice={pickOffice}
                modal={state.modal}
                save={_save}
                isLoading={isLoadingModal}
                handleChange={_handleChange}
            />

            <OfficeModal
                item={state.location}
                dropdownOptions={dropdownOptions}
                errors={errors}
                client={client}
                showClients={false}
                users={users}
                pickUser={pickUser}
                toggleModal={_toggleModalLocation}
                modal={state.modalLocation}
                save={saveLocation}
                isLoading={isLoadingModal}
                handleChange={_handleChangeLocation}
            />
        </Layout>
    );
};

export default Details;