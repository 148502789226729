import React, {useContext, useEffect, useRef, useState} from 'react';
import context from '../../context/context';
import Layout from "../Misc/Layout";
import {getTransValues, translate} from "../../libs/trans";
import {Button as ButtonBootstrap, Col} from "reactstrap";
import Table from "../Reusable/Table";
import Http from "../../libs/Http";
import TableHover from "../Reusable/TableHover";
import {useAlert} from "react-alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import AddOrEditDropdownModal from "./AddOrEditDropdownModal";
import {ROLE_ADMIN} from "../../constants";
import CustomSelect from "../Reusable/CustomSelect";
import {Link} from "react-router-dom";
import {FormButton} from "../Reusable/FormComponents";


const Dropdowns = (props) => {

    const {state: globalState} = useContext(context);
    const [isLoading, setIsLoading] = useState(false);
    const [dropdowns, setDropdowns] = useState([]);
    const [item, setItem] = useState({});
    const [modal, setModal] = useState(false);
    const [forceHover, setForceHover] = useState(false);
    const [extraParams, setExtraParams] = useState({});
    const [dropdownFilter, setDropdownFilter] = useState( props?.location?.dropdownFilterDefault || null);

    const alert = useAlert();
    const wrapperRef = useRef();
    const [refreshTable, doRefresh] = useState(0);
    const refreshTableResults = () => doRefresh(i => ++i);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => document.removeEventListener('click', handleClickOutside, true);
    }, []);

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target))
            setForceHover(false);
    };

    /**
     * Toggle Modal Add
     * @private
     */
    const _openAddModal = () => {
        setItem({});
        _toggleModal();
    };

    /**
     * Toggle Modal Edit
     * @private
     */
    const _openEditModal = (item)  => {
        setItem(item);
        _toggleModal();
    };

    /**
     * Toggle Modal
     * @private
     */
    const _toggleModal = () => {
        setModal(!modal)
    };

    const getDropdowns = params => Http.get('/dropdowns', {...params, show_suspended:true});

    useEffect(() => {
        if (dropdownFilter) {
            setExtraParams(prevState => ({...prevState, dropdown: dropdownFilter}));
            refreshTableResults();
        }
    }, [dropdownFilter]);

    const _deleteDropdown = async params => {

        setIsLoading(true);

        const res = await Http.delete('/dropdowns/' + params.id);

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            refreshTableResults();
            alert.show(translate('notifications.delete_option'), {type: 'success'});
        }

        setIsLoading(false);
    };


    const columns = [
        {
            colSize: 5,
            text: 'Id',
            name: 'id',
            sortable: true,
            colSizeTablet: 10,
            colSizeMobile: 20,
        },
        {
            colSize: 30,
            text: translate('dropdowns.option_name'),
            name: 'name',
            sortable: true,
            colSizeTablet: 40,
            colSizeMobile: 65,
        },
        {
            colSize: 10,
            text: 'Sub Options',
        },
        {
            colSize: 30,
            text: translate('dropdowns.dropdown'),
            sortable: true,
            name: 'dropdown',
            colSizeTablet: 40,
        },
        {
            colSize: 10,
            text: "Created By Client",
            sortable: true,
            name: 'client_id',
            colSizeTablet: 10,
        },
        {
            colSize: 1,
            text: '',
            colSizeTablet: 5,
            colSizeMobile: 5,
        }
    ];

    return (
        <>
            <div className="table-container">

                { dropdownFilter && <div className="mb-4">
                    <p className='dropdown-breadcrumb'><span className={'blue'} onClick={() =>setDropdownFilter(null)}>Dropdowns</span> / {getTransValues('dropdown_categories').find(i => i.value === dropdownFilter).label}</p>
                </div> }


                <div className={'table-content add-users'}>

                    {!dropdownFilter && <>
                        <div className="table-header mt-0">
                            <div className="w-col-5"><span>#</span></div>
                            <div className="w-col-95"><span>Category</span></div>
                        </div>
                    <div className={`table-container-items position-relative ${isLoading ? 'isLoading' : ''}`}>
                        {getTransValues('dropdown_categories').map((item, key) => {
                            return (
                                <div onClick={() => setDropdownFilter(item.value)} className={`table-row-item row m-0 align-items-center}`} key={key}>
                                    <div className="w-col-5">{key + 1}</div>
                                    <div className="ellipsis">
                                        {item.label}
                                    </div>
                                </div>
                            );
                        })}
                    </div></>}

                    { dropdownFilter && <Table columns={columns}
                                               isLoading={isLoading}
                                               getItems={getDropdowns}
                                               setItems={setDropdowns}
                                               refreshTable={refreshTable}
                                               extraParams={extraParams}
                                               customFilterContainer={<CustomSelect
                                                   type="text"
                                                   className="mb-4"
                                                   label={"Filter by dropdown"}
                                                   options={getTransValues('dropdown_categories')}
                                                   name="dropdown"
                                                   value={dropdownFilter}
                                                   onChange={(name, value)=> setDropdownFilter(value)}
                                               />}
                                               buttonsContainer={<FormButton
                                                   size={'large'}
                                                   variant={'secondary'}
                                                   text={"+" + translate('dropdowns.add')}
                                                   onClick={_openAddModal}/>}>
                        {!!dropdowns.length && dropdowns.map((item, key) => {
                            return (
                                <div className={`table-row-item row m-0 align-items-center ${forceHover === key ? 'force-hover' : ''}`} key={key}>
                                    <div>{item.id}</div>
                                    <div className="ellipsis">
                                        <Link to={`/settings/dropdowns/${item.id}`}>{item.name}</Link>
                                    </div>
                                    <div>{item.sub_options_count}</div>
                                    <div className={'pr-3 text-capitalize'}>{translate(`dropdown_categories.${item.dropdown}`)}</div>
                                    <div className="d-flex justify-content-center">
                                        <span className={`${item.client_id ? 'status-green' : 'status-red'}`} />
                                    </div>
                                    <div className="justify-content-center">
                                        {globalState.responsive.width <= 1024 && <div className={'table-row-item dots force-hover'}>
                                            <div className={'table-row-icons dots-icon'}>
                                                <div className="table-row-icon" onClick={() => setForceHover(key)}>
                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                </div>
                                            </div>
                                        </div>}
                                    </div>

                                    <div ref={wrapperRef}>
                                        <TableHover
                                            idSuffix={key + 'patients'}
                                            onEdit={(item) => _openEditModal(item)}
                                            onDelete={(item) => _deleteDropdown(item)}
                                            item={item}
                                            hover={{
                                                edit: globalState.user.hasRole(ROLE_ADMIN),
                                                delete: globalState.user.hasRole(ROLE_ADMIN),
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </Table>}
                </div>
            </div>

            {globalState.responsive.width <=1024 && dropdownFilter && <div className={'outside-button-container pt-4'}>
                <FormButton
                    size={'xl'}
                    className={'w-450'}
                    fluid={true}
                    variant={'secondary'}
                    text={"+" + translate('dropdowns.add')}
                    onClick={_openAddModal}/>
            </div>}

            {modal && <AddOrEditDropdownModal refreshTableResults={refreshTableResults} item={item} toggleModal={_toggleModal} />}

        </>
    );
};

export default Dropdowns;