import React, {Fragment, useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {ROLE_ADMIN} from "../../constants";

const NavElementWithDropdown = (props) => {

    const initialState = {
        listOpen: false,
        stopEvent: false
    };

    const [state, setState] = useState(initialState);

    const wrapperRef = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', _handleClickOutside, true);
        return () => document.removeEventListener('mousedown', _handleClickOutside, true);
    }, []);

    const _toggleList = () => {
        setState(prevState => ({
            ...prevState,
            listOpen: !prevState.listOpen,
            stopEvent: false
        }))

    };

    const _handleClickOutside = (event) => {
        if (!state.stopEvent && wrapperRef.current && !wrapperRef.current.contains(event.target))
            setState(prevState => ({
                ...prevState,
                listOpen: false,
                stopEvent: false
            }))
    };

    const Icon = props => (
        icon ? icon : null
    );

    const classes = [
        "dropdown-heading",
        props.className,
        ...( props.width < 768 ? ["mobile"] : []),
    ].join(" ");

    const {text, icon, userIcon, children, hasNotifications, hideArrow, noDropDown, user, ...others} = props;
    const {listOpen} = state;

    return (
        <div className={classes}
             ref={wrapperRef}
             {...(children) ? {
                 onClick: _toggleList
             } : {}}
             {...others}>

            {icon && <Icon/>}
            {hasNotifications > 0 && <div className={'notification-number'}>
                <span>{hasNotifications}</span>
            </div>}

            {children && <Fragment>
                {!hideArrow && <FontAwesomeIcon icon={faUserCircle} className={`menu-item-caret nav-user-icon ${!user.hasRole(ROLE_ADMIN) ? 'blue' : ''}`}/>}
                {listOpen && <Fragment>{children}</Fragment>}
            </Fragment>
            }

        </div>
    );
};

export default NavElementWithDropdown;