import React, {useContext, useEffect, useRef, useState} from 'react';
import context from '../../context/context';
import Layout from "../Misc/Layout";
import {translate} from "../../libs/trans";
import {
    Col,
    Row, FormGroup
} from "reactstrap";
import Table from "../Reusable/Table";
import Http from "../../libs/Http";
import TableHover from "../Reusable/TableHover";
import Modal from "../Reusable/Modal";
import CustomInput from "../Reusable/CustomInput";
import {
    DEPT_AREA, DRUG_TESTED, HOURS,
    PERMISSION_SAVE_RESULTS, ROLE_ADMIN, ROLE_CLIENT, SURFACE, SURFACE_STATUS,
} from "../../constants";
import {useAlert} from "react-alert";
import CustomSelect from "../Reusable/CustomSelect";
import {faCalendarTimes, faClock, faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {generateOfficeName, scrollToErrorInModal, scrollToTopInModal} from "../../libs/helpers";
import moment from "moment";
import {FormButton} from "../Reusable/FormComponents";
import {useHistory} from "react-router";

const ResultsList = (props) => {

    const initialState = {
        modal: false,
        addAnotherResult: false,
        item: {},
    };

    const {state: globalState, dispatch} = useContext(context);
    const [state, setState] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isLoadingSaveMore, setIsLoadingSaveMore] = useState(false);
    const [items, setItems] = useState([]);
    const [errors, setErrors] = useState({});
    const [forceHover, setForceHover] = useState(false);
    const [clients, setClients] = useState([]);
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [allDropdownOptions, setAllDropdownOptions] = useState({});
    const companyIdFilter = globalState.filters?.company_id;
    const [extraParams, setExtraParams] = useState({});
    const [offices, setOffices] = useState([]);
    const [countries, setCountries] = useState([]);
    const [mainOffice, setMainOffice] = useState({});
    const history = useHistory();

    const alert = useAlert();
    const wrapperRef = useRef();

    const [refreshTable, doRefresh] = useState(0);
    const refreshTableResults = () => doRefresh(i => ++i);

    useEffect(() => {
        if (companyIdFilter) {
            setExtraParams(prevState => ({...prevState, company_id: companyIdFilter}))
            refreshTableResults();
        }
    }, [companyIdFilter]);


    useEffect(() => {

        if (!globalState.user.hasRole(ROLE_ADMIN) || (globalState.user.hasRole(ROLE_ADMIN) && state.modal)) {
            _getOffices();
            _getMainOffice();
        }

        const params = {
            ...(globalState.user.hasRole(ROLE_ADMIN) && {client_id: state.item?.company_id}),
            mode: !state.item?.company_id ? 'all' : 'single'
        };

        if (state.item?.company_id) {
            _getDropdownOptions(params);
        } else {
            _getDropdownOptions(params);
        }
    }, [state.item?.company_id]);

    useEffect(() => {
        if (!countries.length && state.modal === true && state.item.office_id === 0) {
            _getCountries();
        }
    }, [state.item?.office_id]);

    useEffect(() => {
        if (state.modal === false) {
            setState(prevState => ({
                ...prevState,
                item: {},
                addAnotherResult: false
            }));
            setErrors({});
        }
    }, [state.modal]);


    useEffect(() => {
        if (history.location?.create === true && !globalState.user.readonly_access) {
            setState(prevState => ({...prevState, modal: true}));
        }
    }, [history.location?.create]);

    useEffect(() => {
        if (globalState.user.hasRole(ROLE_ADMIN)) {
            _getClients()
        }
    }, []);

    const _getDropdownOptions = async (params) => {
        const res = await Http.get('/dropdown-options', {...params, result: true});
        if (!res.hasError) {
            if (!globalState.user.hasRole(ROLE_ADMIN)) {
                setAllDropdownOptions(res.data);
                setDropdownOptions(res.data);
            } else if (params && params.hasOwnProperty('mode') && params.mode === 'all') {
                setAllDropdownOptions(res.data);
            } else {
                setDropdownOptions(res.data);
            }
        }
    };

    const _getOffices = async () => {

        setIsLoading(true);

        const params = {
            ...(globalState.user.hasRole(ROLE_ADMIN) && {company_id: state.item.company_id})
        };

        const res = await Http.get('/options/office', params);

        if (!res.hasError) {
            let tempOffices = res.data;

            if (globalState.user.id === globalState.user.company?.owner_id || globalState.user.hasRole(ROLE_ADMIN)) {
                tempOffices.push({
                    value: 0,
                    label: "Other"
                });
            }

            setOffices(tempOffices);
        }

        setIsLoading(false);
    };


    const _getMainOffice = async () => {

        setIsLoading(true);

        const params = {
            ...(globalState.user.hasRole(ROLE_ADMIN) && {company_id: state.item.company_id})
        };

        const res = await Http.get('/options/main_office', params);

        if (!res.hasError) {
            setMainOffice(res.data);
        }

        setIsLoading(false);
    };


    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => document.removeEventListener('click', handleClickOutside, true);
    }, []);

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target))
            setForceHover(false);
    };

    const _handleChange = (name, value) => {

        let otherAttributes = {};

        //reset values when company dropdown changes
        if (name === 'company_id') {
            setState(prevState => ({
                ...prevState, item: {
                    ...prevState.item,
                    office_id: null,
                    surface_id: null,
                    area_id: null,
                    area_id_sub: 0,
                    surface_id_sub: 0
                }
            }));
        }

        if (name === 'area_id') {
            otherAttributes['area_id_sub'] = 0;
            if(value === 0){
                setState(prevState => ({...prevState, item: {...prevState.item, new_area_id: null}}));
            }
        }

        if (name === 'office_id' && value === 0) {
            let refNo;
            if (globalState.user.hasRole(ROLE_ADMIN)) {
                refNo = state.item?.company_id;
            } else {
                refNo = globalState.user.company_id;
            }

            setState(prevState => ({
                ...prevState, item: {
                    ...prevState.item,
                    office_id: null,
                    country_id: mainOffice.country_id,
                    office_name: generateOfficeName(refNo, offices.length - 1)
                }
            }));
        }

        
        if (name === 'surface_id') {
            otherAttributes['surface_id_sub'] = 0;
            if(value === 0){
                setState(prevState => ({...prevState, item: {...prevState.item, new_surface_id: null}}));
            }
        }

        if (name === 'surface_id_sub') {
            setState(prevState => ({...prevState, item: {...prevState.item, new_surface_sub_id: null}}));
        }

        if (name === 'area_id_sub') {
            setState(prevState => ({...prevState, item: {...prevState.item, new_area_sub_id: null}}));
        }

        setState(prevState => ({...prevState, item: {...prevState.item, [name]: value, ...otherAttributes}}));
    };

    const _toggleModal = () => {
        setState(prevState => ({...prevState, modal: !prevState.modal}));
    };

    const _getCountries = async () => {
        const res = await Http.get('/countries');
        if (!res.hasError) {
            setCountries(res.data.options);
        }
    };

    const _openEditModal = async (item) => {

        let data = JSON.parse(JSON.stringify(item));

        if (data.date_time) {
            const arrDate = data.date_time.split(' ');
            data.date = arrDate[0];
            data.time = arrDate[1];
        }

        setState(prevState => ({
            ...prevState,
            item: data
        }));

        _toggleModal();
    };


    const _getClients = async () => {

        setIsLoading(true);

        const res = await Http.get('/clients/min');

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            setClients(res.data);
        }

        setIsLoading(false);
    };

    const getItems = params => Http.get('/results', {...params});

    const deleteItem = async params => {
        setIsLoading(true);
        const res = await Http.delete('/results/' + params.id);
        setIsLoading(false);
        if (res.hasError) {
            alert.show(res.error.general, {type: 'error'});
        } else {
            alert.show(translate('notifications.delete_result'), {type: 'success'});

        }
        refreshTableResults();
    };

    const _save = async (addAnother = false) => {

        if (isLoadingSave || isLoadingSaveMore) return false;

        if (addAnother) {
            setIsLoadingSaveMore(true);
        } else {
            setIsLoadingSave(true);
        }

        let result = JSON.parse(JSON.stringify(state.item));
        result.date_time = result.date + ' ' + result.time;

        const res = await Http.post('/results/save', result);

        if (addAnother) {
            setIsLoadingSaveMore(false);
        } else {
            setIsLoadingSave(false);
        }

        if (res.hasError) {
            setErrors(res.error);
            scrollToErrorInModal();
        } else {
            if(!state.item.area_id || !state.item.surface_id){
                _getDropdownOptions();
            }
            if (state.item?.id) {
                alert.show(translate('notifications.update_result'), {type: 'success'});
                _toggleModal();
            } else {
                if (addAnother) {
                    setState(prevState => ({
                        ...prevState,
                        item: {},
                        addAnotherResult: true
                    }));
                    setErrors({});
                    scrollToTopInModal();
                } else {
                    _toggleModal();
                }
                alert.show(translate('notifications.add_result'), {type: 'success'});
            }
            refreshTableResults();
        }

    };

    const getSubOptions = (options, parent_id) => {
        let selectOptions = [{label: 'Default', value: 0}];
        let parentOptions = options?.find(i => i.value === parent_id)?.sub_options || [];
        return selectOptions.concat(parentOptions.map(i => ({
            label: i.name,
            value: i.id
        }))).concat({
            value: 'my_own',
            label: "Add My Own"
        });
    }

    const appendNewSubOption = (type, parent_id, newOption) => {
        dropdownOptions[type].find(i => i.value === parent_id).sub_options.push({id: newOption.value, name: newOption.label});
    }


    const columns = [
        {
            colSize: 5,
            text: 'ID',
            name: 'id',
            sortable: true,
            colSizeTablet: 10,
            colSizeMobile: 15,
        },
        globalState.user.hasRole(ROLE_ADMIN) && {
            colSize: 10,
            text: translate('sidebar.account'),
            colSizeTablet: 20,
        },
        {
            colSize: 8,
            text: translate('results.drug_tested'),
            name: 'name',
            colSizeTablet: 20,
            colSizeMobile: 40,
        },
        {
            colSize: 10,
            text: translate('results.result'),
            colSizeTablet: 20,
        },
        {
            colSize: 12,
            text: translate('results.area'),
        },
        {
            colSize: 16,
            text: translate('results.surface')
        },
        {
            colSize: globalState.user.hasRole(ROLE_ADMIN) ? 14 : 20,
            text: translate('results.surface_status'),
        },
        {
            colSize:  globalState.user.hasRole(ROLE_ADMIN) ? 8 : 10,
            text: translate('results.is_retest'),
            customClass: "d-flex justify-content-center",
        },
        {
            colSize: 10,
            text: translate('results.date'),
            colSizeTablet: globalState.user.hasRole(ROLE_ADMIN) ? 15 : 20,
            colSizeMobile: 40,
        },
        {
            colSize: 5,
            text: translate('results.time'),
            colSizeTablet: 10,
        },
        {
            colSize: 1,
            text: '',
            colSizeTablet: 5,
            colSizeMobile: 5,
        }
    ];

    const _handleChangeFilters = (name, value) => {
        dispatch({type: "SET_FILTERS", payload: {[name]: value}});
    };

    let headerFilters = null;

    if(globalState.user.hasRole(ROLE_ADMIN) && !globalState.responsive.isMobile){
        headerFilters = <div className="iteam-header-filter header-filters">
            <div className="header-select-filter mr-3">
                <CustomSelect
                    className=""
                    name="company_id"
                    placeholder={"Accounts"}
                    onChange={_handleChangeFilters}
                    value={globalState.filters?.company_id || ''}
                    options={clients}
                />
            </div>
        </div>;
    }



    const _export = async _ => {
        setIsLoading(true);
        await Http.download('/results/export', extraParams);
        setIsLoading(false);
    };

    const {item} = state;


    return (
        <Layout headerFilters={headerFilters ? headerFilters : ''}>

            {(globalState.user.hasRole(ROLE_ADMIN) && globalState.responsive.isMobile) &&
            <div className="mt-3 mx-4 pl-1">
                <div className="header-select-filter">
                    <CustomSelect
                        className=""
                        name="company_id"
                        placeholder={"Accounts"}
                        onChange={_handleChangeFilters}
                        value={globalState.filters?.company_id || ''}
                        options={clients}
                    />
                </div>
            </div>}

            <div className="bordered-container">
                <h3 className={'mt-3 ml-4'}>{translate('results.title')}</h3>
                <div className="table-container">
                    <div className={'table-content add-users'}>
                        <Table columns={columns}
                               getItems={getItems}
                               setItems={setItems}
                               extraParams={extraParams}
                               refreshTable={refreshTable}
                               buttonsContainer={<>
                                   <FormButton
                                       size={'xs'}
                                       variant={'secondary'}
                                       className={'mr-3 mb-3'}
                                       text={"Back"}
                                       onClick={() => history.push('/overall-analysis')}/>
                                   <FormButton
                                       size={'large'}
                                       variant={'primary'}
                                       className={'mr-3 mb-3'}
                                       text={"Export"}
                                       onClick={_export}/>
                                   {!globalState.user.readonly_access && <FormButton
                                       size={'large'}
                                       variant={'primary'}
                                       text={"+ " + translate('results.add')}
                                       onClick={_toggleModal}/>}
                               </>}>
                            {!!items.length && items.map((result, key) => {
                                return (
                                    <div className={`table-row-item row align-items-center m-0 ${forceHover === key ? 'force-hover' : ''}`} key={key}>
                                        <div onClick={() =>_openEditModal(result)}>{result.id}</div>
                                        {globalState.user.hasRole(ROLE_ADMIN) && <div className="ellipsis" onClick={() =>_openEditModal(result)}>{result?.company.id}</div>}
                                        <div className="ellipsis pr-3" onClick={() =>_openEditModal(result)}>{allDropdownOptions[DRUG_TESTED]?.find(option => option.value === result?.drug_tested_id)?.label}</div>
                                        <div className="ellipsis pr-3">{result.result === 0 ? translate("misc.negative") : translate("misc.positive")}</div>
                                        <div className="ellipsis pr-3">{allDropdownOptions[DEPT_AREA]?.find(option => option.value === result?.area_id)?.label}</div>
                                        <div className="ellipsis pr-3">{allDropdownOptions[SURFACE]?.find(option => option.value === result?.surface_id)?.label}</div>
                                        <div className="ellipsis pr-3">{allDropdownOptions[SURFACE_STATUS]?.find(option => option.value === result?.surface_status_id)?.label}</div>
                                        <div className="justify-content-center">{result.is_retest === 0 ? translate("misc.no") : translate("misc.yes")}</div>
                                        <div>{moment(result.date_time).format('DD-MM-YYYY')}</div>
                                        <div>{moment(result.date_time).format('HH:mm')}</div>
                                        <div className="justify-content-center">
                                            {globalState.responsive.width <= 1024 &&
                                            <div className={'table-row-item dots force-hover'}>
                                                <div className={'table-row-icons dots-icon'}>
                                                    <div className="table-row-icon" onClick={() => setForceHover(key)}>
                                                        <FontAwesomeIcon icon={faEllipsisH}/>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>

                                        <div ref={wrapperRef}>
                                            {globalState.user.can(PERMISSION_SAVE_RESULTS) ? <TableHover
                                                idSuffix={key + 'results'}
                                                onEdit={_openEditModal}
                                                onDelete={deleteItem}
                                                item={result}
                                                hover={{
                                                    delete: (!globalState.user.readonly_access && (globalState.user.id === result.user_id || globalState.user.hasRole(ROLE_CLIENT))) || globalState.user.hasRole(ROLE_ADMIN),
                                                    edit: (!globalState.user.readonly_access && (globalState.user.id === result.user_id || globalState.user.hasRole(ROLE_CLIENT))) || globalState.user.hasRole(ROLE_ADMIN),
                                                }}
                                            /> : <TableHover hover={{}}/>}
                                        </div>
                                    </div>
                                );
                            })}
                        </Table>
                    </div>
                </div>
            </div>

            {globalState.responsive.width <=1024 && <div className={'d-block mt-4 px-4 w-450'}>
                <FormButton
                    size={'xl'}
                    variant={'primary'}
                    fluid={true}
                    className={'mr-3 d-block mb-3'}
                    text={"Export"}
                    onClick={_export}/>
                {!globalState.user.readonly_access && <FormButton
                    size={'xl'}
                    fluid={true}
                    className={'mr-3 d-block mb-3'}
                    variant={'primary'}
                    text={"+ " + translate('results.add')}
                    onClick={_toggleModal}/>}
                <FormButton
                    size={'xl'}
                    fluid={true}
                    variant={'secondary'}
                    className={'mr-3 d-block mb-3'}
                    text={"Back"}
                    onClick={() => history.push('/overall-analysis')}/>
            </div>}

            <Modal modal={state.modal} _toggleModal={_toggleModal} className={'bd-my-modal results-modal modal-right'}
                   hideTitle={true}
                   backdrop={globalState.responsive.isDesktop}
                   wrapClassName={!globalState.responsive.isDesktop}
                   buttonSize={'xl'}
                   buttonFluid={true}
                   confirmText={translate('misc.save')}
                   {...(!state.item?.id && state.modal && {
                       addAnotherButton:  <div className={globalState.responsive.isTablet ? 'd-none' : 'm-0'}>
                           <FormButton
                               size={'xl'}
                               fluid={true}
                               isLoading={isLoading}
                               className={'mt-3'}
                               variant={'secondary'}
                               text={"Save & Add Another"}
                               onClick={() => _save(true)}/>
                           <FormButton
                               size={'xl'}
                               fluid={true}
                               isLoading={isLoading}
                               className={'mt-3'}
                               variant={'secondary'}
                               text={"Back"}
                               onClick={_toggleModal}/>
                       </div>
                   })}
                   {...(state.item?.id && state.modal && {
                       addAnotherButton:  <div className={globalState.responsive.isTablet ? 'd-none' : 'm-0'}>
                           <FormButton
                               size={'xl'}
                               fluid={true}
                               isLoading={isLoading}
                               className={'mt-3'}
                               variant={'secondary'}
                               text={"Back"}
                               onClick={_toggleModal}/>
                       </div>
                   })}
                   onAct
                   onAction={() => _save()}
                   isLoading={isLoadingSave}
                   hideActionButton={globalState.responsive.isTablet}
                   hideCancelButton={true}>
                <Row noGutters={true} className={'iteam-modal-row'}>
                    {!state.addAnotherResult && <Col xs={12}><h5 className={'mb-4'}>{translate('results.add')}</h5></Col> }
                    {state.addAnotherResult && <div className={'my-3 w-100 col-12'}>
                        <h5>Your Result is Submitted</h5>
                        <span>Your result has been submitted to your account and is live.</span>
                        <hr/>
                        <h5 className={'my-4'}>Add another result</h5>
                    </div>}
                    <Col xs={globalState.responsive.isTablet ? 6 : 12} className={"negative-margin col-left"}>

                        {globalState.user.hasRole(ROLE_ADMIN) &&
                        <CustomSelect
                            className="mb-3"
                            name="company_id"
                            required={true}
                            label={translate('sidebar.account')}
                            value={item.company_id}
                            options={clients}
                            onChange={_handleChange}
                            error={errors.company_id}
                        />}
                        <CustomSelect
                            className="mb-3"
                            name="office_id"
                            required={true}
                            disabled={globalState.user.hasRole(ROLE_ADMIN) && !item.company_id}
                            label={"Choose Sub Account"}
                            value={item.office_id}
                            options={offices}
                            onChange={_handleChange}
                            error={errors.office_id}
                        />
                        {item.office_id === 0 && <div  className="multiple-office">
                            <span/>
                            <div className="w-100">
                                <FormGroup>
                                    <CustomSelect
                                        className="mb-3"
                                        name="country_id"
                                        label={translate('location.country')}
                                        required={true}
                                        onChange={_handleChange}
                                        value={item.country_id}
                                        options={countries}
                                        error={errors.country_id}
                                        loadingNoOptions={isLoading}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <CustomInput
                                        name="office_name"
                                        label={translate('location.office_name')}
                                        required={true}
                                        readonly={true}
                                        onChange={() => {}}
                                        value={item.office_name}
                                        error={errors.office_name}
                                    />
                                </FormGroup>
                            </div>
                        </div>}
                        <CustomInput
                            type="text"
                            date={true}
                            required={true}
                            parentClass={"mb-3"}
                            finishDate={moment(new Date()).format('YYYY-MM-DD')}
                            icon={faCalendarTimes}
                            name="date"
                            biggerIcon={true}
                            label={translate('results.date')}
                            value={item.date || ""}
                            onChange={_handleChange}
                            error={errors.date}
                        />
                        <CustomSelect
                            required={true}
                            className={"mb-3"}
                            name="time"
                            label={translate('results.time')}
                            value={item.time || ""}
                            customIconRight={faClock}
                            options={HOURS}
                            onChange={_handleChange}
                            error={errors.time}
                        />
                        <CustomSelect
                            className="mb-3"
                            name="area_id"
                            required={true}
                            label={translate('results.area')}
                            value={item.area_id}
                            options={dropdownOptions[DEPT_AREA]?.concat({
                                value: 0,
                                label: "Other"
                            })}
                            onChange={_handleChange}
                            error={errors.area_id}
                        />

                        {!!(item.area_id && item.area_id !==0) && <div className="nested-select-return mb-3">
                            <span/>
                            <div className={'d-block w-100'}>
                                <CustomSelect
                                    name="area_id_sub"
                                    required={true}
                                    label={`Type of ${dropdownOptions[DEPT_AREA]?.find(i => i.value === item.area_id)?.label}`}
                                    value={item.area_id_sub || 0}
                                    appendNewOption={newOption => appendNewSubOption(DEPT_AREA, item.area_id, newOption)}
                                    options={getSubOptions(dropdownOptions[DEPT_AREA], item.area_id)}
                                    onChange={_handleChange}
                                    error={errors.area_id_sub}
                                />
                                {item.area_id_sub === 'my_own' && <div className={'mt-3'}>
                                    <CustomInput
                                        name="new_area_sub_id"
                                        required={true}
                                        label={`Type of ${dropdownOptions[DEPT_AREA]?.find(i => i.value === item.area_id)?.label}`}
                                        value={item.new_area_sub_id}
                                        onChange={_handleChange}
                                        error={errors.new_area_sub_id}
                                    />
                                    <div className="loc-info mt-2">{translate('results.dept_info_type')}</div>
                                </div>}
                            </div>
                        </div>}

                        {item.area_id === 0 && <FormGroup>
                            <div className="nested-select-return mb-3 result">
                                <span/>
                                <CustomInput
                                    name="new_area_id"
                                    parentClass={"w-100"}
                                    required={true}
                                    label={`Add Your Own Dept / Area`}
                                    value={item.new_area_id}
                                    onChange={_handleChange}
                                    error={errors.new_area_id}
                                />
                            </div>
                            <div className="loc-info mb-3 ml-5 pl-1">{translate('results.dept_info')}</div>
                        </FormGroup>}

                        <CustomSelect
                            className="mb-3"
                            name="surface_id"
                            required={true}
                            label={translate('results.surface')}
                            value={item.surface_id}
                            options={dropdownOptions[SURFACE]?.concat({
                                value: 0,
                                label: "Other"
                            })}
                            onChange={_handleChange}
                            error={errors.surface_id}
                        />

                        {!!(item.surface_id && item.surface_id !== 0) && <div className="nested-select-return mb-3">
                            <span/>
                            <div className={'d-block w-100'}>
                                <CustomSelect
                                    name="surface_id_sub"
                                    required={true}
                                    label={`Type of ${dropdownOptions[SURFACE]?.find(i => i.value === item.surface_id)?.label}`}
                                    value={item.surface_id_sub || 0}
                                    appendNewOption={newOption => appendNewSubOption(SURFACE, item.surface_id, newOption)}
                                    options={getSubOptions(dropdownOptions[SURFACE], item.surface_id)}
                                    onChange={_handleChange}
                                    error={errors.surface_id_sub}
                                />
                                {item.surface_id_sub === 'my_own' && <div className={'mt-3'}>
                                    <CustomInput
                                        name="new_surface_sub_id"
                                        required={true}
                                        label={`Type of ${dropdownOptions[SURFACE]?.find(i => i.value === item.surface_id)?.label}`}
                                        value={item.new_surface_sub_id}
                                        onChange={_handleChange}
                                        error={errors.new_surface_sub_id}
                                    />
                                    <div className="loc-info mt-2">{translate('results.surface_info_type')}</div>
                                </div>}
                            </div>
                        </div>}

                        {item.surface_id === 0 && <FormGroup>
                            <div className="nested-select-return mb-3 result">
                                <span/>
                                <CustomInput
                                    name="new_surface_id"
                                    parentClass={"w-100"}
                                    required={true}
                                    label={`Add Your Own Surface`}
                                    value={item.new_surface_id}
                                    onChange={_handleChange}
                                    error={errors.new_surface_id}
                                />
                            </div>
                            <div className="loc-info mb-3 ml-5 pl-1">{translate('results.surface_info')}</div>
                        </FormGroup>}

                    </Col>
                    <Col xs={globalState.responsive.isTablet ? 6 : 12} className={"negative-margin col-right"}>

                        <CustomSelect
                            className="mb-3"
                            name="surface_status_id"
                            required={true}
                            label={translate('results.surface_status')}
                            value={item.surface_status_id}
                            options={dropdownOptions[SURFACE_STATUS]}
                            onChange={_handleChange}
                            error={errors.surface_status_id}
                        />
                        <CustomSelect
                            className="mb-3"
                            name="drug_tested_id"
                            required={true}
                            label={translate('results.drug_tested')}
                            value={item.drug_tested_id}
                            options={dropdownOptions[DRUG_TESTED]}
                            onChange={_handleChange}
                            error={errors.drug_tested_id}
                        />
                        <CustomSelect
                            className="mb-3"
                            name="result"
                            required={true}
                            label={translate('results.result')}
                            value={item.result}
                            options={[
                                {value: 0, label: "Negative"},
                                {value: 1, label: "Positive"},
                            ]}
                            onChange={_handleChange}
                            error={errors.result}
                        />
                        <CustomSelect
                            className="mb-3"
                            name="is_retest"
                            required={true}
                            label={translate('results.is_retest')}
                            value={item.is_retest}
                            options={[
                                {value: 0, label: "No"},
                                {value: 1, label: "Yes"},
                            ]}
                            onChange={_handleChange}
                            error={errors.is_retest}
                        />

                        <CustomInput
                            type="textarea"
                            className="mb-3 results-textarea"
                            label={translate('results.add_comment')}
                            name="comment"
                            value={item.comment}
                            onChange={_handleChange}
                            error={errors.comment}
                        />

                        <div className="loc-info mb-3">{translate('results.comment_info')}</div>

                        {globalState.responsive.isTablet && <div>
                            <FormButton
                                size={'xl'}
                                fluid={true}
                                isLoading={isLoading}
                                className={'mt-3'}
                                variant={'primary'}
                                text={"Save"}
                                onClick={() => _save()}/>
                            {!state.item?.id &&  <FormButton
                                size={'xl'}
                                fluid={true}
                                isLoading={isLoading}
                                className={'mt-3'}
                                variant={'secondary'}
                                text={"Save & Add Another"}
                                onClick={() => _save(true)}/>}
                            <FormButton
                                size={'xl'}
                                fluid={true}
                                isLoading={isLoading}
                                className={'mt-3'}
                                variant={'secondary'}
                                text={"Back"}
                                onClick={_toggleModal}/>
                        </div> }

                    </Col>
                </Row>
            </Modal>


        </Layout>
    );
};

export default ResultsList;