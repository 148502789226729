import React, {useEffect, useState} from 'react';
import Select, {components} from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormFeedback, Label} from "reactstrap";
import Creatable from "react-select/creatable/dist/react-select.esm";

const CustomSelectCreate = (props) => {

    const initialState = {
        isFocused: (props.value !== undefined && props.value !== null && props.value.toString().length > 0) === true
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        if ((props.value === undefined || props.value?.toString().length === 0 || props.value === null)) {
            setState(prevState => ({...prevState, isFocused: false}))
        }
        if (props.value !== '' && props.value !== undefined && props.value?.toString().length > 0) {
            setState(prevState => ({...prevState, isFocused: true}))
        }
        if (props.value !== '' && props.value !== undefined && (props.value && props.value?.toString().length > 0)) {
            setState(prevState => ({...prevState, isFocused: true}))
        }
    }, [props]);

    /**
     *
     * @param isFocused
     * @returns {Function}
     * @private
     */
    const _onFocus = (isFocused) => () => {

        if (props.value !== undefined && props.value !== null && props.value.toString().length > 0) {
            isFocused = true;
        }

        setState(prevState => ({...prevState, isFocused}))
    };

    /**
     *
     * @param option
     * @param select
     * @private
     */
    const _handleChange = (option, select) => {

        if (props.isMulti) {
            let val = select.hasOwnProperty('removedValue') ? select.removedValue?.value : select.option.value;
            return props.onChange(select.name, val);
        }

        const value = (option?.value || option?.value === 0) ? option?.value : null;
        const {onChange, itemKey, name} = props;
        !itemKey && onChange && onChange(name, value);
        itemKey && onChange && onChange(name, value, itemKey);
    };


    /**
     *
     * @param value
     * @private
     */
    const _handleCreate = (value) => {
        const newValue = {value: value.toLowerCase(), label: value};
        props.options.push(newValue);
        props.appendNewOption && props.appendNewOption(newValue); //force update parent state with new options
        const {onChange, itemKey, name} = props;
        !itemKey && onChange && onChange(name, value);
        itemKey && onChange && onChange(name, value, itemKey);
    };


    const invalid = props.error !== undefined;

    let containerClasses = [
        "custom-input-container",
        ...(invalid ? ["custom-input-adjust"] : []),
        ...(props.borderRightNone ? ["custom-input-container-border-none"] : []),
        ...(props.edit ? ["edit"] : [])
    ].join(" ");

    let labelClasses = [
        "custom-label",
        ...(state.isFocused ? ["input-focus"] : []),
        ...(props.icon && props.iconDirection === 'left' ? ["with-icon"] : []),
        ...(props.isMulti ? ["is-multi"] : []),
        ...(props.no_label ? ["d-none"] : []),
        ...(props.value !== null && props.value !== undefined ? ["is-selected"] : []),
    ].join(" ");

    let selectClasses = [
        "custom-select-react",
        ...(invalid ? ["is-invalid"] : []),
        ...(props.isMulti ? ["is-multi"] : []),
        props.className
    ].join(" ");

    const DropdownIndicator = data => {
        return (
            <components.DropdownIndicator {...data}>
                <FontAwesomeIcon className="custom-icon-right" icon={props.customIconRight}/>
            </components.DropdownIndicator>
        );
    };

    let styled = {};

    if (props.textColor) {
        styled.color = props.textColor;
    }

    if (props.width) {
        styled.width = props.width;
    }

    if (props.marginTop) {
        styled.marginTop = props.marginTop;
    }

    const {error, label, required, name, options, value} = props;

    return (
        <>
            {error && <FormFeedback>{error}</FormFeedback>}

            <div className={containerClasses}>
                {label && <Label className={`${labelClasses} ${error ? 'error' : ''}`}>
                    {label}{required && <span className={'field-required'}>*</span>}
                </Label>}

                <Creatable
                    styles={props.styles}
                    className={selectClasses}
                    classNamePrefix="react-select"
                    isDisabled={props.disabled}
                    isSearchable={props.isSearchable}
                    onCreateOption={_handleCreate}
                    name={name}
                    {...(!props.isMulti ?
                            {value: options && options.filter(option => option.value === value)}
                            :
                            {value: options && options.filter(option => value && value.length && value.indexOf(option.value) > -1)}
                    )}
                    isClearable={!!props.isClearable}
                    onChange={_handleChange}
                    options={options}
                    {...(props.customIconRight && {components: {DropdownIndicator}})}
                    {...(props.getOptions && {onMenuOpen: props.getOptions})}
                    isMulti={props.isMulti}
                    placeholder={''}
                    onFocus={_onFocus(true)}
                    onBlur={_onFocus(false)}
                    {...(props.loadingNoOptions && {
                        noOptionsMessage: () => {
                            return <div className={'table-loader select-loader'}>
                                <div className="spinner"/>
                            </div>;
                        }
                    })}

                />

            </div>
        </>
    );
};

export default CustomSelectCreate;