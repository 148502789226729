import React from 'react';

const SlideToggle = ({onChange, options, value, height = 80}) => {

    if (options.length === 1) {
        value = options[0].value;
    }

    return (
        <div className="slide-toggle-container" style={{minHeight: height + 'px'}}>

                            <span className={`selected ${value ? 'active' : ''}`} style={{
                                width: `calc((100% - 6px) / ${options.length})`,
                                transform: `translateX(calc(100% * ${options.findIndex(i => i.value === value)}))`
                            }}>{options.find(i => i.value === value)?.label}</span>

            { options.map(item => <div className="item" key={item.value} onClick={() => onChange(item.value)}>
                <span>{item.label}</span>
            </div>)}

        </div>
    );
};

export default SlideToggle;