import React, {useState} from 'react';
import {
    Col,
    Modal as BootstrapModal,
    ModalBody as BootstrapModalBody,
    ModalHeader as BootstrapModalHeader,
    Row
} from "reactstrap";
import CustomInput from "../../Reusable/CustomInput";
import CustomButton from "../../Reusable/Button";
import {translate} from "../../../libs/trans";
import Http from "../../../libs/Http";
import Dropzone from "../../Reusable/Dropzone";
import {useAlert} from "react-alert";
import UploadChunk from "./UploadChunk";
import {FormButton} from "../../Reusable/FormComponents";


const UploadImageModal = (props) => {

    const [item, setItem] = useState(props.item || {});
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const alert = useAlert();

    /**
     * Handle Change Item
     * @param name
     * @param value
     * @private
     */
    const _handleChange = (name, value) => {
        setItem(prevState => ({...prevState, [name]: value}));
    };

    /**
     * Handle image dropzone
     * @param dropped
     * @private
     */
    const _handleDrop = dropped => {

        _handleChange('image', dropped[0]);
    };


    /**
     * Handle Save
     * @returns {Promise<boolean>}
     * @private
     */
    const _handleSave = async () => {

        if (isLoading) return false;

        setIsLoading(true);

        const res = await Http.post("/media", {...item, type: props.type, hasFiles: true});

        if (res.hasError) {
            setErrors(res.error);
        } else {
            setErrors({});
            setItem({});
            alert.show(translate(`notifications.add_${type === 'faq_video' ? 'video' : 'image'}`), {type: 'success'});
            props.toggleModal();
            props.refreshTableResults()
        }

        setIsLoading(false);
    };


    const {toggleModal, mediaPick, type} = props;

    return (
        <BootstrapModal isOpen={true} toggle={toggleModal}
                        className="general-modal modal-upload">
            <BootstrapModalHeader toggle={toggleModal} className={'mx-3 mt-3'}>
                <h5> {!item.hasOwnProperty('id') ? translate(`media.upload_${type === 'faq_video' ? 'video' : 'image'}`) : translate(`media.edit_${type === 'faq_video' ? 'video' : 'image'}`)}</h5>
            </BootstrapModalHeader>
            <BootstrapModalBody>
                <div className="container-fluid">
                    <Row>
                        <Col sm={6}>
                            <CustomInput
                                type="text"
                                className="mb-4"
                                label={type === 'faq_video' ? translate("media.video") : translate("media.title")}
                                required={true}
                                name="title"
                                value={item.title}
                                onChange={_handleChange}
                                error={errors.title}
                            />
                        </Col>
                        <Col sm={6}>
                            <CustomInput
                                type="text"
                                className="mb-4"
                                label={translate("media.keywords")}
                                name="keywords"
                                value={item.keywords}
                                onChange={_handleChange}
                                error={errors.keywords}
                            />
                        </Col>
                        {type !== 'faq_video' && <Col sm={12}>
                            <Dropzone
                                handleDrop={_handleDrop}
                                item={item}
                            />
                        </Col>}
                        {type === 'faq_video' && <Col sm={12}>
                            {item.hasOwnProperty('id') ? <div className="my-3">
                                    <video width="100%" controls controlsList="nodownload">
                                        <source src={item.full_path} type="video/mp4"/>
                                    </video>
                                </div> :
                                <UploadChunk acceptedTypes={['mp4']}
                                             onChunksUploaded={(chunkFilename) => setItem(prevState => ({
                                                 ...prevState,
                                                 chunkFilename
                                             }))}/>}
                        </Col>}
                        <Col sm={12} className="text-center mt-4">
                            <FormButton
                                size={'large'}
                                className={'mb-3'}
                                variant={'primary'}
                                isLoading={isLoading}
                                text={translate(item.hasOwnProperty('id') ? 'misc.update' : "media.upload")}
                                onClick={_handleSave}/>
                            {mediaPick && <CustomButton text={translate("media.or_load")}
                                                        className="ml-3"
                                                        backgroundColor={'white-grey'}
                                                        isLoading={isLoading}
                                                        onClick={props.onLoadMediaBtnClick}
                            />}

                        </Col>
                    </Row>
                </div>
            </BootstrapModalBody>
        </BootstrapModal>
    );
};

export default UploadImageModal;
