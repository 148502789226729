import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
    Col,
    Modal as BootstrapModal,
    ModalBody as BootstrapModalBody,
    Row
} from "reactstrap";
import {Bar, default as CustomChart} from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {useAlert} from "react-alert";
import Http from "../../libs/Http";
import Chart from "chart.js";
import {translate} from "../../libs/trans";
import HorizontalBarChart from "./HorizontalBarChart";
import {FormButton} from "../Reusable/FormComponents";
import context from "../../context/context";

Chart.defaults.pie2 = Chart.defaults.pie;
Chart.controllers.pie2 = Chart.controllers.pie.extend({
    updateElement: function (arc, index, reset) {
        Chart.controllers.pie.prototype.updateElement.call(this, arc, index, reset);
        const displacement = this.getDataset().displacements[index] || 0;
        const model = arc._model;
        const angle = model.startAngle + model.circumference / 2;
        model.x += Math.cos(angle) * displacement;
        model.y += Math.sin(angle) * displacement;
    }
});


const pieOptions = {
    layout: {
        padding: 10
    },
    legend: {
        display: false
    },
    plugins: {
        datalabels: {
            color: '#fff',
            font: {
                weight: 'bold',
                size: 18
            },
            formatter: function (value, context) {
                return value + ' %';
            }
        }
    }
};

const colors = [
    [['#EF6549', '#FAD0C8']],
    [['#0090B5', '#ADDFEB']],
    [['#009988', '#A9DCD6']],
];

const CurrentResultsCharts = ({params}) => {

    const {state: globalState} = useContext(context);
    const [results, setResults] = useState([]);
    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState({});
    const alert = useAlert();

    useEffect(() => {
        getCompareResults();
    }, [params]);

    const getCompareResults = useCallback(async () => {
        setIsLoading(true);

        const res = await Http.get('/dashboard/pies', params);

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            setResults(res.data);
        }

        setIsLoading(false);
    }, [params]);

    const toggleModal = () => {
        setModal(!modal);
    };

    const openMoreModal = (item, key) => _ => {
        item.key = key;
        setItem(item)
        setModal(true);
    };

    return (
        <div>

            {isLoading ? <div className="current-results-spinner">
                    <div className="spinner"/>
                </div> :
                <div className="row flex-multiple-charts">
                    <Col md={12} className="d-flex pies-row">
                        {results.map((item, key) => {
                            if (item.positive === 0 && item.negative === 0) {
                                return (<Col lg={4} key={key} className="chart-col">
                                    <div className="pie-container">
                                        <h5 className="chart-pie-title">{item.name}</h5>
                                        <CustomChart width={1} height={1} type="pie" data={{
                                            labels: ['N/A'],
                                            datasets: [
                                                {
                                                    data: [100],
                                                    backgroundColor: '#c3bfbf',
                                                    borderWidth: 0
                                                },
                                            ],
                                        }} options={{
                                            ...pieOptions, tooltips: {
                                                enabled: false
                                            }
                                        }}/>

                                        <FormButton
                                            size={'small'}
                                            variant={'secondary'}
                                            className={'my-3'}
                                            disabled={true}
                                            text={translate('misc.more')}
                                            onClick={() =>{}}/>

                                    </div>
                                </Col>);
                            }

                            return (<Col lg={4} key={key} className="chart-col">
                                <div className="pie-container">
                                    <h5 className="chart-pie-title">{item.name}</h5>
                                    <CustomChart width={1} height={1} type="pie2" plugins={[ChartDataLabels]} data={{
                                        labels: ['Negative', 'Positive'],
                                        datasets: [
                                            {
                                                data: [
                                                    parseFloat(item.negative).toFixed(2),
                                                    parseFloat(item.positive).toFixed(2)
                                                ],
                                                backgroundColor: colors[key][0],
                                                hoverBackgroundColor: colors[key][0],
                                                borderColor: colors[key][0],
                                                hoverBorderColor: colors[key][0],
                                                borderWidth: 2,
                                                displacements: [0, 8],
                                            },
                                        ],
                                    }} options={pieOptions}/>
                                    <FormButton
                                        size={'small'}
                                        className={'my-3'}
                                        variant={'secondary'}
                                        text={translate('misc.more')}
                                        onClick={openMoreModal(item, key)}/>
                                </div>
                            </Col>)

                        })}
                    </Col>
                </div>}

            <BootstrapModal isOpen={modal} toggle={toggleModal}
                            className="general-modal modal-upload horizontal-bar-modal">
                <BootstrapModalBody>
                    <div className="container-fluid px-0">
                        <Row className={'invalid-negative-margin mx-0'}>
                            <Col sm={12} className='px-4 bg-white'>
                                <HorizontalBarChart item={item}
                                                    drug_tested_id={item.drug_id}
                                                    showHeader={true}
                                                    params={params}

                                />
                                <div className={`${globalState.responsive.isDesktop ? 'text-center' : ""} bg-white pb-5 pt-3`}>
                                    <FormButton
                                        size={!globalState.responsive.isDesktop ?  'xl' : 'xxl'}
                                        fluid={!globalState.responsive.isDesktop}
                                        className={'w-450'}
                                        variant={!globalState.responsive.isDesktop ? 'secondary' : "primary"}
                                        text={translate('misc.close')}
                                        onClick={toggleModal}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </BootstrapModalBody>
            </BootstrapModal>
        </div>
    );
};

export default CurrentResultsCharts;