import React, {useContext, useEffect, useRef, useState} from 'react';
import Layout from "../Misc/Layout";
import {translate} from "../../libs/trans";
import {Col, FormFeedback, FormGroup, Row} from "reactstrap";
import Table from "../Reusable/Table";
import Http from "../../libs/Http";
import TableHover from "../Reusable/TableHover";
import {useAlert} from "react-alert";
import striptags from "striptags";
import CustomInput from "../Reusable/CustomInput";
import Modal from "../Reusable/Modal";
import ReactSummernote from "react-summernote";
import $ from 'jquery';
import context from "../../context/context";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH} from "@fortawesome/free-solid-svg-icons";

const Templates = () => {

    const initialState = {
        item: {}
    };

    const {state: globalState} = useContext(context);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [state, setState] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [forceHover, setForceHover] = useState(false);

    const alert = useAlert();
    const wrapperRef = useRef();
    const [refreshTable, doRefresh] = useState(0);
    const refreshTableResults = () => doRefresh(i => ++i);

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideMenu, true);
        return () => document.removeEventListener('click', handleClickOutsideMenu, true);
    }, []);

    const handleClickOutsideMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target))
            setForceHover(false);
    };

    useEffect(() => {
        document.addEventListener('click', _handleClickOutside, true);
        return () => document.removeEventListener('click', _handleClickOutside, true);
    }, []);

    const _handleClickOutside = (event) => {
        if (document.querySelector("div.summernote .dropdown-menu.show")){
            document.querySelector("div.summernote .dropdown-menu.show").classList.remove('show');
        }

        if (event.target.closest('.btn-group')){
            if(event.target.closest('.btn-group').querySelector('.dropdown-menu') !== null){
                event.target.closest('.btn-group').querySelector('.dropdown-menu').classList.add('show');
            }
        }

    };

    const getTemplates = params => Http.get('/templates', params);

    const _toggleModal = () => {
        setState(prevState => ({...prevState, modal:!prevState.modal}));
    };

    const _openEditModal = (item) => {
        setErrors({});
        setState(prevState => ({...prevState, item}));
        _toggleModal();
    };

    const _handleChange = (name, value) => {
        setState(prevState => ({...prevState, item: {...prevState.item, [name]: value}}));
    };

    const _updateTemplate = async () => {

        if (isLoadingModal) return false;

        setIsLoadingModal(true);

        let res = await Http.patch('/templates/' + state.item.id, {...state.item});

        setIsLoadingModal(false);

        if (res.hasError) {
            setErrors(res.error);
        } else {
            alert.show(translate('notifications.update_template'), {type: 'success'});

            _toggleModal();
            refreshTableResults();
        }

    };

    const suspendItem = async params => {
        setIsLoading(true);
        const res = await Http.patch('/templates/' + params.id + '/toggle-suspend');
        setIsLoading(false);
        if (res.hasError) {
            alert.show(res.error.general, {type: 'error'});
        } else {
            if (res.data.suspended) {
                alert.show(translate('notifications.suspend_template'), {type: 'success'});
            } else {
                alert.show(translate('notifications.unsuspend_template'), {type: 'success'});
            }

        }
        refreshTableResults();
    };

    const Tags = (tags) =>  function (context) {
        let ui = $.summernote.ui;

        let button = ui.buttonGroup([
            ui.button({
                className: 'dropdown-toggle',
                contents: 'Tags <span class="note-icon-caret"/>',
                tooltip: translate("misc.tags"),
                data: {
                    toggle: 'dropdown'
                },
                click: function() {
                    context.invoke('editor.saveRange');
                }
            }),
            ui.dropdown({
                className: 'drop-default summernote-custom-list',
                contents: `<ul>
                        ${tags.map(item =>`<li value="{${item}}">
                            ${item.replace(/_/g, ' ')}
                        </li>`).join('')}
                    </ul>`,
                callback: function ($dropdown) {
                    $dropdown.find('li').each(function () {
                        $(this).click(function () {
                            context.invoke('editor.restoreRange');
                            context.invoke('editor.focus');
                            context.invoke('editor.insertText', ' ' + $(this).attr('value')+' ');
                        });
                    });
                }
            })
        ]);

        return button.render();
    };

    const onInit = (name, value) => (note) => {
        note.reset()

        const regex = /(<\w*)((\s\/>)|(.*<\/\w*>))/i

        if (value.match(regex) !== null) {
            note.replace(value)
        } else {
            note.insertText(value)
        }

        setState(prevState => ({...prevState, item: {...prevState.item, [name]: value}}));
    }

    const columns = [
        {
            colSize: 5,
            text: '#',
            name: 'id',
            sortable: true,
            colSizeTablet: 10,
            colSizeMobile: 10,
        },
        {
            colSize: 10,
            text: 'Sent to',
            name: 'to',
            sortable: true
        },
        {
            colSize: 15,
            text: translate('templates.title'),
            name: 'title',
            sortable: true,
            colSizeTablet: 40,
            colSizeMobile: 70,
        },
        {
            colSize: 20,
            text: translate('templates.subject'),
            name: 'subject',
            colSizeTablet: 20,
        },
        {
            colSize: 41,
            text: translate('templates.email'),
            name: 'email'
        },
        {
            colSize: 8,
            customClass: "d-flex justify-content-center",
            text: translate('misc.status'),
            colSizeTablet: 20,
            colSizeMobile: 10,
        },
        {
            colSize: 1,
            text: '',
            colSizeTablet: 5,
            colSizeMobile: 5,
        }
    ];

    const {item} = state;

    return (

        <>
            <div className="table-container">
                <div className={'table-content add-users'}>
                    <Table columns={columns}
                           isLoading={isLoading}
                           sortOrder={'asc'}
                           getItems={getTemplates}
                           setItems={setTemplates}
                           refreshTable={refreshTable}>
                        {!!templates.length && templates.map((template, key) => {
                            return (
                                <div className={`table-row-item row m-0 ${forceHover === key ? 'force-hover' : ''}`} key={key}>
                                    <div>{template.id}</div>
                                    <div>{template.to}</div>
                                    <div className="ellipsis pr-3" onClick={() => _openEditModal(template)}>{template.title}</div>
                                    <div className="ellipsis pr-3">{template.subject}</div>
                                    <div className="ellipsis pr-3">{striptags(template.email_content).replace(/&nbsp;/g, ' ')}</div>
                                    <div className={'d-flex justify-content-center'}>
                                        <span className={`${template.suspended ? 'status-red' : 'status-green'}`}/>
                                    </div>
                                    <div className="justify-content-center">
                                        {globalState.responsive.width <= 1024 && <div className={'table-row-item dots force-hover'}>
                                            <div className={'table-row-icons dots-icon'}>
                                                <div className="table-row-icon" onClick={() => setForceHover(key)}>
                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                </div>
                                            </div>
                                        </div>}
                                    </div>

                                    <div ref={wrapperRef}>
                                        <TableHover
                                            idSuffix={key + 'templates'}
                                            onEdit={_openEditModal}
                                            onLock={suspendItem}
                                            item={template}
                                            hover={{
                                                edit: true,
                                                lock: true
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </Table>
                </div>
            </div>

            {state.modal && <Modal modal={true} _toggleModal={_toggleModal} className={'bd-my-modal modal-right modal-w-500'}
                                   title={translate('templates.edit')}
                                   confirmText={translate('misc.update')}
                                   onAction={_updateTemplate}
                                   buttonSize={'xl'}
                                   buttonFluid={true}
                                   isLoading={isLoadingModal}
                                   hideCancelButton={true}>
                <Row noGutters={true} className={'iteam-modal-row'}>
                    <Col xs={12}>
                        <CustomInput
                            type="text"
                            className="mb-3"
                            label={translate('templates.title')}
                            name="title"
                            required={true}
                            value={item.title}
                            onChange={_handleChange}
                            error={errors.title}
                        />
                        <CustomInput
                            type="text"
                            className="mb-3"
                            label={translate('templates.subject')}
                            name="subject"
                            required={true}
                            value={item.subject}
                            onChange={_handleChange}
                            error={errors.subject}
                        />
                        <FormGroup>
                            {errors?.email_content && <FormFeedback>{errors.email_content}</FormFeedback>}
                            <div className={`summernote ${errors.email_content ? 'invalid-feedback mt-1' : ''}`}>
                                <label htmlFor="email_content">{translate('templates.email')}</label>
                                <ReactSummernote
                                    onInit={onInit('email_content', item.email_content)}
                                    className="mb-3"
                                    options={{
                                        height: 200,
                                        dialogsInBody: true,
                                        toolbar: [
                                            ['style', ['style']],
                                            ['color', ['color']],
                                            ['font', ['bold', 'italic', 'underline', 'clear']],
                                            ['para', ['paragraph', 'ul', 'ol']],
                                            ['tags', ['tags']]
                                        ],
                                        buttons: {
                                            tags: Tags(item.tags)
                                        },
                                        popover: false
                                    }}
                                    onChange={value => _handleChange('email_content', value)}
                                />
                            </div>
                        </FormGroup>

                        {item.to === 'Admin' && <CustomInput
                            type="text"
                            className="mb-3"
                            label={"Email Notification To"}
                            name="notified_email"
                            required={true}
                            value={item.notified_email}
                            onChange={_handleChange}
                            error={errors.notified_email}
                        />}

                        {/*<div className={`summernote ${errors.sms_content ? 'invalid-feedback mt-1' : ''}`}>*/}
                        {/*    <label htmlFor="sms_content">{translate('templates.sms')}</label>*/}
                        {/*    <ReactSummernote*/}
                        {/*        onInit={onInit('sms_content', item.sms_content)}*/}
                        {/*        className="mb-3"*/}
                        {/*        options={{*/}
                        {/*            height: 100,*/}
                        {/*            dialogsInBody: true,*/}
                        {/*            toolbar: [*/}
                        {/*                ['style', ['style']],*/}
                        {/*                ['color', ['color']],*/}
                        {/*                ['font', ['bold', 'italic', 'underline', 'clear']],*/}
                        {/*                ['para', ['paragraph', 'ul', 'ol']],*/}
                        {/*                ['tags', ['tags']]*/}
                        {/*            ],*/}
                        {/*            buttons: {*/}
                        {/*                tags: Tags(item.tags)*/}
                        {/*            },*/}
                        {/*            popover: false*/}
                        {/*        }}*/}
                        {/*        onChange={value => _handleChange('sms_content', value)}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        {/*<div className={'d-flex align-items-center'}>*/}
                        {/*    <div className={'toggle-container'}>*/}
                        {/*        <input id="toggle"*/}
                        {/*               type="checkbox"*/}
                        {/*               checked={!!item.send_sms}*/}
                        {/*               onChange={event => _handleChange('send_sms', event.target.checked)}/>*/}
                        {/*        <label htmlFor="toggle"/>*/}
                        {/*    </div>*/}
                        {/*    <div className={'ml-2'}>{translate('misc.send_sms')}</div>*/}
                        {/*</div>*/}
                    </Col>
                </Row>
            </Modal>}
        </>
    );
};

export default Templates;