import React from 'react';
import {Col, Row} from "reactstrap";
import {translate} from "../../libs/trans";

const NoAccessPage = ({text}) => {
    return (
        <div className="container-fluid behaviour suspended-page-employee pt-3">
            <Row>
                <Col xl={{size: 8, offset: 2}} lg={{size: 10, offset: 1}} md={{size: 10, offset: 1}} sm={12}>
                    <div className="box-content">
                        <div className="suspended-message">
                            <p>{text || translate("misc.no_access")}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default NoAccessPage;