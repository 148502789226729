import React, {Fragment, useEffect, useState} from 'react';
import moment from "moment";
import {FormFeedback, Input as InputBootstrap, Label} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import {faEye, faEyeSlash} from "@fortawesome/free-regular-svg-icons";

const CustomInput = (props) => {

    const initialState = {
        isFocused: (props.value && props.value.toString().length > 0) || props.type === 'select',
        type: props.type || 'text'
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        const {date} = props;

        if (date) {
            const datePicker = document.getElementsByClassName("react-datepicker__input-container");

            for (let dp of datePicker) {
                dp.childNodes[0].setAttribute("readOnly", true);
            }
        }
    }, []);

    useEffect(() => {
        if ((props.value === undefined || props.value?.toString().length === 0 || props.value === null)) {
            setState(prevState => ({...prevState, isFocused: false}))
        }
        if ( props.value && props.value?.toString().length > 0) {
            setState(prevState => ({...prevState, isFocused: true}))
        }
    }, [props]);



    const _changePasswordType = () => {
        const {type} = state;

        let newType = type === 'text' ? 'password' : 'text';

        setState(prevState => ({...prevState, type: newType}))
    };

    const _handleDateChange = (day) => {
        const {name, onChange, time} = props;

        let date;

        if (time) {
            date = moment(day).format('YYYY-MM-DD HH:mm');
        } else {
            date = moment(day).format('YYYY-MM-DD');
        }

        onChange && onChange(name, date);
    };

    const _handleDateTime = (day) => {
        const {name, onChange} = props;
        let date  = moment(day).format('HH:mm');
        onChange && onChange(name, date);
    };

    const _clearDate = () => {
        const {name, onChange} = props;

        onChange && onChange(name, '');

        setState(prevState => ({...prevState, isFocused: false}))
    };

    /**
     * Handle input change
     *
     * @param e
     * @private
     */
    const _handleChange = (e) => {
        let {name, value} = e.target;
        const {onChange, itemKey, uppercase} = props;
        if(uppercase){
            value = value.toUpperCase();
        }
        !itemKey && onChange && onChange(name, value);
        itemKey && onChange && onChange(name, value, itemKey);
    };

    /**
     * Handle input focus
     *
     * @private
     */
     const _handleFocus = () => setState(prevState => ({...prevState, isFocused: true}));

    /**
     * Handle input blur
     *
     * @private
     */
    const _handleBlur = () => {
        const {value, type} = props;
        props.blurAction && props.blurAction();
        if (!value && type !== 'select' && type) {
            setState(prevState => ({...prevState, isFocused: false}))
        }
    };


    const {
        error, label, edit, name, value, icon, iconDirection, date, textColor, time, options,
        placeholder, onKeyPress, width, marginTop, passwordViewer, borderRightNone, borderRadiusLeftNone, disabled,
        futureDate, startDate,required, finishDate,height, onlyTime, parentClass,no_label} = props;
    const {isFocused, type} = state;

    const invalid = error !== undefined && error !== false;

    let styled = {};
    let iconClasses;

    const containerClasses = [
        "custom-input-container",
        ...(invalid ? ["custom-input-adjust"] : []),
        ...(borderRightNone ? ["custom-input-container-border-none"] : []),
        ...(edit ? ["edit"] : [])
    ].join(" ");

    const labelClasses = [
        "custom-label",
        ...(isFocused ? ["input-focus"] : []),
        ...(type === 'textarea' && isFocused === true ? ["input-textarea-focus"] : []),
        ...(type === 'textarea' && isFocused !== true ? ["custom-label-textarea"] : []),
        ...(icon && iconDirection === 'left' ? ["with-icon"] : []),
        ...(no_label ? ["d-none"] : []),
    ].join(" ");

    const classes = [
        "custom-input",
        props.className,
        ...(icon ? ["has-icon"] : []),
        ...(icon && iconDirection === 'left' ? ["icon-left"] : []),
        ...(type === 'select' ? ["left-adjust"] : []),
        ...(!label ? ["no-label"] : []),
        ...(time ? ["time-container"] : []),
        ...(borderRadiusLeftNone ? ["border-radius-left-none"] : []),
    ].join(" ");

    if (icon || passwordViewer) {
        iconClasses = [
            "custom-icon",
            ...(iconDirection === 'left' ? ["icon-left"] : []),
            ...(invalid ? ["icon-invalid"] : []),
            ...(props.biggerIcon ? ["bigger-icon"] : []),
        ].join(" ");
    }

    if (textColor) {
        styled.color = textColor;
    }

    if (width) {
        styled.width = width;
    }

    if (height) {
        styled.height = height;
    }

    if (marginTop) {
        styled.marginTop = marginTop;
    }

    styled = {...styled, ...props.style};


    return (
        <div className={`position-relative ${parentClass  ? parentClass : ''}`}>
            {error && <FormFeedback>{error}</FormFeedback>}

            <div className={containerClasses}>

                {label && <Label className={`${labelClasses} ${error ? 'error' : ''}`}>
                    {label}
                    {required && <span className={'field-required'}>*</span>}</Label>}

                {date &&
                <Fragment>
                    {value && !props.biggerIcon && <div onClick={_clearDate} className={'custom-input-date-icon'}><FontAwesomeIcon icon={faTimes} /></div>}
                    <DatePicker
                        {...(time ? {
                            dateFormat: "DD.MM.YYYY HH:mm"
                        } : {
                            dateFormat: props.dateFormat ? props.dateFormat : "DD.MM.YYYY"
                        })}
                        className={classes}
                        customInput={<InputBootstrap style={styled}
                                                     label={label}
                                                     type={type}
                                                     {...(invalid ? {
                                                         invalid: true
                                                     } : {})}
                        />}
                        {...(value !== '' ? {
                                selected: moment(value, 'YYYY-MM-DD HH:mm')
                            } : {}
                        )}
                        {...(invalid ? {
                            invalid: true
                        } : {})}
                        onChange={_handleDateChange}
                        dropdownMode="scroll"

                        {...(futureDate ?
                                {minDate:moment(new Date(), 'YYYY-MM-DD')} :
                                {}
                        )}

                        {...(startDate ?
                                {minDate:moment(startDate, 'YYYY-MM-DD')} :
                                {}
                        )}

                        {...(finishDate ?
                                {maxDate:moment(finishDate, 'YYYY-MM-DD')} :
                                {}
                        )}
                        dateFormatCalendar={'MMMM'}
                        showYearDropdown
                        onFocus={_handleFocus}
                        onBlur={_handleBlur}
                        disabled={disabled}
                        {...(time ? {
                            showTimeSelect: time,
                            timeFormat: "HH:mm"
                        } : {})}
                    />
                </Fragment>
                }
                {onlyTime &&
                <Fragment>
                    {value && <div onClick={_clearDate} className={'custom-input-date-icon'}><FontAwesomeIcon icon={faTimes} /></div>}
                    <DatePicker
                        className={classes}
                        customInput={<InputBootstrap style={styled}
                                                     label={label}
                                                     type={type}
                                                     {...(invalid ? {
                                                         invalid: true
                                                     } : {})}
                        />}
                        {...(value !== '' ? {
                                selected: moment(value, 'HH:mm')
                            } : {}
                        )}
                        {...(invalid ? {
                            invalid: true
                        } : {})}
                        onChange={_handleDateTime}
                        dropdownMode="scroll"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        timeCaption="Time"
                        dateFormat="h:mm a"
                        onFocus={_handleFocus}
                        onBlur={_handleBlur}
                        disabled={disabled}
                    />
                </Fragment>
                }
                {!date && !onlyTime && <InputBootstrap className={classes}
                                                       style={styled}
                                                       id={props.id}
                                                       label={label}
                                                       name={name}
                                                       type={type}
                                                       min={props.min}
                                                       maxLength={props.maxLength}
                                                       value={value || ''}
                                                       disabled={disabled}
                                                       readOnly={props.readonly}
                                                       onFocus={_handleFocus}
                                                       onBlur={_handleBlur}
                                                       onChange={_handleChange}
                                                       onClick={props.onClick}
                                                       placeholder={placeholder}
                                                       onKeyPress={onKeyPress}
                                                       {...(invalid ? {
                                                           invalid: true
                                                       } : {})}
                                                       autoComplete={props.autocomplete || 'off'}>
                    {options && options.map((value, key) => {
                        return <option key={key} value={value.id}>{value.name}</option>;
                    })}
                </InputBootstrap>}
                {icon && <FontAwesomeIcon icon={icon} className={iconClasses} />}
                {passwordViewer &&
                <FontAwesomeIcon icon={type === 'password' ? faEye : faEyeSlash } className={iconClasses}
                                 onClick={_changePasswordType}/>}
            </div>
        </div>
    );
};

export default CustomInput;