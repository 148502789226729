import React, {useContext} from 'react';
import context from "../../context/context";
import {Route} from 'react-router';
import NoAccessPage from "./NoAccessPage";

const ProtectedRoute = ({component: Component, permission, ...rest}) => {

    const { state: globalState } = useContext(context);

    if (!globalState.user || (globalState.user && permission && !globalState.user.can(permission))) {
        return <NoAccessPage />;
    }

    return <Route render={() => (<Component {...rest} />)} {...rest} />;
};

export default ProtectedRoute;
