import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {Link, NavLink} from "react-router-dom";

const MenuItem = ({text, textColor, icon, userIcon, dropDown, sidebarOpen, content, href, openSidebar, tooltip, exact, bold}) => {

    const [showContent, setShowContent] = useState(false);

    let style = {};
    if (textColor) {
        style.textColor = textColor;
    }

    let menuItemClass = 'menu-item';
    if ( openSidebar && content)
        menuItemClass += ' menu-item-toggeable-dropdown';

    const urlSegments = window.location.pathname.split('/').filter( item => item !== '');

    if (dropDown) {
        let subLinks = [];
        content.map(item => subLinks.push(item.url.replace('/', '')));
        if(subLinks.indexOf(urlSegments[0]) >= 0){
            setShowContent(true);
        }
    }

    return (
        <>
            <div className={menuItemClass} onClick={() => setShowContent(!showContent)}>
                {!dropDown && <NavLink exact={exact || false} to={href}>
                    {icon}
                    {userIcon && <img alt="" src={userIcon} className={'user-icon'}/>}
                    {text && <p style={style} dangerouslySetInnerHTML={{__html: bold ? text.replace(bold, '<b>' + bold + '</b>') : text}} />}
                </NavLink>}
                {dropDown && <div>
                    {icon}
                    {userIcon && <img alt="" src={userIcon} className={'user-icon'}/>}
                    {(text && sidebarOpen) && <p style={style}>{text}</p>}
                    <FontAwesomeIcon icon={showContent ? faAngleUp : faAngleDown} className={'menu-item-caret'}/>
                </div>}
                {tooltip}
            </div>
            {content && showContent && openSidebar &&
            <div className={'menu-item-content-container'}>
                {content && content.map((item, key) =>
                    <div key={key} className={'menu-item-content ' + (urlSegments[0] === item.url.replace('/', '') && 'active')}>
                        <Link to={item.url}>{item.name}</Link>
                    </div>)}
            </div>}
        </>
    );
};

export default MenuItem;

