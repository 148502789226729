import React, {useEffect, useState} from 'react';
import Layout from "../../Misc/Layout";
import Http from "../../../libs/Http";
import {useAlert} from "react-alert";
import {translate} from "../../../libs/trans";

const SaleDashboard = () => {

    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const alert = useAlert();



    useEffect(() => {
        getResults()
    }, []);


    const getResults = async () => {

        setIsLoading(true);

        const res = await Http.get('/dashboard');

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            setResults(res.data);
        }

        setIsLoading(false);
    };


    return (
        <Layout loading={isLoading}>
            <div className={'current-page-details'}>
                {<h3>{translate('sidebar.dashboard')}</h3>}
            </div>

        </Layout>
    );
};

export default SaleDashboard;