import React, {Fragment, useEffect, useState} from 'react';
import {Alert} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {translate} from "../../libs/trans";
import {ALERT_TIME} from "../../constants";

const SuccessAlert = (props) => {

    const [visible, setVisible] = useState( false);

    useEffect(() => {
        if(props.visible){
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
            }, ALERT_TIME);
        }
    },[])

     const alertClasses = [
        props.className,
        ...(props.type === 'warning' ? ["alert-warning"] : ["alert-success"]),
        ...(visible ? ["alert-shown"] : []),
        ...(!visible ? ["alert-hidden"] : []),
    ].join(" ");

    const {customText} = props;

    return (
        <Fragment>
            {visible &&
            <Alert color="success" className={alertClasses}>
                <div className={'success-body'}>
                    <FontAwesomeIcon className="mr-2" icon={faCheck}/>
                    {!customText && translate('misc.success')}
                    {customText && customText}
                </div>
            </Alert>
            }
        </Fragment>
    );
};

export default SuccessAlert;