import  { createContext } from 'react';
import {DEFAULT_LANG} from "../constants";

const context = createContext({
    user: null,
    lang: localStorage.getItem("lang") || DEFAULT_LANG ,
    isLoading: false,
    headerLoading: false,
    responsive: {
        sidebarOpen: true,
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth <= 768,
        isTablet: window.innerWidth > 768 && window.innerWidth <= 1024,
        isDesktop: window.innerWidth > 1024,
    },
    collapsed: {
    },
    modalDelete: {
        open: false,
        action: null
    }
});

export default context;