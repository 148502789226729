import React, {useContext, useEffect, useState} from 'react';
import Layout from "../Misc/Layout";
import {translate} from "../../libs/trans";
import {Col, Row} from "reactstrap";
import Http from "../../libs/Http";
import Pagination from "../Reusable/Pagination";
import {plainText, scrollToTop} from "../../libs/helpers";
import CustomSelect from "../Reusable/CustomSelect";
import {DEFAULT_LANGUAGE, LANGUAGES} from "../../constants";
import Modal from "../Reusable/Modal";
import ReactSummernote from "react-summernote";
import {useAlert} from "react-alert";
import context from "../../context/context";


const ReportTemplate = (props) => {

    const initialState = {
        languages: LANGUAGES,
        modal: false,
        item: {}
    };

    const { state: globalState } = useContext(context);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [template, setTemplate] = useState({pages: [], blocks: []});
    const [currentPage, setCurrentPage] = useState(0);
    const [lang, setLang] = useState(DEFAULT_LANGUAGE);
    const [state, setState] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [templateA4Style, setTemplateA4Style] = useState({});
    const [templateA4ContainerStyle, setTemplateA4ContainerStyle] = useState({});
    const alert = useAlert();
    const {type = 'vad'} = props;

    useEffect(() => {
        _getTemplate();
    }, [lang, type]);

    useEffect(() => {
        let templateA4StyleTemp = JSON.parse(JSON.stringify(templateA4Style));
        let templateA4ContainerStyleTemp = JSON.parse(JSON.stringify(templateA4ContainerStyle));
        if (globalState.responsive.width <= 1480 && globalState.responsive.width >= 320) {
            let scale;
            if(globalState.responsive.sidebarOpen && globalState.responsive.width >= 1025){
                scale = 0.000517241 * (globalState.responsive.width - 250) +  0.234483
            }else{
                if(globalState.responsive.width <= 991){
                    scale = 0.00126398 * globalState.responsive.width - 0.119474;
                }else{
                    scale = 0.000517241 * globalState.responsive.width +  0.234483
                }
            }
            templateA4StyleTemp.transform = `scale(${scale > 1 ? 1 : scale})`;
            templateA4ContainerStyleTemp.height = `${scale * 1122}px`;
        } else {
            templateA4StyleTemp = {};
            templateA4ContainerStyleTemp = {};
        }
        setTemplateA4Style(templateA4StyleTemp);
        setTemplateA4ContainerStyle(templateA4ContainerStyleTemp);
    }, [globalState.responsive.width,globalState.responsive.sidebarOpen]);

    const _getTemplate = async () => {
        setIsLoading(true);
        const res = await Http.get(`/reports${type === 'sm' ? '/sm' : ''}`,{lang});
        if (!res.hasError) {
            setTemplate(res.data);
        }
        setIsLoading(false);
    };

    const onInit = (name, value) => (note) => {
        note.reset()

        const regex = /(<\w*)((\s\/>)|(.*<\/\w*>))/i

        if (value.match(regex) !== null) {
            note.replace(value)
        } else {
            note.insertText(value)
        }

        setState(prevState => ({...prevState, item: {...prevState.item, [name]: value}}));
    };

    const _handleChangePage = (page) => _ => {
        scrollToTop();
        setCurrentPage(page);
    };
    const _toggleModal = () => {
        setState(prevState => ({...prevState, modal: !prevState.modal}));
    };

    const _openEditModal = (item) => _ => {
        setState(prevState => ({...prevState, item}));
        _toggleModal();
    };

    const _handleChange = (name, value) => {
        setState(prevState => ({...prevState, item:{...prevState.item, [name]:value}}));
    };

    const _handleSave = async() => {

        setIsLoadingModal(true);
        const res = await Http.patch(`/reports/${state.item.id}`,state.item);
        if (res.hasError) {
            setErrors(res.error)
        }else{
            _getTemplate();
        }
        setIsLoadingModal(false);
        _toggleModal();
        alert.show(translate('notifications.update_section'), {type: 'success'});
    };

    return (
        <Layout loading={isLoading}>
            {!isLoading && <>
                <div className={'current-page-details justify-content-between d-flex align-items-center'}>
                    {<h3>{translate('sidebar.report_templates')}</h3>}
                    <Col sm={5} md={4} lg={3} xl={2} className="d-flex justify-content-end mt-3 mt-sm-0">
                        <div className="blue-bg-select2">
                            <CustomSelect
                                label={translate("misc.lang_title")}
                                onChange={(_, value) => setLang(value)}
                                value={lang}
                                options={state.languages}
                            />
                        </div>
                    </Col>
                </div>

                <div className="table-container">
                    <Row className="table-content">

                        <Col sm={12} lg={8} md={12} className="mt-3">
                            <div className="d-flex justify-content-center container-template-a4-auto-height"
                                 style={templateA4ContainerStyle}>
                                <div className="template-a4" style={templateA4Style}>
                                    <div className="pdf-page">
                                        {template.pages[currentPage] &&
                                        <div dangerouslySetInnerHTML={{__html: template.pages[currentPage].html}}/>}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <Pagination pages={template.pages.length} currentPage={currentPage + 1}
                                            onChange={(page) => _handleChangePage(page - 1)()}/>
                            </div>
                        </Col>

                        <Col sm={12} md={12} lg={4} className="mt-3">

                            <div className="template-pages">

                                <h3>{translate("report_template.pages")}</h3>

                                <div className="table-header">
                                    <div className="w-col-10 pl-2 "><span>#</span></div>
                                    <div className="w-col-70 "><span>{translate("report_template.page_name")}</span>
                                    </div>
                                    {/*<div className="w-col-20 d-flex justify-content-center "><span>Show</span></div>*/}
                                </div>

                                <div className="table-container-items">
                                    {template.pages.map((item, key) => {
                                        return (
                                            <div key={key}
                                                 className={`table-row-item py-2 px-1 ${(currentPage === key ? 'text-bold' : '')}`}>
                                                <div className="row m-0">
                                                    <div onClick={_handleChangePage(key)}
                                                         className="table-single-item w-col-10 pl-2">{key + 1}</div>
                                                    <div onClick={_handleChangePage(key)}
                                                         className="table-single-item w-col-70">{item.name}</div>
                                                    {/*<div className="table-single-item w-col-20 d-flex justify-content-center">*/}
                                                    {/*    <FontAwesomeIcon icon={item.show ? faCheckSquare : faSquare} />*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>


                            <div className="template-pages pb-4">
                                <h4 className={'pt-3 ml-4'}>{translate("report_template.editable_sections")}</h4>
                                <div className="table-header">
                                    <div className="w-col-10 pl-2 "><span>#</span></div>
                                    <div className="w-col-70 "><span>{translate("report_template.section_name")}</span>
                                    </div>
                                </div>
                                <div className="table-container-items overflow-visible ">
                                    {template.blocks.filter(i => i.page_id === template.pages[currentPage].id).map((block, key) =>
                                        <div key={key} className={`table-row-item py-2 px-1`}>
                                            <div className="row m-0" onClick={_openEditModal(block)}>
                                                <div onClick={_handleChangePage(key)}
                                                     className="table-single-item w-col-10 pl-2">{key + 1}</div>
                                                <div className="table-single-item w-col-50">{block.name}</div>
                                            </div>
                                        </div>
                                    )}

                                </div>

                                {/*<Row>*/}
                                {/*<Col className="my-3 text-center">*/}
                                {/*<Button onClick={_handleSave}*/}
                                {/*fontSize={15}*/}
                                {/*height={48}*/}
                                {/*text={translate('misc.save')}*/}
                                {/*textColor={'white'}*/}
                                {/*backgroundColor={'green'}*/}
                                {/*border={'green'}*/}
                                {/*/>*/}
                                {/*</Col>*/}
                                {/*</Row>*/}

                            </div>

                        </Col>


                    </Row>
                </div>

                <Modal modal={state.modal} _toggleModal={_toggleModal} className={'bd-my-modal modal-right modal-w-500'}
                       title={translate('misc.update') + ' ' + state?.item?.name}
                       confirmText={translate('misc.update')}
                       onAction={_handleSave}
                       isLoading={isLoadingModal}
                       hideCancelButton={true}>
                    <Row noGutters={true} className={'iteam-modal-row'}>
                        <Col xs={12}>

                            <div className={`summernote w-100 ${errors?.value ? 'invalid-feedback mt-1' : ''}`}>
                                <label htmlFor="value">{translate("misc.text")}</label>
                                <ReactSummernote
                                    onInit={onInit('value', state.item?.value || '')}
                                    key={lang}
                                    onPaste={plainText}
                                    options={{
                                        height: 350,
                                        dialogsInBody: true,
                                        toolbar: [
                                            ['color', ['color']],
                                            ['font', ['bold', 'italic', 'underline', 'clear']],
                                            ['para', ['paragraph', 'ul', 'ol']],
                                            ['tags', ['tags']]
                                        ],
                                        popover: false
                                    }}
                                    onChange={value => _handleChange('value', value)}
                                />
                            </div>
                        </Col>
                    </Row>
                </Modal>

            </>}

        </Layout>
    );
};

export default ReportTemplate;