import React, {useState} from 'react';
import {
    Col,
    Modal as BootstrapModal,
    ModalBody as BootstrapModalBody,
    ModalHeader as BootstrapModalHeader,
    Row
} from "reactstrap";
import CustomInput from "../Reusable/CustomInput";
import {getTransValues, translate} from "../../libs/trans";
import Http from "../../libs/Http";
import {useAlert} from "react-alert";
import CustomSelect from "../Reusable/CustomSelect";
import {FormButton} from "../Reusable/FormComponents";


const AddOrEditDropdownModal = (props) => {

    const [item, setItem] = useState(props.item || {});
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const alert = useAlert();

    /**
     * Handle Change Item
     * @param name
     * @param value
     * @private
     */
    const _handleChange = (name, value) => {
        setItem(prevState => ({...prevState, [name]:value}));
    };



    /**
     * Handle Save
     * @returns {Promise<boolean>}
     * @private
     */
    const _handleSave = async () => {

        if (isLoading) return false;

        setIsLoading(true);

        const res = await Http.post("/dropdowns/save", {...item, ...(props.parent_id ? {parent_id: props.parent_id} : {})});

        if (res.hasError) {
            setErrors(res.error);
        } else {
            setErrors({});
            setItem({});
            if (item.id) {
                alert.show(translate('notifications.update_option'), {type: 'success'});
            } else {
                alert.show(translate('notifications.created_option'), {type: 'success'});
            }
            props.toggleModal();
            props.refreshTableResults()
        }

        setIsLoading(false);
    };



    const {toggleModal} = props;

    return (
        <BootstrapModal isOpen={true} toggle={toggleModal}
                        className="general-modal modal-upload">
            <BootstrapModalHeader toggle={toggleModal} className={'mx-3'}>
                {translate(item.hasOwnProperty('id') ? 'dropdowns.edit' : 'dropdowns.add')}
            </BootstrapModalHeader>
            <BootstrapModalBody>
                <div className="container-fluid">
                    <Row className={'invalid-negative-margin'}>
                        <Col sm={!props.parent_id ? 6 : 12}>
                            <CustomInput
                                type="text"
                                className="mb-4"
                                label={translate("dropdowns.option_name")}
                                name="name"
                                required={true}
                                value={item.name}
                                onChange={_handleChange}
                                error={errors.name}
                            />
                        </Col>
                        {!props.parent_id && <Col sm={6}>
                            <CustomSelect
                                type="text"
                                className="mb-4"
                                label={translate("dropdowns.dropdown")}
                                options={getTransValues('dropdown_categories')}
                                name="dropdown"
                                disabled={!!item.id}
                                required={true}
                                value={item.dropdown}
                                onChange={_handleChange}
                                error={errors.dropdown}
                            />
                        </Col>}

                        <Col sm={12} className="text-center my-3">
                            <FormButton
                                size={'large'}
                                variant={'primary'}
                                text={translate(item.hasOwnProperty('id') ? 'misc.update' : "misc.save")}
                                isLoading={isLoading}
                                onClick={_handleSave}/>
                        </Col>
                    </Row>
                </div>
            </BootstrapModalBody>
        </BootstrapModal>
    );
};

export default AddOrEditDropdownModal;
