import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './resources/styles/app.scss';
import {Provider as AlertProvider} from "react-alert";
import {ALERT_OPTIONS} from "./constants";
import AlertTemplate from "./components/Misc/AlertTemplate";

window.logoutTimeout = null;
window.echo = null;

ReactDOM.render(
    <React.StrictMode>
        <AlertProvider template={AlertTemplate} {...ALERT_OPTIONS}>
            <App/>
        </AlertProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
