import React from 'react';
import {Input} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

const SearchInput = (props) => {

    const _handleChange = (e) => {
        const {name, value} = e.target;
        const {onChange} = props;
        onChange && onChange(name, value);
    };

    const {name, value, borderRightNone, placeholder, onChange, height, ...others} = props;

    let containerClasses = 'custom-input-container';
    if ( borderRightNone ) {
        containerClasses += 'custom-input-container-border-right-none';
    }

    return (
        <div className={containerClasses} {...others}>
            <Input className="custom-search-input"
                            name={name}
                            style={{...height ? {height} : {}}}
                            defaultValue={value}
                            onChange={_handleChange}
                            placeholder={placeholder}
                            autoComplete={'off'}>
            </Input>
            <FontAwesomeIcon icon={faSearch} className={'custom-search-icon'}/>
        </div>
    );
};

export default SearchInput;

