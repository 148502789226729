export default function reducer(state, {type, payload}) {
    switch (type) {
        case "SET_USER":
            return {...state, user: payload};
        case "SET_LANGUAGE":
            return {...state, lang: payload};
        case "SET_GLOBAL_LOADING":
            return {...state, isLoading: payload};
        case "SET_HEADER_LOADING":
            return {...state, headerLoading: payload};
        case "TOGGLE_SIDEBAR":
            return {
                ...state, responsive: {
                    ...state.responsive,
                    sidebarOpen: payload
                }
            };
        case "SET_NOTIFICATION_MESSAGE":
            return {...state, notificationMessage: payload};
        case "MENU_COLLAPSE":
            return {...state, collapsed: payload};
        case "UPDATE_SCREEN_SIZE":
            return {
                ...state, responsive: {
                    ...state.responsive,
                    width: window.innerWidth,
                    height: window.innerHeight,
                    isMobile: window.innerWidth <= 768,
                    isTablet: window.innerWidth > 768 && window.innerWidth <= 1024,
                    isDesktop: window.innerWidth > 1024
                }
            };
        case "SET_MODAL_DELETE":
            return {...state, modalDelete: payload};
        case "SET_FILTERS":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload
                }
            };
        case "SET_CLIENTS":
            return {...state, clients: payload};
        default:
            return state;
    }
}
