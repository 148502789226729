import React, {useContext, useState} from 'react';
import context from "../../context/context";
import Modal from "./Modal";
import {translate} from "../../libs/trans";
import Checkbox from "./Checkbox";

const ConfirmModalDelete = ({hideActionButton, hideCancelButton, showAnonymize, children, isLoading, customIcon,
                                customMessage, customLoadingMessage,toggleModalWithSelect,isModalWithSelect,onAction,textRed}) => {

    const {state: globalState, dispatch} = useContext(context);
    const [confirmed, setConfirmed] = useState(false);
    const [anonymize, setAnonymize] = useState(false);

    const _toggleModal = () => {
        dispatch({type: "SET_MODAL_DELETE", payload: {open: false, action: null}})
    };

    const _confirm = () => {
        setConfirmed(true);
    };

    const _setAnonymize = (item) => {
        setAnonymize(item);
    };

    const _callOnAction = async () => {
        _toggleModal();
        await globalState.modalDelete.action({anonymize});
    };


    return (
        <Modal modal={true} _toggleModal={toggleModalWithSelect ? toggleModalWithSelect : _toggleModal}
               confirmText={translate('misc.yes')}
               reverseButtons={confirmed}
               onAction={confirmed ? (isModalWithSelect ? onAction :  _callOnAction) : _confirm}
               className="pk-confirm-modal modal-dialog-centered"
               hideActionButton={hideActionButton}
               hideCancelButton={hideCancelButton}
               isLoading={isLoading}>
            {customIcon ? <div className={'custom-modal-icon'}>{customIcon}</div> :
                <div className={'pk-confirm-icon'}><span>!</span></div>
            }
            {children}
            {customMessage ?
                <p className={`pk-confirm-text ${isLoading && 'delete'}`}>{isLoading ? (customLoadingMessage || translate('misc.loading')) : <span className={`${textRed ? 'red' : ''}`}> {customMessage}</span>}</p> :
                <p className={`pk-confirm-text ${isLoading && 'delete'}`}>{isLoading ? translate('misc.deleting') : translate(confirmed ? 'misc.confirmDeleteRe' : 'misc.confirmDelete')}</p>}

            {showAnonymize && <div className={'d-flex justify-content-center'}>
                <Checkbox {...(anonymize ? {
                    checked: true
                } : {})} onChange={_setAnonymize}/>
                <div className={'ml-2'}><b>{translate('misc.anonymize')}</b></div>
            </div>}
        </Modal>
    );
};

export default ConfirmModalDelete;
