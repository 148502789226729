import React, {useContext, useEffect, useRef, useState} from 'react';
import context from '../../context/context';
import Layout from "../Misc/Layout";
import {translate} from "../../libs/trans";
import {Col, Button as ButtonBootstrap, Row} from "reactstrap";
import Table from "../Reusable/Table";
import Http from "../../libs/Http";
import TableHover from "../Reusable/TableHover";
import Modal from "../Reusable/Modal";
import CustomInput from "../Reusable/CustomInput";
import {
    DEFAULT_PHONE_PREFIX,
    PERMISSION_ADD_ADMIN,
    PERMISSION_IMPERSONATE,
    PERMISSION_VIEW_LOGS,
} from "../../constants";
import CustomPhone from "../Reusable/CustomPhone";
import {useAlert} from "react-alert";
import PhoneJSON from "../../resources/phone";
import {impersonate} from "../../libs/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {FormButton} from "../Reusable/FormComponents";

const AdminUsers = () => {

    const initialState = {
        modalEdit: false,
        modalPrivileges: false,
        item: {
            phone_country: DEFAULT_PHONE_PREFIX
        },
    };

    const { state: globalState } = useContext(context);
    const [state, setState] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [errors, setErrors] = useState({});
    const [forceHover, setForceHover] = useState(false);
    const alert = useAlert();
    const wrapperRef = useRef();

    const [refreshTable, doRefresh] = useState(0);
    const refreshTableResults = () => doRefresh(i => ++i);

    useEffect(() => {
        if (state.modalEdit === false) {
            setState(prevState => ({...prevState, item: {
                    phone_country: DEFAULT_PHONE_PREFIX
                }}));
            setErrors({});
        }
    }, [state.modalEdit]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => document.removeEventListener('click', handleClickOutside, true);
    }, []);

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target))
            setForceHover(false);
    };

    const _handleChange = (name, value) => {
        setState(prevState => ({...prevState, item: {...prevState.item, [name]: value}}));
    };

    const _toggleModal = () => {
        setState(prevState => ({...prevState, modalEdit:!prevState.modalEdit}));
    };

    const _openEditModal = (item) => {

        if (!globalState.user.can(PERMISSION_ADD_ADMIN))
            return false;

        let data = JSON.parse(JSON.stringify(item));

        if (data.phone && data.phone.includes('-')) {
            const arrPhone = data.phone.split('-');
            data.phone = arrPhone[1];
        }
        setState(prevState => ({...prevState, item:data}));
        _toggleModal();
    };

    const getItems = params => Http.get('/users', {type: 'admins', ...params});

    const deleteItem = async params => {
        setIsLoading(true);
        const res = await Http.delete('/user/' + params.id);
        setIsLoading(false);
        if (res.hasError) {
            alert.show(res.error.general, {type: 'error'});
        } else {
            alert.show(translate('notifications.delete_user'), {type: 'success'});

        }
        refreshTableResults();
    };

    const suspendItem = async params => {
        setIsLoading(true);
        const res = await Http.patch('/user/' + params.id + '/toggle-suspend');
        setIsLoading(false);
        if (res.hasError) {
            alert.show(res.error.general, {type: 'error'});
        } else {
            if(res.data.suspended){
                alert.show(translate('notifications.suspend_user'), {type: 'success'});
            }else{
                alert.show(translate('notifications.unsuspend_user'), {type: 'success'});
            }

        }
        refreshTableResults();
    };

    const _save = async () => {

        if (isLoading) return false;

        setIsLoading(true);

        let res;
        let data = JSON.parse(JSON.stringify(state.item));

        if (data.phone) {
            data.phone = `${PhoneJSON[data.phone_country]}-${data.phone}`;
        }

        if (data?.id) {
            res = await Http.patch('/user/admin/' + data.id, data);
        } else {
            res = await Http.post('/user/admin', data);
        }

        setIsLoading(false);

        if (res.hasError) {
            setErrors(res.error);
        } else {
            if (state.item?.id) {
                alert.show(translate('notifications.update_user'), {type: 'success'});
            } else {
                alert.show(translate('notifications.add_user'), {type: 'success'});
            }
            _toggleModal();
            refreshTableResults();
        }

    };

    const _handleChangeToggle = (e) => {

        setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                privileges: {
                    ...prevState.item.privileges,
                    [e.target.name]: e.target.checked ? 1 : 0
                }
            }
        }));
    };

    const _openModalPrivileges = () => {
        let {item} = state;

        let privileges = [
            "save.admin",
            "save.clients"
        ];
        item.privileges = {};

        privileges.map(privilege => {
            return item.privileges[privilege] = item.permissions.indexOf(privilege) < 0 ? 0 : 1;
        });

        setState(prevState => ({...prevState, item}));

        _toggleModalPrivileges();
    };

    const _toggleModalPrivileges = () => {
        setState(prevState => ({...prevState, modalPrivileges:!prevState.modalPrivileges}));
    };

    const _savePrivileges = async () => {

        if (isLoading) return false;

        setIsLoading(true);

        const res = await Http.patch('/user/privileges/' + state.item.id, {
            privileges: state.item.privileges
        });

        if (res.hasError) {
            setErrors(res.error);
        } else {

            refreshTableResults();
            setState(prevState => ({...prevState, modalPrivileges: false, modalEdit: false}));
            alert.show(translate('notifications.update_privileges'), {type: 'success'});
        }

        setIsLoading(false);
    };

    const staticPrivileges = {
        "save.admin": "Create/Edit Admin",
        "save.clients": "Create/Edit Client"
    };

    const columns = [
        {
            colSize: 5,
            text: 'ID',
            name: 'id',
            sortable: true,
            colSizeTablet: 10,
            colSizeMobile: 15,
        },
        {
            colSize: 20,
            text: translate('general.first_name'),
            name: 'first_name',
            sortable: true,
            colSizeTablet: 18,
            colSizeMobile: 60,
        },
        {
            colSize: 20,
            text: translate('general.surname'),
            name: 'last_name',
            colSizeTablet: 17,
        },
        {
            colSize: 25,
            text: translate('general.job_title'),
            name: 'job_title',
            colSizeTablet: 20,
        },
        {
            colSize: 24,
            text: translate('clients.contact_no'),
            name: 'contact',
            colSizeTablet: 20,
        },
        {
            colSize: 5,
            customClass: "d-flex justify-content-center",
            text: translate('misc.status'),
            colSizeTablet: 10,
            colSizeMobile: 10,
        },
        {
            colSize: 1,
            text: '',
            colSizeTablet: 5,
            colSizeMobile: 5,
        }
    ];

    const {item} = state;

    return (
        <>
            <div className="table-container">
                <div className={'table-content add-users'}>
                    <Table columns={columns}
                           getItems={getItems}
                           setItems={setItems}
                           refreshTable={refreshTable}
                           buttonsContainer={globalState.user.can(PERMISSION_ADD_ADMIN) && <FormButton
                               size={'large'}
                               variant={'secondary'}
                               text={"+ " + translate('admin_users.add')}
                               onClick={_toggleModal}>

                           </FormButton>}>
                        {!!items.length && items.map((user, key) => {
                            return (
                                <div className={`table-row-item row m-0 ${forceHover === key ? 'force-hover' : ''}`} key={key}>
                                    <div>{user.id}</div>
                                    <div className="ellipsis" onClick={() =>_openEditModal(user)}>{user.first_name}</div>
                                    <div className="ellipsis" onClick={() => _openEditModal(user)}>{user.last_name}</div>
                                    <div className="ellipsis">{user.job_title}</div>
                                    <div className="ellipsis">{user.phone}</div>
                                    <div className="d-flex justify-content-center">
                                        <span className={`${user.suspended ? 'status-red' : (user.confirmed ? 'status-green' : 'status-orange')}`} />
                                    </div>
                                    <div className="justify-content-center">
                                        {globalState.responsive.width <= 1024 && <div className={'table-row-item dots force-hover'}>
                                            <div className={'table-row-icons dots-icon'}>
                                                <div className="table-row-icon" onClick={() => setForceHover(key)}>
                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                </div>
                                            </div>
                                        </div>}
                                    </div>

                                    <div ref={wrapperRef}>
                                        {globalState.user.can(PERMISSION_ADD_ADMIN) ? <TableHover
                                            idSuffix={key + 'admins'}
                                            onEdit={_openEditModal}
                                            onImpersonate={impersonate}
                                            onDelete={deleteItem}
                                            onLock={suspendItem}
                                            onLogs={() =>{}}
                                            item={user}
                                            hover={{
                                                edit:  globalState.user.id !== user.id,
                                                delete: globalState.user.id !== user.id,
                                                lock:  globalState.user.id !== user.id,
                                                impersonate: globalState.user.can(PERMISSION_IMPERSONATE) && !!user.confirmed && globalState.user.id !== user.id,
                                            }}
                                        /> : <TableHover hover={{}} />}
                                    </div>
                                </div>
                            );
                        })}
                    </Table>
                </div>
            </div>
            {globalState.responsive.width <=1024 && <div className={'outside-button-container pt-4'}>
                <FormButton
                    size={'xl'}
                    className={'w-450'}
                    fluid={true}
                    variant={'secondary'}
                    text={"+ " + translate('admin_users.add')}
                    onClick={_toggleModal}/>
            </div>}

            <Modal modal={state.modalEdit} _toggleModal={_toggleModal} className={'bd-my-modal modal-right'}
                   title={item.id ? translate('admin_users.update') : translate('admin_users.add_admin')}
                   confirmText={item.id ? translate('misc.update') : translate('misc.save')}
                   onAction={_save}
                   buttonSize={'xl'}
                   buttonFluid={true}
                   isLoading={isLoading}
                   hideCancelButton={true}>
                <Row noGutters={true} className={'iteam-modal-row'}>
                    <Col xs={12}>
                        <CustomInput
                            type="text"
                            className="mb-3"
                            label={translate('general.first_name')}
                            name="first_name"
                            required={true}
                            value={item.first_name}
                            onChange={_handleChange}
                            error={errors.first_name}
                        />
                        <CustomInput
                            type="text"
                            className="mb-3"
                            label={translate('general.surname')}
                            name="last_name"
                            required={true}
                            value={item.last_name}
                            onChange={_handleChange}
                            error={errors.last_name}
                        />
                        <CustomInput
                            type="text"
                            className="mb-3"
                            label={translate('general.job_title')}
                            name="job_title"
                            required={true}
                            value={item.job_title}
                            onChange={_handleChange}
                            error={errors.job_title}
                        />

                        <CustomInput
                            type="text"
                            className="mb-3"
                            label={translate('general.email')}
                            name="email"
                            required={true}
                            value={item.email}
                            onChange={_handleChange}
                            error={errors.email}
                        />

                        <CustomPhone
                            className="mb-3"
                            label={translate('general.mobile_no')}
                            valueInput={item.phone || null}
                            valuePrefix={item.phone_country || DEFAULT_PHONE_PREFIX}
                            onChange={_handleChange}
                            error={errors.phone}
                        />

                        {item.id && <FormButton
                            size={'large'}
                            variant={'secondary'}
                            text={translate('misc.privileges')}
                            onClick={_openModalPrivileges}/>}
                    </Col>
                </Row>
            </Modal>

            {state.modalPrivileges && <Modal modal={state.modalPrivileges} _toggleModal={_toggleModalPrivileges} className={'privileges-modal'}
                                             confirmText={translate('misc.save')}
                                             onAction={_savePrivileges}
                                             isLoading={isLoading}
                                             hideCancelButton={true}>
                <div className={'table-privileges'}>
                    <Row className={'table-header-privileges'}>
                        <Col xs={7}>
                            <span>{translate('misc.privileges')}</span>
                        </Col>
                        <Col xs={5}>
                            <span>{translate("misc.on_off")}</span>
                        </Col>
                    </Row>

                    {Object.keys(staticPrivileges).map((privileges_key, key) => {
                        return <Row className={'table-body-privileges'} key={key}>
                            <Col xs={7}>
                                <span>{staticPrivileges[privileges_key]}</span>
                            </Col>
                            <Col xs={5}>
                                <div className={'toggle-container'}>
                                    <input id={privileges_key}
                                           type="checkbox"
                                           key={privileges_key}
                                           name={privileges_key}
                                           checked={!!item?.privileges?.[privileges_key]}
                                           onChange={_handleChangeToggle}/>
                                    <label htmlFor={privileges_key}/>
                                </div>
                            </Col>
                        </Row>;
                    })}
                </div>
            </Modal>}
        </>
    );
};

export default AdminUsers;