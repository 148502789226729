import React, {Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

const HeaderNavItem = (props) => {

    const [showContent, setShowContent] = useState(false);

    const _toggleList = () => {
        setShowContent(!showContent)
    };

    let style = {};

    if (props.textColor) {
        style.textColor = props.textColor;
    }

    let itemClasses = ["menu-item", props.className].join(" ");

    const Icon = props => (
        customIcon ? customIcon : null
    );

    const {text, icon, customIcon, iconType, userIcon, dropDown, content, href, opensModal, popOver} = props;

    return (
        <Fragment>
            <div className={itemClasses}>
                {icon && <FontAwesomeIcon icon={[iconType, icon]}/>}
                {customIcon && <Icon/>}
                {userIcon && <img src={userIcon} alt="avatar" className={'user-icon'}/>}

                {href && <Link to={href} style={style}>{text}</Link>}
                {dropDown && <Fragment>
                    <p onClick={this._toggleList}>{text}</p>
                    <FontAwesomeIcon onClick={_toggleList}
                                     icon={['fas', `angle-${showContent ? 'up' : 'down'}`]}
                                     className={'menu-item-caret'}/>
                </Fragment>}
                {opensModal && <p>{text}</p>}
            </div>
            {showContent && !popOver &&
            <div className={'menu-item-content-container'}>
                {content && content.map((item, key) =>
                    <div key={key} className={'menu-item-content'}>
                        <Link to={'/logout'}>{item.name}</Link>
                    </div>)}
            </div>}
        </Fragment>
    );
};

export default HeaderNavItem;