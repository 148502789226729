import React, {useContext, useEffect, useRef, useState} from 'react';
import Layout from "../Misc/Layout";
import {translate} from "../../libs/trans";
import {useHistory} from "react-router";
import {scrollToTop} from "../../libs/helpers";
import ReactSummernote from "react-summernote";
import Button from "../Reusable/Button";
import Http from "../../libs/Http";
import {useAlert} from "react-alert";
import context from "../../context/context";
import {ROLE_ADMIN, ROLE_CLIENT_USER} from "../../constants";
import {FormButton} from "../Reusable/FormComponents";

const Page = () => {

    const { state: globalState, dispatch } = useContext(context);
    const [page, setPage] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const {location} = useHistory();
    const alert = useAlert();
    const history = useHistory();
    const {sidebarOpen} = globalState.responsive;
    const mounted = useRef(false);

    useEffect(() => {
        scrollToTop();
        setPage(location.pathname.substr(1));
        _getPages();
        if(!mounted.current) {
            mounted.current = true;
        } else {
            if(sidebarOpen){
                document.querySelector('.aside-left').classList.remove('mobile-expand');
                document.querySelector('.layout-content').classList.remove('backdrop-menu');
                document.querySelector('body').classList.remove('backdrop-menu');
            }
        }
    }, [location]);

    const onInit = (name, value) => (note) => {
        if (!value) return false;
        note.reset()
        const regex = /(<\w*)((\s\/>)|(.*<\/\w*>))/i
        if (value.match(regex) !== null) {
            note.replace(value)
        } else {
            note.insertText(value)
        }
        setData(prev => ({...prev, [name]: value}));
    };

    const _getPages = async () => {
        setIsLoading(true);
        const res = await Http.get('/pages');
        if (!res.hasError) {
            setData(res.data);
        }
        setIsLoading(false);
    };

    const _save = async () => {
        if (isLoading) return false;
        setIsLoading(true);
        const res = await Http.patch('/pages', data);
        if (!res.hasError) {
            alert.show(translate('notifications.page_updated'), {type: 'success'});
            scrollToTop();
        }
        setIsLoading(false);
    };

    const openDeleteModal =  () => {
        dispatch({type: "SET_MODAL_DELETE", payload: {open: true, action: () => anonymizeCompany()}})
    }

    const anonymizeCompany = async () => {
        setIsLoading(true);
        const res = await Http.delete('/clients', {...(globalState.user.hasRole(ROLE_CLIENT_USER) && {client_user: true})});
        setIsLoading(false);
        if (res.hasError) {
            alert.show(res.error.general, {type: 'error'});
        } else {
            history.push('/');
        }
    };

    return (
        <Layout>
            <div className={'mx-4 mt-3'}>
                {!globalState.responsive.isMobile && <div className="my-tabs">
                    <span onClick={() =>  history.push('/terms')} className={page === 'terms' ? 'active' : ''}>{translate('sidebar.terms')}</span>
                    <span onClick={() =>  history.push('/privacy')} className={page === 'privacy' ? 'active' : ''}>{translate('sidebar.privacy')}</span>
                    <span onClick={() =>  history.push('/gdpr')} className={page === 'gdpr' ? 'active' : ''}>{translate('sidebar.gdpr')}</span>
                </div>}
                <div className="tab-container pages">
                    {!isLoading && <div>
                        {globalState.responsive.isMobile && <div className={'current-page-details align-items-center d-flex justify-content-between pl-4 mt-4 mb-3'}>
                            <h3>{translate(`sidebar.${page}`)}</h3>
                            <div className={''}>
                                {!globalState.user.hasRole(ROLE_ADMIN) && (page ==='gdpr') && <Button onClick={openDeleteModal}
                                                                                                      fontSize={15}
                                                                                                      height={44}
                                                                                                      textColor={'#fff'}
                                                                                                      backgroundColor={'red'}
                                                                                                      className={'border-0'}
                                                                                                      text={"Close My Account"}
                                />}
                            </div>
                        </div>}

                        {!globalState.responsive.isMobile && <div className={'float-right mt-3 mb-3 mx-4'}>
                            {!globalState.user.hasRole(ROLE_ADMIN) && (page ==='gdpr') && <Button onClick={openDeleteModal}
                                                                                                  fontSize={15}
                                                                                                  height={44}
                                                                                                  textColor={'#fff'}
                                                                                                  backgroundColor={'red'}
                                                                                                  className={'border-0'}
                                                                                                  text={"Close My Account"}
                            />}
                        </div>}

                        {globalState.user.hasRole(ROLE_ADMIN) ? <div className="container-white">
                                <ReactSummernote
                                    onInit={onInit(page, data[page])}
                                    disabled={!globalState.user.hasRole(ROLE_ADMIN)}
                                    className="mb-3"
                                    options={{
                                        height: 600,
                                        dialogsInBody: true,
                                        toolbar: [
                                            ['style', ['style']],
                                            ['color', ['color']],
                                            ['font', ['bold', 'italic', 'underline', 'clear']],
                                            ['para', ['paragraph', 'ul', 'ol']],
                                            ['tags', ['tags']]
                                        ],
                                        popover: false
                                    }}
                                    onChange={value => setData(prev => ({...prev, [page]: value}))}
                                />

                                <div className={'d-flex justify-content-center'}>
                                    <FormButton
                                    size={'large'}
                                    variant={'primary'}
                                    text={translate('misc.save')}
                                    isLoading={isLoading}
                                    onClick={_save}/>
                                </div>

                            </div> :
                            <div className={'mx-4 pt-3'} dangerouslySetInnerHTML={{__html:data[page]}} />}
                    </div>}

                </div>
            </div>
        </Layout>
    );
};

export default Page;