import saveAs from 'file-saver';

const axios = require('axios');

class Http {

    async request(url, params, method = 'GET') {

        let headers = {
            'Content-Type': 'application/json',
        };

        let data = null, hasError = false, error = null, pagination = null;
        const jwt = sessionStorage.getItem('jwt');
        if (jwt) headers['Authorization'] = 'Bearer ' + jwt;

        let response = {};

        let requestObject = {
            method,
            url: process.env.REACT_APP_API_URL + url,
            headers
        };

        if (method === 'GET') {
            requestObject.params = params;
        } else {
            requestObject.data = params;
        }

        try {
            window.setHeaderLoading(true);
            response = await axios(requestObject);
        } catch (e) {

            response = e.response;

            if (!response || !response.hasOwnProperty('status')) {
                console.error('Server error: ', e.message);
                return {
                    data,
                    hasError: true,
                    error: "invalid api response",
                    pagination
                };
            } else {
                if (response.status !== 200) {
                    console.error('The server responded with an unexpected status.');
                }

                if (response.status === 401) {
                    sessionStorage.removeItem('jwt');
                    window.location.href = '/login';
                }
            }


        } finally {
            window.setHeaderLoading(false);
        }

        if (typeof response.data !== "object") {
            console.error('The server responded with an invalid response.');
            return {
                data,
                hasError: true,
                error: "invalid api response",
                pagination
            };
        }


        const result = response.data;

        if (result.responseType === 'error') {
            hasError = true;
            if (typeof result.errorMessage === 'object')
                error = {
                    general: null,
                    ...result.errorMessage
                };
            else if (typeof result.errorMessage === 'string')
                error = {
                    general: result.errorMessage
                };
            else {
                error = {
                    general: '__other'
                };
            }

        } else {
            data = result.data.result;
            pagination = result.data.pagination;
        }

        return {
            data,
            hasError,
            error,
            pagination
        };

    }

    async get(url, params) {
        return this.request(url, params);
    }

    async post(url, params) {
        if(params && params.hasOwnProperty('hasFiles')){
            let formData = new FormData();
            Object.keys(params).forEach( key => formData.append(key, params[key]));
            params = formData;
        }
        return this.request(url, params, 'POST');
    }

    async put(url, params) {
        return this.request(url, params, 'PUT');
    }

    async patch(url, params) {
        return this.request(url, params, 'PATCH');
    }

    async delete(url, params) {
        return this.request(url, params, 'DELETE');
    }

    async getRaw(url, params) {

        let requestObject = {
            method: 'GET',
            url: process.env.REACT_APP_API_URL + url,
            headers: {
                'Content-Type': 'application/json',
            }
        };

        requestObject.params = params;
        return await axios(requestObject);

    }

    async download(url, params = {}) {
        return this.downloadPdf(url, params, false);
    }

    async downloadPdf(url, params, fileName = 'download.pdf') {
        
        let headers = {
            'Content-Type': 'application/json',
        };

        const jwt = sessionStorage.getItem('jwt');
        if (jwt) headers['Authorization'] = 'Bearer ' + jwt;

        let requestObject = {
            method: 'GET',
            url: process.env.REACT_APP_API_URL + url,
            headers,
            params,
            responseType: 'arraybuffer'
        };
        try {
            window.setHeaderLoading(true);
            const response = await axios(requestObject);

            if (!fileName) {
                let headerLine = response.headers['content-disposition'];
                let startFileNameIndex = headerLine.indexOf('"') + 1
                let endFileNameIndex = headerLine.lastIndexOf('"');
                fileName = headerLine.substring(startFileNameIndex, endFileNameIndex);
            }

            saveAs(new Blob([response.data], {type: response.headers['content-type']}), fileName);
        } catch (e) {
            console.log(e);
        } finally {
            window.setHeaderLoading(false);
        }

    }

}

export default new Http();
