import React, {useEffect, useState} from 'react';
import Layout from "../Misc/Layout";
import AdminUsers from "../Users/AdminUsers";
import MediaLibrary from "./MediaLibrary";
import Templates from "../Templates";
import CountryEmails from "./CountryEmails";
import Dropdowns from "../Dropdowns";
import {translate} from "../../libs/trans";
import {useHistory} from "react-router-dom";

const Index = () => {

    const[step , setStep] = useState(1);
    const history = useHistory();

    useEffect(() => {
        if(history.location.hasOwnProperty('step')){
            setStep(history.location.step)
        }
    }, [history.location]);

    return (
        <Layout>
            <div className={'mx-4'}>
                <div className="my-tabs mt-3">
                    <span onClick={() =>  setStep(1)} className={step === 1 ? 'active' : ''}>{translate('sidebar.admins')}</span>
                    <span onClick={() =>  setStep(2)} className={step === 2 ? 'active' : ''}>{translate('dropdowns.header')}</span>
                    <span onClick={() =>  setStep(3)} className={step === 3 ? 'active' : ''}>{translate('sidebar.country_emails')}</span>
                    <span onClick={() =>  setStep(4)} className={step === 4 ? 'active' : ''}>{translate('templates.header')}</span>
                    <span onClick={() =>  setStep(5)} className={step === 5 ? 'active' : ''}>{translate('media.header')}</span>
                </div>
                <div className={'tab-container'}>
                    {step === 1 && <AdminUsers/>}
                    {step === 2 && <Dropdowns setStep={setStep} location={{dropdownFilterDefault:history?.location?.dropdownFilterDefault}}/>}
                    {step === 3 && <CountryEmails/>}
                    {step === 4 && <Templates/>}
                    {step === 5 && <MediaLibrary noLayout={true}/>}
                </div>
            </div>



        </Layout>
    );
};

export default Index;