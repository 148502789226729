import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {translate} from "../../libs/trans";

const AlertTemplate = ({ style, options, message, close }) => (
    <div className={`alerts ${options.type}`} style={style}>
        <div className="icon"/>
        <div>
            <p>{translate('misc.alert_' + options.type)}</p>
            <p>{message}</p>
        </div>
        <div className="close-alert" onClick={close}><FontAwesomeIcon icon={faTimes} /></div>
    </div>
)

export default AlertTemplate;
