import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Pagination from './Pagination';
import {translate} from "../../libs/trans";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {Col, Row} from "reactstrap";
import SearchInput from "./SearchInput";
import {SEARCH_TIME} from "../../constants";
import context from "../../context/context";

let searchTimeout = null;

const Table = (props) => {

    const {state: globalState} = useContext(context);
    const {columns, getItems, setItems} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [sortOrder, setSortOrder] = useState(props.sortOrder || 'desc');
    const [sortColumn, setSortColumn] = useState(props.sortColumn || 'id');
    const [searchString, setSearchString] = useState(null);
    const [pagination, setPagination] = useState({
        totalPages: 0,
        currentPage: 1,
        limit: 10,
        totalEntries: 0
    });

    /**
     * On change isLoading from props
     */
    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    /**
     * On change refreshTable from props
     */
    useEffect(() => {
        if(props.refreshTable > 0) getAllResults();
    }, [props.refreshTable]);


    /**
     * On sort and page change
     */
    useEffect(() => {
        getAllResults();
    }, [sortOrder, sortColumn, pagination.currentPage]);

    /**
     * On search input change
     */
    useEffect(() => {
        if (searchString !== null) {
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(getAllResults, SEARCH_TIME);
        }
    }, [searchString]);

    /**
     * Main get results function
     * @returns {Promise<void>}
     */
    const getAllResults = async () => {

        const {extraParams = {}} = props;

        setIsLoading(true);

        const res = await getItems({
            sortOrder,
            sortColumn,
            search: searchString,
            limit: pagination.limit,
            page: pagination.currentPage,
            ...extraParams
        });


        if (res.hasError) {
            console.error(res.error, 'Table component API error');
        } else {
            setResults(res.data);
            setItems(res.data);
            setPagination(res.pagination);
        }
        setIsLoading(false);
    }

    /**
     * Handle sort order
     * @param name
     * @param sortable
     * @returns {function(...[*]=)}
     * @private
     */
    const _onSort = (name, sortable) => _ => {

        if (!sortable) return false;

        setSortColumn(name);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    /**
     * Handle change page
     * @param page
     * @private
     */
    const _changePage = (page) => setPagination({...pagination, currentPage: page});

    /**
     * Handle change search string
     * @param _
     * @param str
     * @private
     */
    const _onSearch = (_, str) => {
        props.setSearchString && props.setSearchString(str);
        setSearchString(str);
    }

    return (
        <div>

            {!props.hideSearchRow && <div className={'table-actions'}>
                <Row className="justify-content-between">
                    <Col sm={12} md={4} lg={4} xl={4} className={'mb-3'}>
                        <SearchInput placeholder={translate('misc.search')} name={'search'} onChange={_onSearch}/>
                    </Col>
                    {props.customFilterContainer && <Col sm={12} md={4} lg={4} xl={3} className={'mb-3'}>
                        {props.customFilterContainer}
                    </Col>}

                    {globalState.responsive.width > 1024 && <Col sm={12} md={props.customFilterContainer ? 4 : 8} lg={props.customFilterContainer ? 4 : 8} xl={props.customFilterContainer ? 3 : 8} className="text-md-right mb-3">
                        {props.buttonsContainer}
                    </Col>}
                </Row>
            </div>}

            <div className={'table-header mt-0'}>

                {columns && columns.map((column, key) => {

                    let columnSortableName = column.sortColumn ? column.sortColumn : column.name;

                    let icon = '';
                    if (column.sortable) {
                        if (columnSortableName === sortColumn) {
                            if (sortOrder === 'asc') {
                                icon = 'up';
                            } else {
                                icon = 'down';
                            }
                        } else {
                            icon = 'down';
                        }
                    }

                    return (!column.hidden && <div key={key} onClick={_onSort(columnSortableName, column.sortable)}
                                                   className={`w-col-${column.colSize}
                                                   ${column.colSizeTablet ? `w-col-tablet-${column.colSizeTablet}` : ''}
                                                   ${column.colSizeMobile ? `w-col-mobile-${column.colSizeMobile}` : ''}
                                                   ${!column.colSizeMobile ? `w-col-mobile-d-none` : ''}
                                                   ${!column.colSizeTablet ? `w-col-tablet-d-none` : ''}
                                                   ${column.sortable ? 'sortable' : ''} ${column.customClass || ''}`}>
                        <span>{column.text}</span>
                        {column.required && <span className="required-star">*</span>}
                        {column.sortable && <FontAwesomeIcon icon={icon === 'up' ? faAngleUp : faAngleDown}/>}
                    </div>);
                })}
            </div>

            {(isLoading && (!results || results.length === 0) ) && <div className="table-container-items">
                <div className="table-row-item row m-0 py-4">
                    <div className="justify-content-center w-100">
                        <div className={'loading'}>
                            <div className={'table-loader'}>
                                <div className="spinner"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div> }


            {(!isLoading && results && results.length === 0) && <div className="table-container-items">
                <div className="table-row-item row m-0">
                    <div className="justify-content-center w-100">{translate('misc.noRecords')}</div>
                </div>
            </div>}

            <div className={`table-container-items position-relative ${isLoading ? 'isLoading' : ''}`}>

                {(isLoading && results && results.length > 0) && <div className="overlay-loading">
                    <div className="table-row-item row m-0 py-4">
                        <div className="justify-content-center w-100">
                            <div className={'loading'}>
                                <div className={'table-loader'}>
                                    <div className="spinner"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> }

                {React.Children.map(props.children, child => {
                    let arr = [];
                    if (!child) return null;
                    child.props.children.forEach((c, index) => {

                        if(columns[index] === false){
                            return;
                        }

                        if (!columns[index]?.colSize) {
                            arr.push(React.cloneElement(c, { key: index}));
                        }else{
                            arr.push(React.cloneElement(c, {
                                ...c.props,
                                key: index,
                                className: (c.props?.className || '') +
                                    (' w-col-' + columns[index]?.colSize || '') +
                                    (!columns[index].colSizeTablet ? ` w-col-tablet-d-none` : '')  +
                                    (!columns[index].colSizeMobile ? ` w-col-mobile-d-none` : '')  +
                                    (columns[index].colSizeTablet ? ` w-col-tablet-${columns[index].colSizeTablet}` : '')  +
                                    (columns[index].colSizeMobile ? ` w-col-mobile-${columns[index].colSizeMobile}` : '')
                            }))
                        }
                    });
                    return (
                        React.cloneElement(child, {...child.props}, arr)
                    );
                })}
            </div>

            {pagination.totalPages > 1 &&
            <Pagination pages={pagination.totalPages} isLoading={isLoading} limit={pagination.limit} onChange={_changePage}/>}
        </div>
    );
};

export default Table;
