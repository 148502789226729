import React, { useContext, useReducer } from "react";
import Context from '../context/context';
import reducer from '../reducers/rootReducer';
import Router from "../router";
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/modal';

const AppContext = () => {

    const initialState = useContext(Context);
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Context.Provider value={{ state, dispatch }}>
            <Router />
        </Context.Provider>
    )
};

export default AppContext;
