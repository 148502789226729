import React, {Fragment, useContext, useEffect, useState} from 'react';
import Http from "../../libs/Http";
import {Container, Row, Col, Form, FormGroup, FormFeedback} from 'reactstrap';
import {Link} from 'react-router-dom';
import {translate} from "../../libs/trans";
import Button from '../Reusable/Button';
import CustomInput from '../Reusable/CustomInput';
import logo from "../../resources/images/side-logo.png";
import context from "../../context/context";
import {useParams, useHistory} from 'react-router-dom';
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import InfoAlert from "../Reusable/InfoAlert";
import {FormButton} from "../Reusable/FormComponents";


const ForgotPassword = () => {

    const {state: globalState, dispatch} = useContext(context);

    const {code} = useParams();

    const initialState = {
        email: '',
        code: code || '',
        password: '',
        retypePassword: '',
        showCode: !!code,
        submittedEmail: false,
        isSuccess: false
    };

    const [state, setState] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const history = useHistory();

    const jwt = sessionStorage.getItem('jwt');

    useEffect(() => {
        if (globalState.user || jwt) {
            history.push('/');
        }
        return () => {
            setIsLoading(false);
        };
    }, [globalState.user, jwt]);


    const _forgot = async (e) => {

        if (isLoading) return false;

        e.preventDefault();

        setIsLoading(true);

        const params = {
            email: state.email,
        };

        const res = await Http.post('/forgot-password', params);

        if (res.hasError) {
            setErrors(res.error);
            setIsLoading(false);

        } else {
            setState(prevState => ({
                ...prevState,
                showCode: true,
                submittedEmail: true
            }));
            setErrors({});
            setIsLoading(false);
        }

    };

    const _reset = async (e) => {
        const {password, code, retypePassword} = state;

        if (isLoading) return false;

        e.preventDefault();

        setIsLoading(true);

        let userDetails = {
            code
        };

        let sendRequest = true;

        if (password !== '' && password === retypePassword) {
            userDetails.password = password;
        } else {
            setErrors({
                ...errors,
                'retypePassword': translate('password.match')
            });
            sendRequest = false;
        }

        if (sendRequest) {

            const res = await Http.post('/forgot-password', {...userDetails});
            if (res.hasError) {
                setErrors(res.error);
            } else {
                setState(prevState => ({
                    ...prevState,
                    isSuccess: true
                }));

                setErrors({});
                dispatch({type: "SET_NOTIFICATION_MESSAGE", payload: translate('auth.login.reset')});
                history.push('/login');
            }

            setIsLoading(false);

        }
        setIsLoading(false);
    };

    const _handleChange = (name, value) => {
        setState(prevState => ({...prevState, [name]: value}));
    };

    const _setShowCode = () => {
        setState(prevState => ({
            ...prevState,
            showCode: !state.showCode,
            submittedEmail: false
        }));
        setErrors({});
    };

    const _renderMain = () => {
        return (
            <Fragment>
                <div className={'auth-background-image'}/>
                <div className={'auth-container mt-4'}>
                    <div className={'up'}>
                        <Row className="mx-0">
                            <Col xs={12} className="header-login px-0">
                                <div className={'logo-container'}>
                                    <img src={logo} alt=""/>
                                    <h2 className="">{translate('auth.login.hd_check')}
                                        <span>{translate('auth.login.analyser')}</span></h2>
                                </div>
                            </Col>
                            <Container>
                                    <Col xs={12} className='px-4'>
                                        <h3 className={'text-center mt-4'}>{translate('auth.forgot.title')}</h3>
                                        <Form onSubmit={_forgot}>
                                            {errors?.email &&
                                            <FormFeedback>{errors?.email}</FormFeedback>}
                                            {errors?.general &&
                                            <FormFeedback>{errors?.general}</FormFeedback>}
                                            <FormGroup>
                                                <CustomInput
                                                    type="text"
                                                    label="Email"
                                                    value={state.email}
                                                    name="email"
                                                    onChange={_handleChange}
                                                    {...(errors && (errors.email || errors.general) ? {
                                                        invalid: true,
                                                        icon: faExclamation
                                                    } : {})}
                                                />
                                            </FormGroup>
                                            <FormButton
                                                size={'xl'}
                                                fluid={true}
                                                className={'mt-4'}
                                                variant={'primary'}
                                                isLoading={isLoading}
                                                disabled={state.email === ''}
                                                text={translate('auth.forgot.title')}
                                                onClick={_forgot}/>
                                        </Form>
                                        <p className={'auth-redirect'}><Link
                                            to='/login'>{translate('misc.cancel')}</Link></p>
                                        <p className={'auth-redirect have-code'}><a
                                            onClick={_setShowCode}>{translate('auth.forgot.haveCode')}</a></p>
                                    </Col>
                            </Container>
                        </Row>
                    </div>
                </div>
            </Fragment>
        );
    };

    const _renderCode = () => {
        const {submittedEmail, code, password, retypePassword} = state;

        return (
            <Fragment>
                <div className={'auth-background-image'}/>
                <div className={'auth-container mt-4'}>
                    <div className={'up'}>
                        <Row className="mx-0">
                            <Col xs={12} className="header-login px-0">
                                <div className={'logo-container'}>
                                    <img src={logo} alt=""/>
                                    <h2 className="">{translate('auth.login.hd_check')}
                                        <span>{translate('auth.login.analyser')}</span></h2>
                                </div>
                            </Col>
                        <Container>
                                <Col xs={12}>
                                    <h3 className={'text-center mt-4'}>{translate('auth.forgot.codeTitle')}</h3>
                                    {submittedEmail && <InfoAlert visible={true}/>}
                                    <Form onSubmit={_reset}>
                                        <FormGroup>
                                            {errors.general &&
                                            <FormFeedback>{errors.general}</FormFeedback>}
                                            <CustomInput
                                                type="text"
                                                label={translate('auth.forgot.code')}
                                                value={code}
                                                name="code"
                                                onChange={_handleChange}
                                                {...(errors && errors.general ? {
                                                    invalid: true
                                                } : {})}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            {errors.password &&
                                            <FormFeedback>{errors.password}</FormFeedback>}
                                            {errors &&
                                            <FormFeedback>{errors.retypePassword}</FormFeedback>}
                                            <CustomInput
                                                type="password"
                                                label={translate('auth.login.password')}
                                                value={password}
                                                autocomplete={"new-password"}
                                                name="password"
                                                passwordViewer
                                                onChange={_handleChange}
                                                {...(errors && (errors.password || (errors && errors.retypePassword)) ? {
                                                    invalid: true
                                                } : {})}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <CustomInput
                                                type="password"
                                                label={translate('auth.login.retypePassword')}
                                                value={retypePassword}
                                                name="retypePassword"
                                                passwordViewer
                                                onChange={_handleChange}
                                                {...(errors && errors.retypePassword ? {
                                                    invalid: true
                                                } : {})}
                                            />
                                        </FormGroup>
                                    </Form>
                                    <FormButton
                                        size={'xl'}
                                        fluid={true}
                                        className={'mt-4'}
                                        variant={'primary'}
                                        isLoading={isLoading}
                                        disabled={code === '' || password === ''}
                                        text={"Reset Password"}
                                        onClick={_reset}/>

                                    <p className={'auth-redirect'}><a
                                        onClick={_setShowCode}>{translate('auth.forgot.codeNotReceived')}</a>
                                    </p>
                                </Col>
                        </Container>
                        </Row>
                    </div>
                </div>
            </Fragment>
        );
    };

    return (
        <div>
            <div className={'auth-blank-section'}/>
            {!state.showCode && _renderMain()}
            {state.showCode && _renderCode()}
        </div>
    );
};

export default ForgotPassword;