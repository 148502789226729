import React, {useContext, useEffect, useState} from 'react';

import {Container, Row, Col, Form, FormGroup, FormFeedback} from 'reactstrap';

import Http from '../../libs/Http';
import {translate} from "../../libs/trans";
import context from "../../context/context";
import Button from '../Reusable/Button';
import CustomInput from '../Reusable/CustomInput';
import {User} from "../../libs/models";
import {useHistory, useParams} from "react-router-dom";
import logo from "../../resources/images/side-logo.png";
import {FormButton} from "../Reusable/FormComponents";


const CreatePassword = () => {

    const {state: globalState, dispatch} = useContext(context);

    const {code} = useParams();

    const initialState = {
        email: '',
        code: code || '',
        password: '',
        retypePassword: '',
        isSuccess: false
    };

    const [state, setState] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const history = useHistory();

    const jwt = sessionStorage.getItem('jwt');
    const rememberToken = localStorage.getItem('rememberToken');


    useEffect(() => {
        if (globalState.user || jwt || rememberToken) {
            history.push('/');
        }
        return () => {
            setIsLoading(false);
        };
    }, [globalState.user, jwt, rememberToken]);

    const _createPassword = async (e) => {
        const {password, retypePassword, code} = state;
        e.preventDefault();

        if (isLoading) return false;

        setIsLoading(true);

        let userDetails = {
            code
        };

        let sendRequest = true;

        if (password !== '' && password === retypePassword) {
            userDetails.password = password;
        } else {

            setErrors({
                ...errors,
                'retypePassword': translate('password.match')
            });
            sendRequest = false;
        }

        if (sendRequest) {
            const res = await Http.post('/create-password', {...userDetails});

            if (res.hasError) {
                setErrors(res.error);
            } else {
                setErrors({});
                sessionStorage.setItem('jwt', res.data.jwt)
                dispatch({type: "SET_USER", payload: User(res.data.user)});
                history.push('/')
            }
        }

        setIsLoading(false)
    };

    const _handleChange = (name, value) => {
        setState(prevState => ({...prevState, [name]: value}))
    };


    const {password, retypePassword} = state;

    return (
        <div>
            <div className={'auth-blank-section'}/>
            <div>
                <div className={'auth-background-image'}/>
                <div className={'auth-container mt-4'}>
                    <div className={'up'}>
                        <Row className="mx-0">
                            <Col xs={12} className="header-login px-0">
                                <div className={'logo-container'}>
                                    <img src={logo} alt=""/>
                                    <h2 className="">{translate('auth.login.hd_check')}
                                        <span>{translate('auth.login.analyser')}</span></h2>
                                </div>
                            </Col>
                            <Container>
                                    <Col xs={12} className={'px-4'}>
                                        <h3 className={'text-center mt-4'}>{translate('auth.employee.title')}</h3>
                                        <Form onSubmit={_createPassword}>
                                            <FormGroup>
                                                {errors && errors.general &&
                                                <FormFeedback>{errors.general}</FormFeedback>}
                                                <CustomInput
                                                    type="text"
                                                    label={translate('auth.employee.email')}
                                                    value={state.code}
                                                    name="code"
                                                    onChange={_handleChange}
                                                    {...(errors && errors.error ? {
                                                        invalid: true
                                                    } : {})}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                {errors && errors.password &&
                                                <FormFeedback>{errors.password}</FormFeedback>}
                                                {errors &&
                                                <FormFeedback>{errors.retypePassword}</FormFeedback>}
                                                <CustomInput
                                                    type="password"
                                                    label={translate('auth.employee.password')}
                                                    value={password}
                                                    autocomplete={"new-password"}
                                                    name="password"
                                                    passwordViewer
                                                    onChange={_handleChange}
                                                    {...(errors && (errors.password || (errors && errors.retypePassword)) ? {
                                                        invalid: true
                                                    } : {})}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <CustomInput
                                                    type="password"
                                                    label={translate('auth.login.retypePassword')}
                                                    value={retypePassword}
                                                    name="retypePassword"
                                                    passwordViewer
                                                    onChange={_handleChange}
                                                    {...(errors && errors.retypePassword ? {
                                                        invalid: true
                                                    } : {})}
                                                />
                                            </FormGroup>
                                        </Form>
                                        <FormButton
                                            size={'xl'}
                                            fluid={true}
                                            className={'mt-4 mb-5'}
                                            variant={'primary'}
                                            isLoading={isLoading}
                                            disabled={code === '' || password === ''}
                                            text={translate('misc.submit')}
                                            onClick={_createPassword}/>
                                    </Col>
                            </Container>
                        </Row>
                    </div>
                </div>
            </div>
            );
        </div>
    );
};

export default CreatePassword;
