import React, {useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faLockOpen, faLock, faEdit, faEnvelope, faTrash, faClone, faComment, faEye, faDownload,
    faFolder, faFolderOpen, faUserSecret, faUndo, faClock, faCalculator
} from "@fortawesome/free-solid-svg-icons";
import TooltipComponent from "./Tooltip";
import {translate} from "../../libs/trans";
import {Link} from "react-router-dom";
import context from "../../context/context";



const TableHover = ({hover, item, onEdit, onEmail, onSms, onLinkTo,
                        onLock, onDelete, isLocked, onClone, onExport, onScoring,
                        idSuffix, onView, onDownloadReport, onArchive, isArchived,
                        onResendInvite, onImpersonate, onUndo, onLogs, className,isDeleteWithSelect,openDeleteModalWithSelect}) => {

    const {dispatch} = useContext(context);

    const handleDelete = () => {
        dispatch({type: "SET_MODAL_DELETE", payload: {open: true, action: () => onDelete(item)}})
    };

    return (
        <div className={`table-row-icons ${className ? className : ''}`}>


            {hover.view && <div className="table-row-icon" id={`view-${idSuffix}`} onClick={() => onView(item)}>
                <FontAwesomeIcon icon={faEye} />
                <TooltipComponent placement={'top'} target={`view-${idSuffix}`}>{translate('misc.view')}</TooltipComponent>
            </div>}

            {hover.scoring && <div className="table-row-icon" id={`scoring-${idSuffix}`} onClick={() => onScoring(item)}>
                <FontAwesomeIcon icon={faCalculator} />
                <TooltipComponent placement={'top'} target={`scoring-${idSuffix}`}>{translate('misc.scoring')}</TooltipComponent>

            </div>}

            {hover.edit && <div className="table-row-icon" id={`edit-${idSuffix}`} onClick={() => onEdit(item)}>
                <FontAwesomeIcon icon={faEdit} />
                <TooltipComponent placement={'top'} target={`edit-${idSuffix}`}>{translate('misc.edit')}</TooltipComponent>

            </div>}

            {hover.link_to && <div className="table-row-icon" id={`link-${idSuffix}`}>
                <Link to={onLinkTo} className={'color-inherit'}><FontAwesomeIcon icon={faEdit} /></Link>
                <TooltipComponent placement={'top'} target={`link-${idSuffix}`}>{translate('misc.edit')}</TooltipComponent>
            </div>}

            {hover.download && <div className="table-row-icon" id={`download-${idSuffix}`} onClick={() => onDownloadReport(item)}>
                <FontAwesomeIcon icon={faDownload} />
                <TooltipComponent placement={'top'} target={`download-${idSuffix}`}>{translate('misc.download')}</TooltipComponent>
            </div>}

            {hover.clone && <div className="table-row-icon" id={`clone-${idSuffix}`} onClick={() => onClone(item)}>
                <FontAwesomeIcon icon={faClone} />
                <TooltipComponent placement={'top'} target={`clone-${idSuffix}`}>{translate('misc.clone')}</TooltipComponent>
            </div>}

            {hover.export && <div className="table-row-icon" id={`export-${idSuffix}`} onClick={() => onExport(item)}>
                <FontAwesomeIcon icon={faDownload} />
                <TooltipComponent placement={'top'} target={`export-${idSuffix}`}>Export</TooltipComponent>
            </div>}

            {hover.lock && <div className={'table-row-icon'} id={`lock-${idSuffix}`} onClick={() => onLock(item)}>
                <FontAwesomeIcon icon={isLocked ? faLockOpen : faLock} />
                <TooltipComponent placement={'top'}
                                  target={`lock-${idSuffix}`}>{isLocked ? translate('misc.enable') : translate('misc.disable')}</TooltipComponent>
            </div>}

            {hover.archive && <div className={'table-row-icon'} id={`archive-${idSuffix}`} onClick={() => onArchive(item)}>
                <FontAwesomeIcon icon={isArchived ? faFolderOpen : faFolder} />
                <TooltipComponent placement={'top'}
                                  target={`archive-${idSuffix}`}>{isArchived ? translate('misc.unarchive') : translate('misc.archive') }</TooltipComponent>
            </div>}

            {hover.email && <div className="table-row-icon" id={`email-${idSuffix}`} onClick={() => onEmail(item)}>
                <FontAwesomeIcon icon={faEnvelope} />
                <TooltipComponent placement={'top'} target={`email-${idSuffix}`}>{translate('misc.email')}</TooltipComponent>
            </div>}

            {hover.sms && <div className="table-row-icon" id={`sms-${idSuffix}`} onClick={() => onSms(item)}>
                <FontAwesomeIcon icon={faComment} />
                <TooltipComponent placement={'top'} target={`sms-${idSuffix}`}>{translate("misc.sms")}</TooltipComponent>
            </div>}

            {hover.delete && <div className="table-row-icon" id={`delete-${idSuffix}`} onClick={isDeleteWithSelect ? () => openDeleteModalWithSelect(item) : handleDelete}>
                <FontAwesomeIcon icon={faTrash} />
                <TooltipComponent placement={'top'} target={`delete-${idSuffix}`}>{translate('misc.delete')}</TooltipComponent>
            </div>}

            {hover.resend_invite && <div className="table-row-icon" id={`resend_invite-${idSuffix}`} onClick={() => onResendInvite(item)}>
                <FontAwesomeIcon icon={faEnvelope} />
                <TooltipComponent placement={'top'} target={`resend_invite-${idSuffix}`}>{translate('misc.resend_invite')}</TooltipComponent>
            </div>}

            {hover.undo && <div className="table-row-icon" id={`undo-${idSuffix}`} onClick={() => onUndo(item)}>
                <FontAwesomeIcon icon={faUndo} />
                <TooltipComponent placement={'top'} target={`undo-${idSuffix}`}>{translate('misc.undo')}</TooltipComponent>
            </div>}

            {hover.impersonate && <div className="table-row-icon" id={`impersonate-${idSuffix}`} onClick={() => onImpersonate(item)}>
                <FontAwesomeIcon icon={faUserSecret} />
                <TooltipComponent placement={'top'} target={`impersonate-${idSuffix}`}>{translate('misc.impersonate')}</TooltipComponent>
            </div>}

            {hover.logs && <div className="table-row-icon" id={`logs-${idSuffix}`} onClick={() => onLogs(item)}>
                <FontAwesomeIcon icon={faClock} />
                <TooltipComponent placement={'top'} target={`logs-${idSuffix}`}>{translate("misc.user_logs")}</TooltipComponent>
            </div>}

        </div>
    );
};

export default TableHover;
