import React, {useContext, useEffect, useState} from 'react';
import Layout from "../../Misc/Layout";
import {Col, Row} from "reactstrap";
import CurrentResultsCharts from "../../Charts/CurrentResultsCharts";
import Timeline from "../../Charts/Timeline";
import Http from "../../../libs/Http";
import context from "../../../context/context";
import {translate} from "../../../libs/trans";
import {useAlert} from "react-alert";
import {ROLE_ADMIN} from "../../../constants";
import CustomSelect from "../../Reusable/CustomSelect";
import SimpleCompareRadar from "../../Charts/SimpleCompareRadar";
import {FormButton} from "../../Reusable/FormComponents";
import {useHistory} from "react-router";


const AdminDashboard = () => {

    const {state: globalState, dispatch} = useContext(context);
    const [isLoading, setIsLoading] = useState(true);
    const [params, setParams] = useState({
        ...(globalState.user.hasRole(ROLE_ADMIN) && {company_id: "all"}),
        others_timespan: "all",
    });

    const alert = useAlert();
    const history = useHistory();

    useEffect(() => {
        _getClients();
    }, []);


    const _getClients = async () => {

        const res = await Http.get('/clients/min');

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            let clients = res.data;

            clients.unshift({
                value: 'all',
                label: translate('clients.all_clients')
            });

            dispatch({type: "SET_CLIENTS", payload: clients});
        }
        setIsLoading(false);
    };

    const setTimeFilter = (value) => _ => {
        if (!isLoading)
            setParams(params => ({...params, others_timespan: value}));
    }

    return (
        <Layout loading={isLoading}>

            {!isLoading && <>
                <Row className="mx-4">

                    { globalState.responsive.isTablet && <Col xs={12} className="pt-4">
                        <Row>
                            <Col xs={6} className="pl-0">
                                <FormButton
                                    size={'xl'}
                                    fluid={true}
                                    className={'mb-4'}
                                    variant={'primary'}
                                    text={'Add New Result/s'}
                                    onClick={() => history.push({
                                        pathname: '/overall-analysis/results',
                                        create: true
                                    })}/>
                                <FormButton
                                    size={'xl'}
                                    fluid={true}
                                    className={'mb-4'}
                                    variant={'secondary'}
                                    text={'All Results'}
                                    onClick={() => history.push({
                                        pathname: '/overall-analysis/results',
                                    })}/>


                                <div className="bordered-container m-0">
                                    <SimpleCompareRadar params={params}/>
                                </div>

                                <div className="bordered-container dash-box bot-container m-0 mt-4">
                                    <Timeline params={params} dashboard={true} title={translate('analysis.timeline')}/>
                                </div>

                            </Col>
                            <Col xs={6} className="px-0 ">
                                <div className="bordered-container m-0">
                                    <div className="dash-box bot-container mt-0 px-4">
                                        <div className={`w-100 flex-wrap justify-content-between d-flex`}>
                                            <div className="left-side">
                                                <h4 className="title py-3">Positives v Negative</h4>
                                            </div>
                                            <div className="d-flex w-100">
                                                <div style={{flexBasis: '70%'}}>
                                                    <CustomSelect
                                                        className="pr-3"
                                                        onChange={(name, value) => setParams(params => ({...params, company_id: value}))}
                                                        value={params.company_id}
                                                        options={globalState.clients}
                                                    />
                                                </div>
                                                <div style={{flexGrow: 1}}>
                                                    <CustomSelect
                                                        onChange={(name, value) => setTimeFilter(value)()}
                                                        value={params.others_timespan}
                                                        options={[
                                                            {label: 'All', value: 'all'},
                                                            {label: '1 Yr', value: 11},
                                                            {label: '6 months', value: 5},
                                                            {label: '3 months', value: 2},
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dash-box px-4">
                                            <CurrentResultsCharts params={params}/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>}

                    {globalState.responsive.isMobile && <Col sm={12} className={'px-0 mt-3'}>
                        <FormButton
                            size={'xl'}
                            fluid={true}
                            className={'mb-4'}
                            variant={'primary'}
                            text={'Add New Result/s'}
                            onClick={() => history.push({
                                pathname: '/overall-analysis/results',
                                create: true
                            })}/>
                        <FormButton
                            size={'xl'}
                            fluid={true}
                            variant={'secondary'}
                            text={'Existing Results'}
                            onClick={() => history.push({
                                pathname: '/overall-analysis/results',
                            })}/>
                    </Col>}

                    {(globalState.responsive.isMobile || globalState.responsive.isDesktop) && <>

                    <Col xs={12} sm={12} md={12} xl={4} className="px-0">
                        <div className="mb-4 bordered-container ml-0 mr-0 mr-xl-3">
                            <SimpleCompareRadar params={params}/>
                        </div>
                        {globalState.responsive.isDesktop && <Col sm={12} className={'px-0 pr-xl-3'}>
                            <FormButton
                                size={'xl'}
                                className={'mb-4'}
                                fluid={true}
                                variant={'primary'}
                                text={'Add New Result/s'}
                                onClick={() => history.push({
                                pathname: '/overall-analysis/results',
                                create: true
                            })}/>
                            <FormButton
                                size={'xl'}
                                fluid={true}
                                variant={'secondary'}
                                className={'mb-4'}
                                text={'Existing Results'}
                                onClick={() => history.push({
                                    pathname: '/overall-analysis/results',
                                })}/>
                            </Col>}
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={8} className="px-0 bordered-container m-0 mb-4 px-0">
                            <div className="dash-box bot-container mt-0 px-4">
                                <div className={`w-100 flex-wrap justify-content-between ${globalState.responsive.isMobile ? 'd-block' : 'd-flex'}`}>
                                    <div className="left-side">
                                        <h4 className="title">Positives v Negative</h4>
                                    </div>
                                    <div className="filters-container">
                                        {globalState.user.hasRole(ROLE_ADMIN) && <div>
                                            <CustomSelect
                                                onChange={(name, value) => setParams(params => ({...params, company_id: value}))}
                                                value={params.company_id}
                                                options={globalState.clients}
                                            />
                                        </div>}
                                        <div>
                                            <div className="time-filter-container d-flex">
                                                <div onClick={setTimeFilter("all")}
                                                     className={`time-option ${params.others_timespan === "all" ? 'active' : ""}`}>{translate('misc.all')}</div>
                                                <div onClick={setTimeFilter(11)}
                                                     className={`time-option ${params.others_timespan === 11 ? 'active' : ""}`}>1 Yr
                                                </div>
                                                <div onClick={setTimeFilter(5)}
                                                     className={`time-option ${params.others_timespan === 5 ? 'active' : ""}`}>6 {translate('misc.month')}</div>
                                                <div onClick={setTimeFilter(2)}
                                                     className={`time-option ${params.others_timespan === 2 ? 'active' : ""}`}>3 {translate('misc.month')}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dash-box px-4">
                                <CurrentResultsCharts params={params}/>
                            </div>
                    </Col>

                    </> }
                    
                </Row>


                {(globalState.responsive.isMobile || globalState.responsive.isDesktop) && <div className="mb-4 mt-0 bordered-container d-mobile d-desktop">
                    <Row className="mx-0">
                        <Col className="px-0">
                            <div className="dash-box mt-0 bot-container px-4">
                                <Timeline params={params} dashboard={true} title={translate('analysis.timeline')}/>
                            </div>
                        </Col>
                    </Row>
                </div> }

            </>}
        </Layout>
    );
};

export default AdminDashboard;