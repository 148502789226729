import React, {useContext, useEffect, useRef, useState} from 'react';
import context from "../../context/context";
import {useAlert} from "react-alert";
import Http from "../../libs/Http";
import {translate} from "../../libs/trans";
import Layout from "../Misc/Layout";
import Table from "../Reusable/Table";
import {Button as ButtonBootstrap, Col, Collapse, Row} from "reactstrap";
import striptags from "striptags";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleRight, faArrowDown, faArrowUp, faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import TableHover from "../Reusable/TableHover";
import AddOrEditFaqModal from "./Partials/AddOrEditFaqModal";
import {ROLE_ADMIN} from "../../constants";
import SearchInput from "../Reusable/SearchInput";
import {FormButton} from "../Reusable/FormComponents";

export const IMAGE_PATH = process.env.REACT_APP_API_ROOT + '/uploads/';

const Faq = () => {

    const {state: globalState} = useContext(context);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSections, setIsLoadingSections] = useState(false);
    const [sections, setSections] = useState([]);
    const [item, setItem] = useState({});
    const [modal, setModal] = useState(false);
    const [forceHover, setForceHover] = useState(false);
    const [openSection, setOpenSection] = useState(null);
    const [search, setSearch] = useState('');
    const [containerClass, setContainerClass] = useState('');

    const alert = useAlert();
    const wrapperRef = useRef();
    const [refreshTable, doRefresh] = useState(0);
    const refreshTableResults = () => doRefresh(i => ++i);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => document.removeEventListener('click', handleClickOutside, true);
    }, []);

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target))
            setForceHover(false);
    };

    useEffect(() => {
        if (!globalState.user.hasRole(ROLE_ADMIN)) {
            _getSections()
        }
    }, [globalState.lang]);

    /**
     * Toggle Modal Add
     * @private
     */
    const _openAddModal = () => {
        setItem({});
        _toggleModal();
    };

    /**
     * Toggle Modal Edit
     * @private
     */
    const _openEditModal = (item) => {
        setItem(item);
        _toggleModal();
    };

    /**
     * Toggle Modal
     * @private
     */
    const _toggleModal = () => {
        setModal(!modal)
    };

    /**
     * Toggle Modal
     * @private
     */
    const _showMore = ()  => {
        if (containerClass && containerClass === 'more') {
            setContainerClass('less')
        } else {
            setContainerClass('more')
        }
    };

    const getFaqSections = params => Http.get('/faq-section/all', {...params});

    const _deleteSection = async params => {

        setIsLoading(true);

        const res = await Http.delete('/faq-section/' + params.id);

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            refreshTableResults();
            alert.show(translate('notifications.delete_faq'), {type: 'success'});
        }

        setIsLoading(false);
    };

    const moveSection = async (section, direction) => {

        setIsLoading(true);

        const res = await Http.patch(`/faq-section/${section.id}`, {direction});

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            refreshTableResults();
            alert.show(translate('notifications.move_faq'), {type: 'success'});
        }

        setIsLoading(false);
    };


    const _toggleSuspendSection = async params => {

        setIsLoading(true);

        const res = await Http.post(`/faq-section/${params.id}/toggle-suspend`);

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {

            refreshTableResults();
            if (params.suspended) {
                alert.show(translate('notifications.unlock_faq'), {type: 'success'});
            } else {
                alert.show(translate('notifications.lock_faq'), {type: 'success'});
            }
        }

        setIsLoading(false);
    }

    const _getSections = async () => {

        setIsLoadingSections(true);

        const res = await Http.get('/faq-section/', {lang: globalState.lang});

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            setSections(res.data)
        }

        setIsLoadingSections(false);
    };

    const toggleSection = (key) => {
        setContainerClass('less')
        const moreText = document.getElementsByClassName('more-text-' + key)[0];
        setTimeout(() => {
            if(moreText.clientHeight < 500){
                moreText.classList.add('hide-more')
            } else {
                moreText.classList.remove('hide-more');
            }
        }, 300);

        if (key === openSection) {
            setOpenSection(null)
        } else {
            setOpenSection(key)
        }
    }


    const columns = [
        {
            colSize: 5,
            text: 'Id',
            name: 'id',
            sortable: true,
            colSizeTablet: 10,
            colSizeMobile: 20,
        },
        {
            colSize: 30,
            text: translate('faq.title_1'),
            name: 'title',
            colSizeTablet: 25,
            colSizeMobile: 50,
        },
        {
            colSize: 30,
            text: translate('faq.body'),
            name: 'body',
            colSizeTablet: 25,
        },
        {
            colSize: 20,
            text: translate('faq.move'),
            customClass: "d-flex justify-content-center",
            colSizeTablet: 20,
        },
        {
            colSize: 5,
            customClass: "d-flex justify-content-center",
            text: translate('misc.status'),
            colSizeTablet: 10,
            colSizeMobile: 20,
        },
        {
            colSize: 1,
            text: '',
            colSizeTablet: 5,
            colSizeMobile: 5,
        }
    ];

    return (
        <Layout loading={isLoadingSections}>
            {globalState.user.hasRole(ROLE_ADMIN) ? <><div className="bordered-container">
                <h5 className={'my-3 mt-4 ml-4 pl-2 h-title'}>{translate('faq.title')}</h5>
                <div className="table-container">
                    <div className={'table-content add-users'}>
                        <Table columns={columns}
                               isLoading={isLoading}
                               getItems={getFaqSections}
                               setItems={setSections}
                               refreshTable={refreshTable}
                               buttonsContainer={<FormButton
                                   size={'large'}
                                   variant={'secondary'}
                                   text={"+ " + translate('faq.add')}
                                   onClick={_openAddModal}/>}>
                            {!!sections.length && sections.map((section, key) => {
                                return (
                                    <div
                                        className={`table-row-item row align-items-center m-0 ${forceHover === key ? 'force-hover' : 'stop-hover'}`}
                                        key={key}>
                                        <div>{section.id}</div>
                                        <div className="ellipsis">{section.title}</div>
                                        <div
                                            className="ellipsis">{striptags(section?.body).replace(/&nbsp;/g, ' ')}</div>
                                        <div className="d-flex justify-content-center">
                                            <div className="px-3 mr-3 cursor-pointer"
                                                 onClick={() => moveSection(section, 'up')}>
                                                <FontAwesomeIcon icon={faArrowUp}/>
                                            </div>
                                            <div className="px-3 cursor-pointer"
                                                 onClick={() => moveSection(section, 'down')}>
                                                <FontAwesomeIcon icon={faArrowDown}/>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <span className={`${section.suspended ? 'status-red' : 'status-green'}`}/>
                                        </div>
                                        <div className="justify-content-center">
                                            <div className={'table-row-item dots force-hover'}>
                                                <div className={'force-row-icons dots-icon'}>
                                                    <div className="force-row-icon" onClick={() => setForceHover(key)}>
                                                        <FontAwesomeIcon icon={faEllipsisH}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div ref={wrapperRef}>
                                            <TableHover
                                                idSuffix={key + 'faq'}
                                                onEdit={(item) => _openEditModal(item)}
                                                onDelete={(item) => _deleteSection(item)}
                                                onLock={(item) => _toggleSuspendSection(item)}
                                                item={section}
                                                hover={{
                                                    edit: globalState.user.hasRole(ROLE_ADMIN),
                                                    delete: globalState.user.hasRole(ROLE_ADMIN),
                                                    lock: globalState.user.hasRole(ROLE_ADMIN),
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </Table>
                    </div>
                </div>
                {modal && <AddOrEditFaqModal refreshTableResults={refreshTableResults} item={item} toggleModal={_toggleModal}/>}
            </div>
                {globalState.responsive.width <= 1024 &&
                <div className={'d-block mt-4 px-4 w-450'}>
                    <FormButton
                        size={'xl'}
                        fluid={true}
                        variant={'secondary'}
                        text={"+ " + translate('faq.add')}
                        onClick={_openAddModal}/></div>}
            </> : <div className="bordered-container">
                <h5 className={'my-3 mt-4 ml-4 pl-2 h-title'}>{translate('faq.title')}</h5>
                <Col sm={12} md={6} lg={5} xl={4} className={'mb-5 ml-3'}>
                    <div className={'pr-4'}>
                        <SearchInput placeholder={translate('misc.search')} name={'search'}
                                     onChange={(_, value) => setSearch(value)}/>
                    </div>

                </Col>
                <div className="bordered-container mb-4">
                    {!!sections.length && sections.filter(item => item?.title.toLowerCase().includes(search.toLowerCase())
                        || item?.body.toLowerCase().includes(search.toLowerCase()))
                        .map((section, key) => <div className="px-4 faq-content" key={key}>
                            <div className="d-block cursor-pointer" onClick={() => toggleSection(key)}>
                                <div className="faq-row mr-2 py-3 mb-0">
                                    <div
                                        className={`faq-title ${key === openSection ? "active" : ""}`}>{section?.title}</div>
                                    <FontAwesomeIcon icon={key === openSection ? faAngleDown : faAngleRight}
                                                     className="ml-3 menu-item-caret"/>
                                </div>
                            </div>

                            <Collapse isOpen={key === openSection} navbar>
                                <div className={`my-2 ${key === openSection ? "active" : ""}`}/>
                                <Row>
                                    <Col className={`client-compare-container mb-4 ${section.media && section?.direction === "left" ? 'd-flex' : 'd-block'}`}>
                                        <div className="img-container mr-4">
                                            {section.media && <>
                                                {section.media.type === 'faq_image' ?
                                                    <img src={IMAGE_PATH + section.media.file_name}
                                                         className="img-fluid faq-image my-3" alt="section-image"/>
                                                    :
                                                    <div className="d-flex justify-content-center my-4">
                                                        <video style={{maxWidth: '600px', width: "100%"}} controls
                                                               controlsList="nodownload">
                                                            <source src={section.media.full_path} type="video/mp4"/>
                                                        </video>
                                                    </div>}
                                            </>}
                                        </div>
                                        <div style={{flexBasis:'100%'}}>
                                            <div className={`mt-3 faq-body more-text-${key} ${section.media ? 'faq-max-height' : ''} w-100 ${containerClass}`}
                                                 dangerouslySetInnerHTML={{__html: section?.body}}/>
                                            {section.media && <FormButton
                                                size={'small'}
                                                variant={'secondary'}
                                                className={'mt-3'}
                                                text={translate(`misc.${containerClass === 'more' ? 'less' : 'more'}`)}
                                                onClick={_showMore}/>}
                                        </div>

                                    </Col>
                                </Row>
                            </Collapse>
                            <div className={'faq-hr'}/>
                        </div>)}
                </div>

            </div>}
        </Layout>
    );
};

export default Faq;