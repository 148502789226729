import React, {Fragment, useState} from 'react';
import {translate} from "../../libs/trans";
import {Alert} from "reactstrap";

const InfoAlert = (props) => {

    const initialState = {
        visible: props.visible ? props.visible : false
    };

    const [state] = useState(initialState);

    return (
        <Fragment>
            {state.visible &&
            <Alert color="secondary" className="seatbelt-info alert-shown">
                <div className={'success-body'}>
                    {translate('auth.forgot.sentCode')}
                </div>
            </Alert>
            }
        </Fragment>)
};

export default InfoAlert;

