import React, {useEffect, useState} from 'react';
import Modal from "./Modal";
import {Col, FormGroup, Row} from "reactstrap";
import {translate} from "../../libs/trans";
import CustomSelect from "./CustomSelect";
import CustomInput from "./CustomInput";
import Http from "../../libs/Http";
import {generateOfficeName} from "../../libs/helpers";
import Checkbox from "./Checkbox";
import {COMPOUNDING_DEVICE, HD_PREPARATIONS, HOSPITAL_TYPE, ONCOLOGY_BEDS, ONCOLOGY_CHAIRS} from "../../constants";
import CustomSelectCreate from "./CustomSelectCreate";

const OfficeModal = (props) => {

    const [countries, setCountries] = useState([]);
    const [clients, setClients] = useState([]);

    useEffect(() => {
        if (!countries.length && props.modal === true) {
            _getCountries();
        }
        if (!clients.length && props.showClients && props.modal === true) {
            _getClients();
        }
    }, [props.modal]);

    const _getCountries = async () => {
        const res = await Http.get('/options/country');
        if (!res.hasError) {
            setCountries(res.data);
        }
    };

    const _getClients = async () => {
        let res = await Http.get('/clients/min');
        if (!res.hasError) {
            setClients(res.data);
        }
    };

    const {item, errors, toggleModal, modal, save, isLoading, handleChange, showClients, client, users, pickUser} = props;

    if (!item.hasOwnProperty('id')) {
        item.name = generateOfficeName(client?.id, client?.offices_count);
    }

    return (
        <Modal modal={modal} _toggleModal={toggleModal} className={'bd-my-modal modal-right'}
               title={item.id  ? translate('location.update') : translate('location.add')}
               confirmText={item.id  ? translate('misc.update') : translate('misc.save')}
               onAction={save}
               buttonSize={'xl'}
               buttonFluid={true}
               isLoading={isLoading}
               hideCancelButton={true}>

            <Row noGutters={true} className={'iteam-modal-row invalid-negative-margin'}>
                <Col xs={12}>
                    {showClients && <CustomSelect
                        className="mb-3"
                        name="company_id"
                        label={translate('misc.client')}
                        required={true}
                        onChange={handleChange}
                        value={item.company_id}
                        disabled={!!item.id}
                        options={clients}
                        error={errors.company_id}
                        loadingNoOptions={isLoading}
                    />}
                    <CustomSelect
                        className="mb-3"
                        name="country_id"
                        label={translate('location.country')}
                        required={true}
                        onChange={handleChange}
                        value={item.country_id}
                        options={countries}
                        error={errors.country_id}
                        loadingNoOptions={isLoading}
                    />
                    <CustomInput
                        type="text"
                        className="mb-3"
                        label={translate('location.office_name')}
                        required={true}
                        readonly={true}
                        name="name"
                        value={item.name}
                        onChange={() =>{}}
                        error={errors.name}
                    />

                    { users && <div className="mb-3"><b> Choose Users </b></div> }

                    { users && users.map(user => <div className="location-user-checkbox" onClick={() => props.pickUser(user.id)} key={user.id}>
                        <Checkbox checked={item.users?.find(usr => usr.id === user.id)} /> {user.email}
                    </div>)}

                    <div className="my-3"><b>Sub Account Details</b></div>

                        <CustomSelect
                            className="mb-4"
                            name="hospital_type_id"
                            required={true}
                            label={translate('auth.register.hospital_type')}
                            value={item.hospital_type_id}
                            options={props.dropdownOptions[HOSPITAL_TYPE]}
                            onChange={handleChange}
                            error={errors['hospital_type_id']}
                        />
                        <CustomSelect
                            type="text"
                            className="mb-4"
                            required={true}
                            label={translate('auth.register.oncology_chairs')}
                            value={item.oncology_chairs_id}
                            options={props.dropdownOptions[ONCOLOGY_CHAIRS]}
                            name="oncology_chairs_id"
                            onChange={handleChange}
                            error={errors['oncology_chairs_id']}
                        />
                        <CustomSelect
                            type="text"
                            className="mb-4"
                            required={true}
                            label={translate('auth.register.oncology_beds')}
                            value={item.oncology_beds_id}
                            options={props.dropdownOptions[ONCOLOGY_BEDS]}
                            name="oncology_beds_id"
                            onChange={handleChange}
                            error={errors['oncology_beds_id']}
                        />

                        <CustomSelect
                            type="text"
                            className="mb-4"
                            required={true}
                            label={translate('auth.register.hd_preparations')}
                            value={item.hd_preparations_id}
                            options={props.dropdownOptions[HD_PREPARATIONS]}
                            name="hd_preparations_id"
                            onChange={handleChange}
                            error={errors['hd_preparations_id']}
                        />
                        <CustomSelectCreate
                            className="mb-4"
                            name="compounding_device_id"
                            required={true}
                            label={translate('auth.register.compounding_device')}
                            value={item.compounding_device_id}
                            options={props.dropdownOptions[COMPOUNDING_DEVICE]}
                            onChange={handleChange}
                            error={errors['compounding_device_id']}
                        />


                </Col>
            </Row>
        </Modal>

    );
};

export default OfficeModal;