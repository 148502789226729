import React from 'react';
import Modal from "./Modal";
import {Col, Row} from "reactstrap";
import {translate} from "../../libs/trans";
import CustomSelect from "./CustomSelect";
import CustomInput from "./CustomInput";
import Checkbox from "./Checkbox";


const ClientUserModal = (props) => {

    const {item, errors, toggleModal, modal, save, isLoading, handleChange, showClients, isOwner, offices} = props;

    return (
          <Modal modal={modal} _toggleModal={toggleModal} className={'bd-my-modal modal-right'}
                 title={item.id ? (isOwner ? translate('general.update_user') : translate('client_users.update'))
                     : (isOwner ? translate('general.add_user') : translate('client_users.add_client'))}
                 confirmText={item.id ? translate('misc.update') : translate('misc.save')}
                 onAction={save}
                 isLoading={isLoading}
                 buttonSize={'xl'}
                 buttonFluid={true}
                 hideCancelButton={true}>
              <Row noGutters={true} className={'iteam-modal-row'}>
                  <Col xs={12}>
                      {showClients && <CustomSelect
                          className="mb-3"
                          name="company_id"
                          label={translate('misc.client')}
                          required={true}
                          onChange={handleChange}
                          value={item.company_id}
                          disabled={!!item.id}
                          options={props.clients}
                          error={errors.company_id}
                          loadingNoOptions={isLoading}
                      />}
                      <CustomInput
                          type="text"
                          className="mb-3"
                          label={translate('general.email')}
                          name="email"
                          required={true}
                          value={item.email}
                          onChange={handleChange}
                          error={errors.email}
                      />

                      { offices && <div className="mb-3">Choose Sub Accounts</div> }

                      { offices && offices.map(office => <div className="location-user-checkbox" onClick={() => props.pickOffice(office.id)} key={office.id}>
                         <Checkbox checked={item.locations?.find(location => location.id === office.id)} /> {office.name}
                      </div>)}

                  </Col>
              </Row>
          </Modal>
    );
};

export default ClientUserModal;