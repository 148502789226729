import React, {useContext, useEffect, useState} from 'react';
import Layout from "../Misc/Layout";
import {translate} from "../../libs/trans";
import {
    Col,
    Row
} from "reactstrap";
import SearchInput from "../Reusable/SearchInput";
import {scrollToTop} from "../../libs/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEdit, faPlay, faTrash} from "@fortawesome/free-solid-svg-icons";
import Http from "../../libs/Http";
import {SEARCH_TIME} from "../../constants";
import Pagination from "../Reusable/Pagination";
import UploadImageModal from "./Partials/UploadImageModal";
import Loading from "../Reusable/Loading";
import context from "../../context/context";
import {useAlert} from "react-alert";
import SlideToggle from "../Reusable/SlideToggle";
import MediaImagePlaceholder from "../Reusable/MediaImagePlaceholder";
import {FormButton} from "../Reusable/FormComponents";

export const IMAGE_PATH = process.env.REACT_APP_API_ROOT + '/uploads/';

let searchTimeout = null;

const MediaLibrary = (props) => {

    const [isLoading, setIsLoading] = useState({});
    const [modal, setModal] = useState(false);
    const [picked, setPicked] = useState(props.picked || []);
    const [searchString, setSearchString] = useState(null);
    const [item, setItem] = useState({});
    const [items, setItems] = useState({});
    const [type, setType] = useState(props.defaultType || 'faq_image');
    const {dispatch} = useContext(context);
    const [refreshTable, doRefresh] = useState(0);
    const refreshTableResults = () => doRefresh(i => ++i);
    const [pagination, setPagination] = useState({
        totalPages: 0,
        currentPage: 1,
        limit: 40,
        totalEntries: 0
    });


    const alert = useAlert();

    useEffect(() => {
        !props.noLayout && scrollToTop();
    }, []);

    /**
     * On change refreshTable from props
     */
    useEffect(() => {
        if (refreshTable > 0) _getItems();
    }, [refreshTable]);

    /**
     * On sort and page change
     */
    useEffect(() => {
        _getItems();
    }, [pagination.currentPage, type]);


    /**
     * On search input change
     */
    useEffect(() => {
        if (searchString !== null) {
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(_getItems, SEARCH_TIME);
        }
    }, [searchString]);


    /**
     * Get all Media
     * @returns {Promise<void>}
     * @private
     */
    const _getItems = async () => {

        setIsLoading(true);

        let params = {
            page: pagination.currentPage,
            sortColumn: 'created_at',
            sortOrder: 'desc',
            type
        };

        if (searchString !== '') {
            params.search = searchString;
        }

        if (pagination.limit) {
            params.limit = pagination.limit;
        }

        const res = await Http.get(`/media`, params);

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            setPagination(res.data.pagination);
            setItems(res.data.results);
        }

        setIsLoading(false);

    };

    /**
     * Toggle Modal Add
     * @private
     */
    const _openAddModal = () => {
        setItem({});
        _toggleModal();
    };

    /**
     * Toggle Modal Edit
     * @private
     */
    const _openEditModal = (item) => _ => {
        item.image = IMAGE_PATH + item.file_name;
        setItem(item);
        _toggleModal();
    };

    /**
     * Toggle Modal
     * @private
     */
    const _toggleModal = () => {
        setModal(!modal)
    };

    /**
     * Handle change search string
     * @param _
     * @param str
     * @private
     */
    const _onSearch = (_, str) => setSearchString(str);

    /**
     *
     * @param item_id
     * @returns {Promise<boolean>}
     * @private
     */
    const _deleteMedia = async item_id => {

        if (isLoading) return false;

        setIsLoading(true);

        const res = await Http.delete(`/media/${item_id}`);

        if (res.hasError) {
            alert.show(res.error.general, {type: 'error', timeout: 0});
        } else {
            setItem({});
            alert.show(translate(`notifications.delete_${type === 'faq_video' ? 'video' : 'image'}`), {type: 'success'});
            refreshTableResults();
        }

        setIsLoading(false);

    };


    /**
     *
     * @param item_id
     */
    const handleDelete = (item_id) => {
        dispatch({type: "SET_MODAL_DELETE", payload: {open: true, action: () => _deleteMedia(item_id)}})
    };


    /**
     * On click Choose Images
     * @private
     */
    const _onPickImages = () => {
        if (picked.length === 0 && !props.allowClearAll) return false;
        // callback
        props.onPickImages(picked);
    };


    /**
     * Pick Image
     * @param item
     * @returns {boolean}
     * @private
     */
    const _pick = (item) => _ => {
        if (!props.allowPickImage) return false;

        let tmpPicked = [...picked];
        let index = tmpPicked.findIndex(i => i.id === item.id);

        if (index >= 0) {
            tmpPicked.splice(index, 1);
        } else {
            if (props.pickSingleImage) {
                tmpPicked = [item];
            } else {
                tmpPicked.push(item);
            }
        }

        setPicked(tmpPicked);
    };

    /**
     * Handle change page
     * @param page
     * @private
     */
    const _onPageChange = (page) => setPagination({...pagination, currentPage: page});

    const tabs = [
        {value: 'faq_image', label: 'FAQ Images'},
        {value: 'faq_video', label: 'FAQ Videos'},
    ];

    /**
     * Render
     * @returns {*}
     * @private
     */
    const _content = () => {

        const {hideUploadBtn, hideControls, allowPickImage} = props;

        return <>
            <div className={'table-container px-2'}>
                <div className={'table-content'}>
                    <div className={'table-actions'}>
                        <Row className={'m-0'}>
                            <Col xs={12} sm={6} md={6} lg={5} xl={4} className={'pr-sm-0 mb-4'}>
                                <SearchInput placeholder={translate('misc.search')} name={'search'}
                                             onChange={_onSearch}/>
                            </Col>

                            {!hideUploadBtn && <Col xs={12} sm={6} md={6} lg={7} xl={8}
                                                    className={'d-xs-block d-sm-flex justify-content-end mb-4'}>
                                <div onClick={_openAddModal} className={'new-user'}>
                                 <FormButton
                                    size={'large'}
                                    variant={'secondary'}
                                    text={"+ " +  (type === 'faq_video' ? translate("misc.upload_video") : translate("media.upload_image"))}
                                    onClick={_openAddModal}/>
                                </div>
                            </Col>}
                        </Row>
                    </div>

                    {(isLoading && props.noLayout) && <div className={'loading-modal-container'}>
                        <Loading loading={true}/>
                    </div>}

                    <Row className="mx-0 mb-4">
                        <Col sm={12} md={props.noLayout ? 12 : 6} xl={6}>
                            <SlideToggle onChange={(v) => setType(v)} options={tabs} value={type} height={60}/>
                        </Col>
                    </Row>

                    {!isLoading && <div className="media-container row mx-1">

                        {allowPickImage && <p className="w-100 px-3">
                            {picked.length > 0 ? `${picked.length} ` + translate("media.images_selected") : translate("media.click_images")}
                        </p>}

                        {items.map(item => {
                            const classUsed = item.devices_count || item.pumps_count || item.secondary_sets_count || item.primary_sets_count || item.faqs_count;
                            return <div key={item.id}
                                        className={`overflow-hidden media-item ${classUsed ? 'used' : ''} ${item.type}`}>
                                <div className={`media-image ${allowPickImage ? 'cursor-pointer' : ''}`}
                                     onClick={_pick(item)}>
                                    {item.type === 'faq_video' ?
                                        <div className="d-block text-center">
                                            <FontAwesomeIcon icon={faPlay}/>
                                            <div title={item.title}>{item.title}</div>
                                            {picked.find(i => i.id === item.id) !== undefined &&
                                            <span className="picked"><FontAwesomeIcon icon={faCheck}/></span>}
                                        </div> :
                                        <>
                                            <MediaImagePlaceholder path={IMAGE_PATH + item.file_name} alt={item.title}/>
                                            {picked.find(i => i.id === item.id) !== undefined &&
                                            <span className="picked"><FontAwesomeIcon icon={faCheck}/></span>}
                                            {item.type === 'icon' && <span title={item.title}>{item.title}</span>}
                                        </>}
                                </div>
                                {!hideControls && <div className="actions d-flex">
                                <span onClick={_openEditModal(item)}>
                                    <FontAwesomeIcon icon={faEdit}/>
                                </span>
                                    <span onClick={() => handleDelete(item.id)}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </span>
                                </div>}
                            </div>
                        })}

                    </div>}
                    <div className={'ml-3'}>
                        <Pagination pages={pagination.totalPages} limit={pagination.limit} onChange={_onPageChange}/>

                        <div className="my-3 text-center">
                            <span className="image-in-use">{type === 'faq_video' ? 'video' : 'image'} in use</span>
                        </div>
                    </div>

                    {allowPickImage && <div className="text-center mt-5">
                        <FormButton onClick={_onPickImages}
                                text="Choose"
                                variant={'primary'}
                                isLoading={isLoading}
                        />
                    </div>}

                </div>

            </div>

            {modal && <UploadImageModal refreshTableResults={refreshTableResults} item={item} type={type}
                                        toggleModal={_toggleModal}/>}


        </>;
    };

    if (props.noLayout) return _content();

    return (
        <Layout loading={isLoading}>
            {_content()}
        </Layout>
    );
};

export default MediaLibrary;
