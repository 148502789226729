import React from 'react';
import {ROLE_ADMIN, ROLE_CLIENT, ROLE_CLIENT_USER, ROLE_SALES} from "../../constants";
import Admin from "./Menu/Admin";
import Sale from "./Menu/Sale";
import Client from "./Menu/Client";

const AsideLeft = ({user, sidebarOpen, width}) => {

    const _menuComponent = () => {

        if(user.hasRole(ROLE_ADMIN)) {
            return  <Admin sidebarOpen={sidebarOpen}/>;
        }
        if(user.hasRole(ROLE_SALES)) {
            return  <Sale sidebarOpen={sidebarOpen}/>;
        }
        if(user.hasRole(ROLE_CLIENT) || user.hasRole(ROLE_CLIENT_USER)) {
            return  <Client sidebarOpen={sidebarOpen}/>;
        }
    };


    return (
        <aside id="side-menu" className={`aside-left ${width > 768 && !sidebarOpen ? 'shrinked' : ''}`}>
            {_menuComponent()}
        </aside>
    );
};

export default AsideLeft;
