import React from 'react';

const Loading = ({loading, className}) => {
    return (
        <>
            {loading &&
            <div className={`general-loading ${className}`}>
                <div className="spinner"/>
            </div>}
        </>
    );
};

export default Loading;
