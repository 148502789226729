import React, {useEffect, useState} from 'react';
import {v4 as uuidv4} from "uuid";
import {Progress, Input, FormFeedback} from "reactstrap";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Http from "../../../libs/Http";

const chunkSize = 1048576 * 3; //its 3MB, increase the number measure in mb

const initialState = {
    showProgress: false,
    counter: 1,
    fileToBeUpload: {},
    beginningOfTheChunk: 0,
    endOfTheChunk: chunkSize,
    progress: 0,
    fileGuid: "",
    fileSize: 0,
    chunkCount: 0
};


const UploadChunk = ({onChunksUploaded, acceptedTypes}) => {

    const [showProgress, setShowProgress] = useState(initialState.showProgress);
    const [counter, setCounter] = useState(initialState.counter);
    const [fileToBeUpload, setFileToBeUpload] = useState(initialState.fileToBeUpload);
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(initialState.beginningOfTheChunk);
    const [endOfTheChunk, setEndOfTheChunk] = useState(initialState.endOfTheChunk);
    const [progress, setProgress] = useState(initialState.progress);
    const [fileGuid, setFileGuid] = useState(initialState.fileGuid);
    const [fileSize, setFileSize] = useState(initialState.fileSize);
    const [chunkCount, setChunkCount] = useState(initialState.chunkCount);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (fileSize > 0) {
            setShowProgress(true);
            fileUpload(counter);
        }

    }, [fileToBeUpload, progress]);

    const getFileContext = (e) => {
        const _file = e.target.files[0];

        let type = _file.type.split('/').pop();

        if (!acceptedTypes.includes(type)) {
            setError('Invalid type');
            return;
        }
        setFileSize(_file.size);
        const _totalCount =
            _file.size % chunkSize === 0
                ? _file.size / chunkSize
                : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        setChunkCount(_totalCount);
        setFileToBeUpload(_file);
        const _fileID = uuidv4() + "." + _file.name.split(".").pop();
        setFileGuid(_fileID);
    };

    const fileUpload = () => {
        setCounter(counter + 1);
        if (counter <= chunkCount) {
            const chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            uploadChunk(chunk);
        }
    };

    const revertFile = () => {
        setChunkCount(initialState.chunkCount);
        setFileToBeUpload(initialState.fileToBeUpload);
        setFileSize(initialState.fileSize);
        setFileGuid(initialState.fileGuid);
    };

    const uploadChunk = async (chunk) => {
        try {

            const res = await Http.post('/media/upload-chunk', {
                chunk,
                id: counter,
                total: chunkCount,
                fileName: fileGuid,
                originalName: fileToBeUpload.name,
                type: 'video',
                hasFiles: true
            });


            if (!res.hasError) {
                setBeginingOfTheChunk(endOfTheChunk);
                setEndOfTheChunk(endOfTheChunk + chunkSize);
                if (counter === chunkCount) {
                    console.log("Process is complete, counter", counter);
                    setProgress(100);
                    onChunksUploaded(res.data);
                } else {
                    const percentage = (counter / chunkCount) * 100;
                    setProgress(percentage);
                }
            } else {
                setError(res.errorMessage[0]);
                revertFile();
                console.log("Error Occurred:", res.errorMessage);
            }
        } catch (error) {
            revertFile();
            console.log("error", error);
        }
    };

    return (
        <div className="mt-5">
            {error &&
            <FormFeedback>{error}</FormFeedback>}
            <Input
                type="file"
                id="chunk-file"
                className="d-none"
                accept="video/mp4,video/x-m4v,video/*"
                onChange={getFileContext}

            />
            <label htmlFor="chunk-file" className="d-block">
                <div className="chunk-plus-sign">
                    <FontAwesomeIcon icon={faPlusCircle}/>
                    <p>{fileSize > 0 ? fileToBeUpload.name : 'Click to upload'}</p>
                </div>
            </label>
            {showProgress && <Progress animated bar max={100} value={progress}>
                <p className="mb-0">{progress === 100 ? 'Uploaded sucessfully!' : (`${Math.round(progress)}%`)}</p>
            </Progress>}
        </div>
    );
};

export default UploadChunk;
